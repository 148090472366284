import React, { useState } from "react";
import { User } from "../store";

interface Props {
  children: any;
}

interface Iprops {
  token: any;
  setToken: React.Dispatch<React.SetStateAction<any>>;
  userInfo: any;
  setUserInfo: React.Dispatch<React.SetStateAction<any>>;
}

export const AuthContext = React.createContext({} as Iprops);

export const AuthProvider: React.FC<Props> = ({ children }) => {
  const [token, setToken] = useState(() => User.getToken());
  const [userInfo, setUserInfo] = useState({});
  const value = React.useMemo(() => {
    return {
      userInfo,
      setUserInfo,
      token,
      setToken,
    };
  }, [setUserInfo, userInfo, token, setToken]);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
