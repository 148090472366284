import React from "react";
import { StyledSectionTitle } from "./style";

interface Props {
  children: any;
  sectionTitle: string;
  contentStyles?: any;
}

const ViewActivitySection: React.FC<Props> = (props: Props) => {
  return (
    <div style={{ marginBottom: 44 }}>
      <StyledSectionTitle>{props.sectionTitle}</StyledSectionTitle>
      <div style={props.contentStyles}>{props.children}</div>
    </div>
  );
};

export default ViewActivitySection;
