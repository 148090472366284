import React, { useCallback, useState, useEffect } from "react";
import { MatFileUploadInput, ColorCircularProgress } from "../MaterialFields";
import { TextFieldProps, InputAdornment } from "@material-ui/core";
import { useDropzone } from "react-dropzone";
import axios from "axios";
import { useMutation } from "react-apollo";
import { GET_S3_URL } from "../../../graphql/mutation";
import { useSnackbar } from "notistack";
import { Images } from "../../../assets/images";

interface FormikProps {
  setFieldValue: any;
  error: boolean;
  helperText: string;
  onChange: any;
  handleChange?: any;
  setFileLoading?: any;
  acceptType?: string;
}

const FileUploadInput = ({
  label,
  name,
  setFieldValue,
  error,
  helperText,
  onChange,
  handleChange,
  setFileLoading,
  acceptType,
}: TextFieldProps & FormikProps) => {
  const [fileName, setFileName] = useState<string>("");
  const [callS3UploadUrl] = useMutation(GET_S3_URL);
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState<boolean>(false);

  const onDropAccepted = useCallback((acceptedFiles) => {
    setLoading(true);
    console.log(acceptedFiles);
    handleImageChange(acceptedFiles[0]);
    setFileName(acceptedFiles[0].name);
    setFieldValue(name, acceptedFiles[0]);
  }, []);

  useEffect(() => {
    if (setFileLoading) {
      setFileLoading(loading);
    }
  }, [loading]);

  const onDropRejected = useCallback((rejectedFiles) => {
    if (rejectedFiles[0]?.size > 5000000) {
      enqueueSnackbar("File size cannot be greater than 5MB", {
        variant: "error",
        autoHideDuration: 3000,
      });
    } else {
      enqueueSnackbar("Invalid file format", {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  }, []);

  const handleImageChange = async (file: any) => {
    callS3UploadUrl({
      variables: {
        input: { fileName: file.name },
      },
    })
      .then((res) => {
        if (res?.data?.generateSignedUrl?.status) {
          let url = res?.data?.generateSignedUrl?.url;
          uploadToAws(url, file);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const uploadToAws = (url: string, file: any) => {
    axios
      .put(url, file, {
        headers: {
          "Content-Type": file.type,
        },
      })
      .then((_) => {
        onChange(url?.split("?")[0]);
        setLoading(false);
      })
      .catch((err) => {
        setFileName("");
        setFieldValue(name, "");
        enqueueSnackbar("Upload Failed", {
          variant: "error",
          autoHideDuration: 3000,
        });
        setLoading(false);
        console.log("err", err);
      });
  };
  const { getRootProps, getInputProps } = useDropzone({
    onDropAccepted,
    onDropRejected,
    accept: acceptType,
    disabled: false,
    maxSize: 5000000,
  });

  return (
    <div className="outlineNone" {...getRootProps()}>
      <MatFileUploadInput
        error={error}
        helperText={helperText}
        variant="outlined"
        value={fileName}
        label={label}
        name={name}
        onChange={handleChange}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {loading ? (
                <ColorCircularProgress size={25} thickness={3} />
              ) : (
                <img src={Images.UploadIcon} />
              )}
            </InputAdornment>
          ),
          readOnly: true,
        }}
      />
      <input {...getInputProps()} />
    </div>
  );
};

export default FileUploadInput;
