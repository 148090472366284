export const Images = {
  ColorLogo: require("./images/logo/colorLogo.svg"),
  whiteLogo: require("./images/logo/whiteLogo.svg"),
  MenuIcon: require("./images/menuIcon/menu.svg"),
  UploadIcon: require("./images/icons/upload.svg"),
  SearchIcon: require("./images/icons/search.svg"),
  ImageUploadIcon: require("./images/icons/image-upload.svg"),
  //Profile Section
  ParentProfile: require("./images/common/userplaceHolder3x.jpg"),
  person: require("./images/myProfile/user.svg"),
  list: require("./images/myProfile/list.svg"),
  switch: require("./images/myProfile/switch.svg"),
  chat: require("./images/myProfile/chat.svg"),
  personBlack: require("./images/myProfile/user-black.svg"),
  listBlack: require("./images/myProfile/list-black.svg"),
  chatBlack: require("./images/myProfile/chat-black.svg"),
  star: require("./images/myProfile/star.svg"),
  starBlack: require("./images/myProfile/star-black.svg"),
  group: require("./images/myProfile/group.svg"),
  hand: require("./images/common/hand.png"),
  groupBlack: require("./images/myProfile/group-black.svg"),

  verified: require("./images/common/verified.png"),
  failed: require("./images/common/failed.png"),
  stop: require("./images/common/stop.png"),

  // New Activity
  checkedIcon: require("./images/newActivity/checked.svg"),
  uncheckedIcon: require("./images/newActivity/unchecked.svg"),

  //PoliceVerified
  policeVerified: require("./images/policeVerified/policeVerified.svg"),

  //Common
  userPlaceHolder: require("./images/common/userplaceHolder3x.jpg"),
  userUploadPlaceHolder: require("./images/common/user.png"),
  coursePlaceHolder: require("./images/common/coursePlaceHolder.png"),
  flagImage: require("./images/common/flag3x.jpg"),
  closeIcon: require("./images/common/close.svg"),

  //View Activity Stats Section
  calendar: require("./images/activityStats/calendar.svg"),
  batch: require("./images/activityStats/batch.svg"),
  ageRange: require("./images/activityStats/ageRange.svg"),
  duration: require("./images/activityStats/duration.svg"),

  //Contact Us
  mapPlaceholder: require("./images/contactUs/mapPlaceholder.png"),
};

export const Svg = {
  Spinner: "/images/spinner.svg",
};
