import React from "react";
import Layout from "../../components/Layout/Layout";
import InstituteRegistrationForm from "../../components/InstituteRegistrationForm";
import { FORM_STATES } from "../../_helper/enums";

function InstituteRegistration() {
  const userStep = FORM_STATES.INSTITUTE_DETAILS;

  return (
    <Layout isFooterEnable={false}>
      <InstituteRegistrationForm userStep={userStep} />
    </Layout>
  );
}

export default InstituteRegistration;
