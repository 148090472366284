import React, { useState } from "react";
import Myaccount from "../Myaccount";
import MyClasses from "../MyClasses";
import ReviewRatings from "../ReviewRatings.tsx";
import { CardWrapper } from "../style";
import TutorList from "../TutorList";
import ClassDetails from "../ClassDetails";
import { DASHBOARD_NAVS } from "../../../_helper/enums";
import ViewMyAccount from "../ViewMyAccount";

interface Props {
  userStep: string;
  institute?: boolean;
  id?: string;
  viewStatus?: string;
}

const DashBoardMain: React.FC<Props> = ({
  userStep,
  institute,
  id,
  viewStatus,
}) => {
  const [edit, setEdit] = useState(false);
  return (
    <div>
      {userStep === DASHBOARD_NAVS.MYACCOUNT &&
        (edit ? (
          <CardWrapper className="flex column pt30 pb30">
            <Myaccount
              setIsEdit={(bool) => setEdit(bool)}
              institute={institute ? true : false}
              isEdit={edit}
            />
          </CardWrapper>
        ) : (
          <ViewMyAccount
            institute={institute ? true : false}
            setEdit={(bool) => setEdit(bool)}
          />
        ))}

      {userStep === DASHBOARD_NAVS.MYCLASSES && <MyClasses />}
      {(userStep === DASHBOARD_NAVS.REVIEWRATINGS ||
        userStep === DASHBOARD_NAVS.TUTORREVIEW) && (
        <CardWrapper className="flex column p30">
          <ReviewRatings id={id} institute={institute ? true : false} />
        </CardWrapper>
      )}
      {userStep === DASHBOARD_NAVS.TUTOR && (
        <CardWrapper className="flex column">
          <TutorList />
        </CardWrapper>
      )}
      {userStep === DASHBOARD_NAVS.CLASSDETAILS && (
        <CardWrapper className="flex column">
          <ClassDetails
            institute={institute ? true : false}
            id={id}
            viewStatus={viewStatus}
          />
        </CardWrapper>
      )}
    </div>
  );
};

export default DashBoardMain;
