import React from "react";
import { Images } from "../../assets/images";
import styled from "styled-components";
import { Hidden } from "@material-ui/core";
import useLang from "../../hooks/useLang";

const FooterStyle = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  padding: 0px 70px;
  width: 90%;
  position: relative;
  @media (max-width: 1024px) {
    flex-direction: column;
    justify-content: center;
    padding: 0px;
    text-align: center;
    width: auto;
  }
`;

const CopyRightsWrapper = styled.div`
  width: 161px;
  opacity: 0.3;
  font-family: ${(props) => props.theme.fonts.maxrounded};
  font-size: 18px;
  line-height: 1.39;
  text-align: center;
  color: #ffffff;
  margin: 10px;
  display: flex;
  align-items: flex-end;
  @media (max-width: 1024px) {
    align-items: center;
  }
`;

const NavLinks = styled(FooterStyle)`
  display: flex;
  justify-content: space-around;
  @media (max-width: 1024px) {
    margin: 20px;
  }
  @media (max-width: 768px) {
    justify-content: center;
  }
`;

const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  @media (max-width: 768px) {
    margin: 20px 0px;
  }
`;

const Title = styled.div`
  font-family: ${(props) => props.theme.fonts.maxrounded};
  font-size: 20px;
  font-weight: 500;
  color: #ffffff;
`;
const ContentWrapper = styled.div`
  margin: 20px;
`;

const Links = styled.div`
  opacity: 0.75;
  cursor: pointer;
  font-family: ${(props) => props.theme.fonts.maxrounded};
  font-size: 20px;
  color: #ffffff;
  padding-top: 15px;
  a {
    color: #ffffff;
    text-decoration: none;
  }
`;

const HandImg = styled.img`
  position: absolute;
  bottom: 0;
  right: 30px;
`;

interface Props {}

const Footer: React.FC<Props> = () => {
  const { t } = useLang();
  return (
    <>
      <FooterStyle>
        <div className="flex center-center">
          <Hidden implementation="css" mdDown>
            <LogoWrapper>
              <img src={Images.whiteLogo} alt="activkidoWhiteLogo" />
            </LogoWrapper>
          </Hidden>
        </div>

        <NavLinks>
          <ContentWrapper>
            <Title>{t("footer.information")}</Title>
            <a
              style={{
                textDecoration: "none",
              }}
              target="_blank"
              href={`${process.env.REACT_APP_PARENT_URL}/about_us`}
            >
              <Links>{t("footer.aboutUs")}</Links>
            </a>

            <a
              style={{
                textDecoration: "none",
              }}
              target="_blank"
              href={`${process.env.REACT_APP_PARENT_URL}/contact_us`}
            >
              <Links>{t("footer.contactUs")}</Links>
            </a>
          </ContentWrapper>

          <ContentWrapper>
            <Title>{t("footer.followUs")}</Title>
            <Links>
              <a href="https://www.facebook.com/activkido/" target="_blank">
                {t("footer.faceBook")}
              </a>
            </Links>
            <Links>
              <a href="https://www.instagram.com/activkido/" target="_blank">
                {t("footer.instagram")}
              </a>
            </Links>
            <Links>
              <a href="https://twitter.com/activkido/" target="_blank">
                {t("footer.Twitter")}
              </a>
            </Links>
            {/* <Links>
              <a href="https://twitter.com/activkido/" target="_blank">
                {t('footer.linkedIn')}
              </a>
            </Links> */}
          </ContentWrapper>

          <ContentWrapper>
            <Title>{t("footer.helpLinks")}</Title>

            <a
              style={{
                textDecoration: "none",
              }}
              target="_blank"
              href={`${process.env.REACT_APP_PARENT_URL}/terms_and_conditions`}
            >
              <Links>{t("footer.t&c")}</Links>
            </a>
            <a
              style={{
                textDecoration: "none",
              }}
              target="_blank"
              href={`${process.env.REACT_APP_PARENT_URL}/privacy_policy`}
            >
              <Links>{t("footer.privacyPolicy")}</Links>
            </a>
            <a
              style={{
                textDecoration: "none",
              }}
              target="_blank"
              href={`${process.env.REACT_APP_PARENT_URL}/faqs`}
            >
              <Links>{t("footer.faq")}</Links>
            </a>

            <a
              style={{
                textDecoration: "none",
              }}
              target="_blank"
              href={`${process.env.REACT_APP_PARENT_URL}/cookie_policy`}
            >
              <Links>{t("footer.cookiePolicy")}</Links>
            </a>
          </ContentWrapper>
        </NavLinks>

        <Hidden implementation="css" lgUp>
          <LogoWrapper>
            <img src={Images.whiteLogo} alt="activkidoWhiteLogo" />
          </LogoWrapper>
        </Hidden>
      </FooterStyle>
      <div className="flex center-center">
        <CopyRightsWrapper>{t("footer.cpyRights")}</CopyRightsWrapper>
        <Hidden implementation="css" mdDown>
          <HandImg
            src={Images.hand}
            alt="handImage"
            style={{ display: "none" }}
          />
        </Hidden>
      </div>
    </>
  );
};

export default Footer;
