import gql from "graphql-tag";

const GET_TUTOR_INFO = gql`
  query {
    me {
      ... on Tutor {
        id
        avatarUrl
        email
        firstName
        lastName
        email
        phoneNumber
        address
        alternatePhoneNumber
        ratingAverage
        ratingCount
        phoneVerification
        status
        overview
        awards
        education
        certification
        totalExperience
        allowedCategories {
          nodes {
            category {
              name
              id
            }
          }
        }
      }
    }
  }
`;

const GET_INSTITUEINFO_INFO = gql`
  query {
    me {
      ... on Institution {
        id
        avatarUrl
        email
        firstName
        lastName
        email
        phoneNumber
        address
        panNumber
        ratingAverage
        ratingCount
        phoneVerification
        status
        alternatePhoneNumber
        overview
        certification
        awards
        allowedCategories {
          nodes {
            category {
              name
              id
            }
          }
        }
      }
    }
  }
`;

const GET_CATEGORIES = gql`
  query Categories {
    categories {
      nodes {
        id
        name
      }
    }
  }
`;
const GET_ALLOWED_CATEGORIES = gql`
  query AllowedCategories {
    getAllowedCategories {
      nodes {
        category {
          id
          name
        }
      }
    }
  }
`;

const GET_TUTOR_LIST = gql`
  query tutors($first: Int) {
    tutors(first: $first) {
      nodes {
        firstName
        avatarUrl
        ratingAverage
        ratingCount
        id
        status
        allowedCategories {
          nodes {
            category {
              name
            }
          }
        }
      }
    }
  }
`;

const GET_COURSE_LIST = gql`
  query getCourses($first: Int, $after: String) {
    getCourses(first: $first, after: $after) {
      nodes {
        title
        id
        bannerImageUrl
        numberOfWeeks
        startDate
        price
        slashedPrice
        sunday
        monday
        tuesday
        wednesday
        thursday
        friday
        saturday
        startTime
        endTime
        description
        status
        place
        tutor {
          firstName
          avatarUrl
        }
        remainingSeats
        maxCount
        nextClass
        zoomMeeting {
          startTime
          endTime
          joinUrl
          startUrl
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;

const GET_COURSE_DRAFR_LIST = gql`
  query drafts($first: Int, $after: String) {
    drafts(first: $first, after: $after) {
      nodes {
        params
        id
        tutor {
          firstName
          avatarUrl
        }
        requestedBy {
          ... on Institution {
            id
            firstName
          }
          ... on Tutor {
            id
            firstName
          }
        }
        requestedByType
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;

const GET_COURSE_UNDER_REVIEW_LIST = gql`
  query coursesWaitingForApproval($first: Int, $after: String) {
    coursesWaitingForApproval(first: $first, after: $after) {
      nodes {
        params
        id
        tutor {
          firstName
          avatarUrl
        }
        requestedBy {
          ... on Institution {
            id
            firstName
          }
          ... on Tutor {
            id
            firstName
          }
        }
        requestedByType
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;

const GET_INSTITUTE_RATING = gql`
  query getInstituteRatings($id: Int!, $first: Int, $after: String) {
    getInstituteRatings(id: $id, first: $first, after: $after) {
      nodes {
        createdAt
        reviewComment
        number
        authoredBy {
          avatarUrl
          firstName
          lastName
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`;

const GET_TUTOR_RATING = gql`
  query getTutorRatings($id: Int!, $first: Int, $after: String) {
    getTutorRatings(id: $id, first: $first, after: $after) {
      nodes {
        createdAt
        reviewComment
        number
        authoredBy {
          avatarUrl
          firstName
          lastName
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`;

const GET_TUTOR = gql`
  query getTutor($id: Int!) {
    getTutor(id: $id) {
      id
      avatarUrl
      email
      firstName
      lastName
      email
      phoneNumber
      address
      status
      panNumber
      ratingAverage
      ratingCount
      alternatePhoneNumber
      allowedCategories {
        nodes {
          category {
            name
            id
          }
        }
      }
    }
  }
`;

const GET_COURSE_BY_ID = gql`
  query GetCourseById($id: Int!) {
    getCourse(id: $id) {
      id
      ageGroup
      bannerImageUrl
      status
      category {
        id
        name
      }
      classHours
      daysPerWeek
      description
      maxCount
      place
      numberOfWeeks
      remainingSeats
      price
      title
      tutor {
        id
        firstName
        avatarUrl
      }
      bookings {
        nodes {
          child {
            firstName
          }
          parent {
            firstName
            phoneNumber
            id
          }
          id
          status
        }
      }
      sunday
      monday
      tuesday
      wednesday
      thursday
      friday
      saturday
      startDate
      endTime
      startTime
      nextClass
      zoomMeeting {
        startTime
        endTime
        joinUrl
        startUrl
      }
    }
  }
`;

const GET_DRAFT_BY_ID = gql`
  query GetDraftById($id: Int!) {
    getDraft(draftId: $id) {
      id
      params
      tutor {
        firstName
        avatarUrl
      }
      requestedBy {
        ... on Institution {
          id
          firstName
        }
        ... on Tutor {
          id
          firstName
        }
      }
      requestedByType
    }
  }
`;

const GET_UNDER_REVIEW_ID = gql`
  query getCourseWaitingForApproval($id: Int!) {
    getCourseWaitingForApproval(id: $id) {
      id
      params
      tutor {
        firstName
        avatarUrl
      }
      requestedBy {
        ... on Institution {
          id
          firstName
        }
        ... on Tutor {
          id
          firstName
        }
      }
      requestedByType
    }
  }
`;

const GET_INSTITUTE_TUTORS = gql`
  query getInstituteTutors {
    getInstituteTutors {
      nodes {
        id
        firstName
        allowedCategories {
          nodes {
            id
            category {
              id
              name
            }
          }
        }
      }
    }
  }
`;

export {
  GET_TUTOR_INFO,
  GET_INSTITUEINFO_INFO,
  GET_CATEGORIES,
  GET_ALLOWED_CATEGORIES,
  GET_TUTOR_LIST,
  GET_COURSE_BY_ID,
  GET_COURSE_LIST,
  GET_DRAFT_BY_ID,
  GET_INSTITUTE_RATING,
  GET_TUTOR_RATING,
  GET_TUTOR,
  GET_COURSE_DRAFR_LIST,
  GET_COURSE_UNDER_REVIEW_LIST,
  GET_INSTITUTE_TUTORS,
  GET_UNDER_REVIEW_ID,
};
