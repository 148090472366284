import React, { useEffect, useState } from "react";
import CreateActivityFormContainer from "../common/CreateActivityFormContainer";
import {
  FieldContainer,
  SubmitBtnContainer,
} from "../common/OnboardingFormContainer/styles";
import {
  ActivityTextInput,
  HorizontalFieldsContainer,
  FieldContainer50,
  DraftButtonContainer,
  CreateButtonContainer,
} from "../common/CreateActivityFormContainer/styles";
import { Formik, Form, Field } from "formik";
import ActivityImageUpload from "../common/ActivityImageUpload";
import {
  PrimaryCTAButton,
  CustomDatePicker,
  SecondaryCTAButton,
  CustomTimePicker,
  ColorCircularProgress,
} from "../common/MaterialFields";
import CustomSelectField from "./Fields/CustomSelectField";
import SelectDays from "./components/SelectDays";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { createActivity } from "../../_helper/ValidationSchema";
import { useQuery, useMutation, useLazyQuery } from "react-apollo";
import {
  GET_ALLOWED_CATEGORIES,
  GET_COURSE_BY_ID,
  GET_DRAFT_BY_ID,
  GET_INSTITUTE_TUTORS,
} from "../../graphql/queries";
import _ from "lodash";
import {
  numOfDays,
  numOfWeeks,
  duration,
  ageRange,
  getPayloadObject,
  getTutorId,
  isDraftDisabled,
  initialActivityState,
  getInitialValues,
  getInstituteId,
  places,
  restrictSplChar,
  isTexHasSplChar,
} from "./activity_helpers";
import {
  CREATE_CLASS,
  CREATE_DRAFT_CLASS,
  UPDATE_CLASS,
} from "../../graphql/mutation";
import { useSnackbar } from "notistack";
import { navigate } from "@reach/router";
import useAuth from "../../hooks/useAuth";
import jwt_decode from "jwt-decode";
import { isBefore } from "date-fns";

interface Props {
  userType?: string;
  mode?: string | undefined;
  id?: any;
}

const CreateActivity: React.FC<Props> = ({ mode = "create", id = null }) => {
  const { token } = useAuth();
  const [userType, setUserType] = useState<string>("");

  useEffect(() => {
    if (token) {
      const userData: any = jwt_decode(token);
      console.log(userData, "user data");
      if (userData.claims.entity === "Tutor") {
        setUserType("individual_tutor");
      }
    }
  }, [token]);

  // #region Hooks & Initialization
  const { data } = useQuery(GET_ALLOWED_CATEGORIES, {
    fetchPolicy: "no-cache",
  });
  const [getCourseById, courseData] = useLazyQuery(GET_COURSE_BY_ID, {
    fetchPolicy: "no-cache",
  });
  const [getDraftById, draftData] = useLazyQuery(GET_DRAFT_BY_ID, {
    fetchPolicy: "no-cache",
  });
  const [getInstituteTutors, instituteTutors] = useLazyQuery(
    GET_INSTITUTE_TUTORS,
    {
      fetchPolicy: "no-cache",
    }
  );
  const [createClassMutation] = useMutation(CREATE_CLASS);
  const [updateClassMutation] = useMutation(UPDATE_CLASS);
  const [createDraftClassMutation] = useMutation(CREATE_DRAFT_CLASS);
  const [allowedCategories, setAllowedCategories] = useState([]);
  const [tutorsList, setTutorsList] = useState([]);
  const [tutorsListCopy, setTutorsListCopy] = useState([]);
  const [initialValues, setInitialValues] = useState<any>(initialActivityState);
  const { enqueueSnackbar } = useSnackbar();
  const [saveLoading, setLoading] = useState<boolean>(false);
  const [draftLoading, setDraftLoading] = useState<boolean>(false);
  //#endregion
  // #region Effects
  useEffect(() => {
    if (
      courseData?.data?.getCourse &&
      courseData.called &&
      !courseData.loading
    ) {
      const val = getInitialValues(courseData.data.getCourse);
      if (!_.isEmpty(val)) setInitialValues(val);
    }
  }, [courseData.data]);
  useEffect(() => {
    if (
      draftData?.data?.getDraft?.params &&
      draftData.called &&
      !draftData.loading
    ) {
      const val = getInitialValues(draftData.data.getDraft.params);
      if (!_.isEmpty(val)) setInitialValues(val);
    }
  }, [draftData.data]);

  useEffect(() => {
    if (!_.isEmpty(tutorsListCopy) && (draftData.data || courseData.data)) {
      let val: any;
      if (courseData?.data?.getCourse) {
        val = getInitialValues(courseData.data.getCourse);
      } else if (draftData?.data?.getDraft) {
        val = getInitialValues(draftData.data.getDraft.params);
      }
      if (!_.isEmpty(val)) {
        checkTutorForCategory(val.category, "", true);
      }
    }
  }, [tutorsListCopy, draftData.data, courseData.data]);

  useEffect(() => {
    async function getCourse(id: any) {
      getCourseById({
        variables: {
          id: parseInt(id, 10),
        },
      });
    }
    async function getDraft(id: any) {
      getDraftById({
        variables: {
          id: parseInt(id, 10),
        },
      });
    }
    if (mode !== "create" && id) {
      if (mode === "edit") getCourse(id);
      if (mode === "draft") getDraft(id);
    }
  }, [mode]);

  useEffect(() => {
    if (userType !== "individual_tutor") {
      getInstituteTutors();
    }
  }, [userType]);

  useEffect(() => {
    if (data) {
      if (data?.getAllowedCategories?.nodes?.length) {
        const fetchedCategories: any = _.map(
          data.getAllowedCategories.nodes,
          "category"
        );
        if (fetchedCategories) {
          setAllowedCategories(fetchedCategories);
        }
      }
    }
  }, [data]);

  useEffect(() => {
    if (instituteTutors.data) {
      if (instituteTutors?.data?.getInstituteTutors?.nodes?.length) {
        const fetchTutors: any = _.map(
          instituteTutors.data.getInstituteTutors.nodes
        );
        if (fetchTutors) {
          setTutorsList(fetchTutors);
          setTutorsListCopy(fetchTutors);
        }
      }
    }
  }, [instituteTutors]);
  // #endregion
  // #region API Response Handlers
  function handleSuccess(res: any, key: string, path: string) {
    if (res?.data[key]?.status)
      enqueueSnackbar(res.data[key].message, {
        variant: "success",
        autoHideDuration: 3000,
        onExiting: () => {
          navigate(
            `/${
              userType === "individual_tutor" ? "tutor" : "institute"
            }/dashboard/${path}`
          );

          if (key !== "createCourse") {
            setLoading(false);
            setDraftLoading(false);
          }
        },
      });
  }

  function handleError(err: any) {
    console.log(err);
    setLoading(false);
    setDraftLoading(false);
    const message = err?.graphQLErrors[0]?.message
      ? err.graphQLErrors[0].message
      : err.message;
    enqueueSnackbar(message, {
      variant: "error",
      autoHideDuration: 3000,
    });
  }
  // #endregion
  // #region Form Handlers
  function activitySubmit(params: any, { setFieldError }: any) {
    if (
      mode != "edit" &&
      params?.startTime &&
      checkIsBeforeTime(params?.startTime)
    ) {
      enqueueSnackbar("Start time cannot be in the past", {
        variant: "error",
        autoHideDuration: 3000,
      });
    } else {
      setLoading(true);
      if (
        _.compact(params.selectedDays).length <
        parseInt(params.numOfDaysPerWeek, 10)
      ) {
        setFieldError(
          "selectedDays",
          `Select ${params.numOfDaysPerWeek} Day(s) `
        );
        setLoading(false);
      } else if (userType !== "individual_tutor" && !params.tutor) {
        setFieldError("tutor", `Select A Tutor`);
        setLoading(false);
      } else {
        let input: any = {};
        input.tutorId = getTutorId(userType, params);
        if (mode === "edit") input.id = parseInt(id, 10);
        else if (mode === "draft") input.draftId = parseInt(id, 10);
        input = {
          ...input,
          ...getPayloadObject(params),
        };
        if (mode === "edit") {
          updateClassMutation({ variables: { input } })
            .then((res: any) =>
              handleSuccess(res, "updateCourse", "my-classes")
            )
            .catch(handleError);
        } else {
          if (userType !== "individual_tutor") {
            const institutionId = getInstituteId();
            if (institutionId) {
              input.institutionId = institutionId;
            }
          }
          createClassMutation({ variables: { input } })
            .then((res: any) =>
              handleSuccess(res, "createCourse", "my-classes")
            )
            .catch(handleError);
        }
      }
    }
  }

  const checkIsBeforeTime = (time: any) => {
    return isBefore(new Date(time).getTime(), new Date().getTime());
  };

  function createDraftSubmit(values: any) {
    let params: any = {};
    if (values?.title && isTexHasSplChar(values.title)) {
      return false;
    }
    params.tutorId = getTutorId(userType, values);
    if (values?.startTime && checkIsBeforeTime(values?.startTime)) {
      enqueueSnackbar("Start time cannot be in the past", {
        variant: "error",
        autoHideDuration: 3000,
      });
      return false;
    }
    setDraftLoading(true);
    if (mode === "draft") params.draftId = parseInt(id, 10);
    params = {
      ...params,
      ...getPayloadObject(values),
    };
    if (userType !== "individual_tutor") {
      const institutionId = getInstituteId();
      if (institutionId) {
        params.institutionId = institutionId;
      }
    }
    const input = _.pickBy(params, _.identity);
    createDraftClassMutation({ variables: { input } })
      .then((res: any) => handleSuccess(res, "createDraft", "my-classes"))
      .catch(handleError);
  }

  const checkTutorForCategory = (
    value: any,
    setFieldValue?: any,
    fromCategory?: any
  ) => {
    let filteredTutor: any = JSON.parse(JSON.stringify(tutorsListCopy));
    if (fromCategory && value) {
      filteredTutor = filteredTutor.filter((el: any) => {
        el.allowedCategories = el?.allowedCategories?.nodes?.map(
          (el: any) => el?.category?.id
        );
        return el.allowedCategories?.includes(value);
      });
      setTutorsList(filteredTutor);
      if (setFieldValue) {
        setFieldValue("tutor", "");
      }
      if (_.isEmpty(filteredTutor) && userType !== "individual_tutor") {
        enqueueSnackbar("No Tutors Found for the Selected Categoty", {
          variant: "error",
          autoHideDuration: 3000,
        });
      }
    }
  };

  const shouldDisableDate = (day: any, values: any) => {
    const selDays = _.map(values.selectedDays, (v, i) => (v ? i : -1));
    return !selDays.includes(day.getDay());
  };

  // #endregion
  return (
    <>
      <CreateActivityFormContainer
        title={mode === "edit" ? "Edit Class" : "Create New Class"}
        subtitle="Please fill out the following details"
      >
        <a
          className="go-back"
          onClick={() => {
            window.history.back();
          }}
        >
          &lt; back
        </a>
        <Formik
          initialValues={initialValues}
          validationSchema={createActivity}
          onSubmit={activitySubmit}
          enableReinitialize
        >
          {({
            handleSubmit,
            handleChange,
            touched,
            errors,
            setFieldValue,
            values,
          }) => (
            <Form onSubmit={handleSubmit} style={{ width: "100%" }}>
              {courseData.loading || draftData.loading ? (
                <div className="flex center-center">
                  <ColorCircularProgress size={30} thickness={5} />
                </div>
              ) : (
                <>
                  <FieldContainer>
                    <ActivityImageUpload
                      value={values.activity_image}
                      name="activity_image"
                      setFieldValue={setFieldValue}
                    />
                  </FieldContainer>
                  <FieldContainer>
                    <Field
                      error={errors.title && touched.title}
                      helperText={touched.title && errors.title}
                      name="title"
                      onChange={handleChange}
                      label="Class Title"
                      variant="outlined"
                      onKeyPress={restrictSplChar}
                      as={ActivityTextInput}
                    />
                  </FieldContainer>
                  <FieldContainer>
                    <Field
                      name="place"
                      error={errors.place && touched.place}
                      helperText={touched.place && errors.place}
                      onChange={handleChange}
                      label="Place"
                      menuItemValues={places}
                      as={CustomSelectField}
                    />
                  </FieldContainer>
                  {allowedCategories && (
                    <FieldContainer>
                      <Field
                        error={errors.category && touched.category}
                        helperText={touched.category && errors.category}
                        name="category"
                        onChange={(e: any) => {
                          handleChange(e);
                          checkTutorForCategory(
                            e?.target.value,
                            setFieldValue,
                            true
                          );
                        }}
                        label="Select Category for this class"
                        menuItemValues={allowedCategories.map((cat: any) => ({
                          label: cat.name,
                          value: cat.id,
                        }))}
                        as={CustomSelectField}
                      />
                    </FieldContainer>
                  )}
                  <HorizontalFieldsContainer>
                    {userType !== "individual_tutor" && (
                      <FieldContainer50>
                        <Field
                          name="tutor"
                          error={errors.tutor && touched.tutor}
                          helperText={touched.tutor && errors.tutor}
                          onChange={handleChange}
                          label="Select a Tutor"
                          menuItemValues={tutorsList.map((tutor: any) => ({
                            label: tutor.firstName,
                            value: tutor.id,
                          }))}
                          as={CustomSelectField}
                        />
                      </FieldContainer50>
                    )}
                    <FieldContainer50
                      fullWidth={userType !== "individual_tutor" ? false : true}
                    >
                      <Field
                        error={errors.price && touched.price}
                        helperText={touched.price && errors.price}
                        name="price"
                        onChange={handleChange}
                        type="number"
                        label="Price"
                        variant="outlined"
                        as={ActivityTextInput}
                      />
                    </FieldContainer50>
                  </HorizontalFieldsContainer>
                  <FieldContainer>
                    <Field
                      error={touched.description && errors.description}
                      label="Description of the Activity"
                      helperText={touched.description && errors.description}
                      name="description"
                      onChange={handleChange}
                      rows="6"
                      multiline
                      variant="outlined"
                      as={ActivityTextInput}
                    />
                  </FieldContainer>
                  <HorizontalFieldsContainer>
                    <FieldContainer50>
                      <Field
                        error={errors.numOfWeeks && touched.numOfWeeks}
                        helperText={touched.numOfWeeks && errors.numOfWeeks}
                        name="numOfWeeks"
                        onChange={handleChange}
                        label="No. of weeks"
                        menuItemValues={numOfWeeks}
                        as={CustomSelectField}
                      />
                    </FieldContainer50>
                    <FieldContainer50>
                      <Field
                        name="numOfDaysPerWeek"
                        error={
                          errors.numOfDaysPerWeek && touched.numOfDaysPerWeek
                        }
                        helperText={
                          touched.numOfDaysPerWeek && errors.numOfDaysPerWeek
                        }
                        onChange={(e: any) => {
                          handleChange(e);
                          setFieldValue(
                            "selectedDays",
                            initialValues.selectedDays
                          );
                        }}
                        label="No. of days per week"
                        menuItemValues={numOfDays}
                        as={CustomSelectField}
                      />
                    </FieldContainer50>
                  </HorizontalFieldsContainer>
                  <SelectDays
                    error={
                      touched.selectedDays && errors.selectedDays ? true : false
                    }
                    errorText={errors.selectedDays}
                    selectedDays={values.selectedDays}
                    setFieldValue={setFieldValue}
                    numOfDaysSelected={values.numOfDaysPerWeek}
                  />
                  <FieldContainer>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <Field
                        disablePast
                        inputVariant="outlined"
                        error={
                          touched.startDate && errors.startDate ? true : false
                        }
                        helperText={touched.startDate && errors.startDate}
                        autoOk={true}
                        value={values.startDate}
                        variant="inline"
                        shouldDisableDate={(day: any) =>
                          shouldDisableDate(day, values)
                        }
                        onChange={(date: any) => {
                          setFieldValue(`startDate`, date);
                          setFieldValue(
                            "startTime",
                            new Date(
                              new Date(date).getFullYear(),
                              new Date(date).getMonth(),
                              new Date(date).getDate(),
                              new Date(values.startTime).getHours(),
                              new Date(values.startTime).getMinutes()
                            )
                          );
                        }}
                        name={`startDate`}
                        label={"Start Date"}
                        format="d MMM yyyy"
                        as={CustomDatePicker}
                      />
                    </MuiPickersUtilsProvider>
                  </FieldContainer>
                  <HorizontalFieldsContainer>
                    <FieldContainer50>
                      <Field
                        name="duration"
                        error={errors.duration && touched.duration}
                        helperText={touched.duration && errors.duration}
                        onChange={handleChange}
                        label="Duration"
                        menuItemValues={duration}
                        as={CustomSelectField}
                      />
                    </FieldContainer50>
                    <FieldContainer50>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <Field
                          inputVariant="outlined"
                          error={errors.startTime && touched.startTime}
                          helperText={touched.startTime && errors.startTime}
                          autoOk={true}
                          value={values.startTime}
                          minutesStep={15}
                          onChange={(time: any) =>
                            setFieldValue("startTime", time)
                          }
                          variant="inline"
                          name="startTime"
                          label="Start Time"
                          as={CustomTimePicker}
                        />
                      </MuiPickersUtilsProvider>
                    </FieldContainer50>
                  </HorizontalFieldsContainer>
                  <HorizontalFieldsContainer>
                    <FieldContainer50>
                      <Field
                        error={errors.batchSize && touched.batchSize}
                        helperText={touched.batchSize && errors.batchSize}
                        name="batchSize"
                        onChange={handleChange}
                        label="Batch Size"
                        variant="outlined"
                        type="number"
                        as={ActivityTextInput}
                      />
                    </FieldContainer50>
                    <FieldContainer50>
                      <Field
                        name="ageRange"
                        error={errors.ageRange && touched.ageRange}
                        helperText={touched.ageRange && errors.ageRange}
                        onChange={handleChange}
                        label="Age Range"
                        menuItemValues={ageRange}
                        as={CustomSelectField}
                      />
                    </FieldContainer50>
                  </HorizontalFieldsContainer>
                  <SubmitBtnContainer>
                    {mode !== "edit" && (
                      <DraftButtonContainer>
                        <SecondaryCTAButton
                          disabled={isDraftDisabled(values) || draftLoading}
                          onClick={() => createDraftSubmit(values)}
                          type="button"
                          style={{ width: "100%" }}
                        >
                          Save as Draft
                        </SecondaryCTAButton>
                      </DraftButtonContainer>
                    )}
                    <CreateButtonContainer>
                      <PrimaryCTAButton
                        disabled={saveLoading}
                        type="submit"
                        style={{ width: "100%" }}
                      >
                        {mode === "edit" ? "Save" : "Create"}
                      </PrimaryCTAButton>
                    </CreateButtonContainer>
                  </SubmitBtnContainer>
                </>
              )}
            </Form>
          )}
        </Formik>
      </CreateActivityFormContainer>
    </>
  );
};

export default CreateActivity;
