import React from "react";
import { StyledActivityPrice } from "./styles";

interface Props {
  originalPrice: string;
  price: string;
  className?: string;
}

const ActivityPrice: React.FC<Props> = (props: Props) => {
  return (
    <StyledActivityPrice className={props.className}>
      <div className="price noWrap">₹ {props.price}</div>
      {/* <div className="old-price noWrap">₹ {props.originalPrice}</div> */}
    </StyledActivityPrice>
  );
};

export default ActivityPrice;
