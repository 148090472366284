import React from "react";
import OnboardingFormContainer from "../common/OnboardingFormContainer";
import { Formik, Form, Field } from "formik";
import {
  FieldContainer,
  StyledTextInput,
  SubmitBtnContainer,
  ForgotPasswordText,
} from "../common/OnboardingFormContainer/styles";
import { TUTOR_INSTITUTE_LOGIN } from "../../graphql/mutation";
import { PrimaryCTAButton } from "../common/MaterialFields";
import { useSnackbar } from "notistack";
import useAuth from "../../hooks/useAuth";
import { useMutation } from "react-apollo";
import { User } from "../../store";
import { loginSchema } from "../../_helper/ValidationSchema";
import { Link, navigate } from "@reach/router";

interface Props {
  mode: string;
}

const Signin: React.FC<Props> = ({ mode }) => {
  const { userInfo, setUserInfo, setToken } = useAuth();
  const [callSignin, { loading }] = useMutation(TUTOR_INSTITUTE_LOGIN);
  const { enqueueSnackbar } = useSnackbar();

  const onFormSubmit = (values: any) => {
    callSignin({
      variables: {
        input: {
          ...values,
          mode: mode === "tutor" ? mode.toUpperCase() : "INSTITUTION",
        },
      },
    })
      .then((res) => {
        if (res?.data?.tutorAndInstitutionLogin?.status) {
          enqueueSnackbar(res.data.tutorAndInstitutionLogin.message, {
            variant: "success",
            autoHideDuration: 3000,
          });
          console.log(userInfo, setUserInfo);
          User.setToken(res.data.tutorAndInstitutionLogin.token);
          setToken(res.data.tutorAndInstitutionLogin.token);
          const { user, kycSubmission } = res.data.tutorAndInstitutionLogin;
          if (mode === "tutor") {
            if (
              res?.data?.tutorAndInstitutionLogin?.user?.status === "pending"
            ) {
              navigate(`/${mode}/kyc-pending`);
            } else {
              navigate(`/${mode}/dashboard/my-classes`);
            }
          } else {
            if (!user.phoneVerification) {
              navigate(`/${mode}/verify-otp`);
            } else {
              if (!kycSubmission) {
                navigate(`/${mode}-registration-form/complete-kyc`);
              } else {
                navigate(`/${mode}/dashboard/my-classes`);
              }
            }
          }
        }
      })
      .catch((err) => {
        enqueueSnackbar(err?.graphQLErrors[0]?.message, {
          variant: "error",
          autoHideDuration: 3000,
        });
      });
  };

  return (
    <OnboardingFormContainer title={`Signin as ${mode}`}>
      <Formik
        onSubmit={onFormSubmit}
        initialValues={{
          email: "",
          password: "",
        }}
        isInitialValid={false}
        validationSchema={loginSchema}
      >
        {({ handleSubmit, touched, errors, isValid }) => (
          <Form onSubmit={handleSubmit}>
            <FieldContainer>
              <Field
                error={touched.email && errors.email ? true : false}
                autoFocus={true}
                helperText={touched.email && errors.email}
                name="email"
                label="Email"
                variant="outlined"
                as={StyledTextInput}
              />
            </FieldContainer>
            <FieldContainer>
              <Field
                error={touched.password && errors.password ? true : false}
                helperText={touched.password && errors.password}
                name="password"
                label="Password"
                variant="outlined"
                type="password"
                as={StyledTextInput}
              />
            </FieldContainer>

            <ForgotPasswordText>
              <Link to="forgot-password">Forgot Password</Link>
            </ForgotPasswordText>
            <SubmitBtnContainer>
              <PrimaryCTAButton
                disabled={!isValid || loading}
                type="submit"
                style={{ minWidth: 360 }}
              >
                Continue
              </PrimaryCTAButton>
            </SubmitBtnContainer>
          </Form>
        )}
      </Formik>
    </OnboardingFormContainer>
  );
};

export default Signin;
