import React, { useEffect, useState } from "react";
import {
  ViewActivityWrapper,
  StyledDescription,
  ActiityStatusBadge,
} from "./style";
import {
  ActivityImage,
  ActivityTitle,
  DetailElement,
} from "../MyClasses/style";
import { Hidden } from "@material-ui/core";
import { Images } from "../../../assets/images";
import ViewActivitySection from "./ViewActivitySection";
import ActivityPrice from "../../ActivityPrice";
import ActivityCardTutorInfo from "../../common/ActivityCardTutorInfo";
import ActivityStats from "../../common/ActivityStats";
import ActivityEnrollmentSection from "../../ActivityEnrollment";
import {
  GET_COURSE_BY_ID,
  GET_DRAFT_BY_ID,
  GET_UNDER_REVIEW_ID,
} from "../../../graphql/queries";
import { useLazyQuery } from "react-apollo";
import { format } from "date-fns";
import {
  ColorCircularProgress,
  PrimaryCTAButton,
} from "../../common/MaterialFields";
import { AGE_GROUP } from "../../../_helper/enums";
import { Heading } from "../style";
import { Link } from "@reach/router";
import useAuth from "../../../hooks/useAuth";
import { setWeeks } from "../MyClasses/ActivityCard";

interface Props {
  id?: string;
  viewStatus?: string;
  institute?: boolean;
}

const ClassDetails: React.FC<Props> = ({ id, viewStatus, institute }) => {
  const [course, setCourse] = useState<any>();
  const [loading, setLoading] = useState<boolean>(true);
  const [inActiveTutor, SetInactiveTutor] = useState<any>();
  const { token } = useAuth();
  const [callActiveCourse, activeCourseRes] = useLazyQuery(GET_COURSE_BY_ID, {
    fetchPolicy: "no-cache",
    variables: {
      id: Number(id),
    },
  });

  const [callDraftCourse, draftcourseRes] = useLazyQuery(GET_DRAFT_BY_ID, {
    fetchPolicy: "no-cache",
    variables: {
      id: Number(id),
    },
  });

  const openLink = () => {
    let URL = `${process.env.REACT_APP_KYC_VERIFICATION_URL}/start-zoom?jwt=${token}&course_id=${course?.id}&redirect_url=${process.env.REACT_APP_ZOOM_REDIRECTION}`;
    window.open(URL, "_blank");
  };

  const [callPendingCourse, pendingCoursecourseRes] = useLazyQuery(
    GET_UNDER_REVIEW_ID,
    {
      fetchPolicy: "no-cache",
      variables: {
        id: Number(id),
      },
    }
  );
  useEffect(() => {
    setLoading(true);
    switch (viewStatus) {
      case "active": {
        callActiveCourse();
        break;
      }
      case "draft": {
        callDraftCourse();
        break;
      }
      case "under_review": {
        callPendingCourse();
        break;
      }
      default: {
        break;
      }
    }
  }, []);

  useEffect(() => {
    if (viewStatus === "active" && activeCourseRes?.data) {
      setCourse(activeCourseRes?.data?.getCourse);
      setLoading(false);
    }
    if ((viewStatus = "draft" && draftcourseRes?.data?.getDraft?.params)) {
      setCourse(draftcourseRes?.data?.getDraft?.params);
      SetInactiveTutor(draftcourseRes?.data?.getDraft?.tutor);
      setLoading(false);
    }

    if (
      (viewStatus =
        "under_review" &&
        pendingCoursecourseRes?.data?.getCourseWaitingForApproval?.params)
    ) {
      setCourse(
        pendingCoursecourseRes?.data?.getCourseWaitingForApproval?.params
      );
      SetInactiveTutor(
        pendingCoursecourseRes?.data?.getCourseWaitingForApproval?.tutor
      );
      setLoading(false);
    }
  }, [
    activeCourseRes?.data,
    draftcourseRes?.data,
    pendingCoursecourseRes?.data,
  ]);

  return (
    <div className="p10">
      {loading ? (
        <div
          style={{ minHeight: "calc(100vh - 150px)" }}
          className="flex center-center h50p"
        >
          <ColorCircularProgress size={50} thickness={5} />
        </div>
      ) : (
        <ViewActivityWrapper>
          <div className="flex row pb30 wrap center-center">
            <Heading
              className="flex start-center flex-1"
              padding="0px"
              fontSize="20px"
            >
              <div className="pointer" onClick={() => window.history.back()}>
                Classes
              </div>
              <div className="pl15 pr15"> > </div>
              <div> {course?.title}</div>
            </Heading>
            {viewStatus !== "under_review" && (
              <div>
                {viewStatus === "draft" ? (
                  <Link
                    to={`/${
                      institute ? "institute" : "tutor"
                    }/dashboard/draft/${id}`}
                    // as={`/${
                    //   institute ? "institute" : "tutor"
                    // }/dashboard/draft/${id}`}
                  >
                    <PrimaryCTAButton style={{ width: "192px" }}>
                      Edit Details
                    </PrimaryCTAButton>
                  </Link>
                ) : (
                  <Link
                    to={`/${
                      institute ? "institute" : "tutor"
                    }/dashboard/my-classes/${id}/edit`}
                    // as={`/${
                    //   institute ? "institute" : "tutor"
                    // }/dashboard/my-classes/${id}/edit`}
                  >
                    <PrimaryCTAButton style={{ width: "192px" }}>
                      Edit Details
                    </PrimaryCTAButton>
                  </Link>
                )}
              </div>
            )}
          </div>
          <div className="image-title-section">
            <div className="flex">
              <ActivityImage
                desktop={305}
                mobile={102}
                tab={220}
                src={
                  course?.bannerImageUrl
                    ? course?.bannerImageUrl
                    : Images.coursePlaceHolder
                }
              />
              <Hidden implementation="css" smUp>
                <div className="ml20">
                  <ActivityTitle>{course?.title}</ActivityTitle>
                </div>
              </Hidden>
            </div>
            <div className="content">
              <Hidden implementation="css" xsDown>
                <ActivityTitle className="title f28">
                  {course?.title}
                </ActivityTitle>
              </Hidden>
              {course?.tutor?.firstName && viewStatus === "active" ? (
                <ActivityCardTutorInfo
                  isFromView={true}
                  fontSize={"16px"}
                  className="tutor-info"
                  place={course?.place}
                  name={course?.tutor?.firstName}
                  src={
                    course?.tutor?.avatarUrl
                      ? course?.tutor?.avatarUrl
                      : Images.person
                  }
                />
              ) : (
                <>
                  {inActiveTutor && (
                    <ActivityCardTutorInfo
                      isFromView={true}
                      fontSize={"16px"}
                      className="tutor-info"
                      place={course?.place}
                      name={inActiveTutor.firstName}
                      src={
                        inActiveTutor.avatarUrl
                          ? inActiveTutor.avatarUrl
                          : Images.person
                      }
                    />
                  )}
                </>
              )}
              <ActiityStatusBadge>Status : {viewStatus}</ActiityStatusBadge>
              {course?.price && (
                <ActivityPrice
                  className="in-view-details"
                  originalPrice={course?.price}
                  price={course?.price}
                />
              )}
              {course?.zoomMeeting?.startTime && (
                <div className="w150 flex align-center">
                  <DetailElement type="isLabel" className="noWrap p5">
                    Online Class Link
                  </DetailElement>
                  <div className="pl5">
                    <PrimaryCTAButton
                      className="ml10 w100 f12"
                      disableElevation
                      color="primary"
                      variant="contained"
                      onClick={openLink}
                    >
                      Start
                    </PrimaryCTAButton>
                  </div>
                </div>
              )}
            </div>
          </div>
          <ViewActivitySection
            sectionTitle="Description"
            contentStyles={{ marginTop: 12 }}
          >
            <StyledDescription>
              {course?.description ? course?.description : "Nil"}
            </StyledDescription>
          </ViewActivitySection>
          <ViewActivitySection
            sectionTitle="Days of Week"
            contentStyles={{ marginTop: 12 }}
          >
            <StyledDescription>
              {setWeeks(course) ? setWeeks(course) : "Nil"}
            </StyledDescription>
          </ViewActivitySection>
          <div className="pt20">
            <ActivityStats
              startDate={`${
                course?.startDate
                  ? format(new Date(course?.startDate), "MMMM dd, yyyy")
                  : "Nil"
              }`}
              duration={
                course?.numberOfWeeks ? `${course?.numberOfWeeks} Weeks` : "Nil"
              }
              batchSize={course?.maxCount}
              ageRange={course?.ageGroup ? AGE_GROUP[course?.ageGroup] : "Nil"}
            />
          </div>
          <ViewActivitySection
            sectionTitle="Session Timing"
            contentStyles={{ marginTop: 30 }}
          >
            <div className="session-timming">
              {course?.startTime
                ? format(new Date(course?.startTime), "h:mm a")
                : "Nil"}
              &nbsp; - &nbsp;
              {course?.endTime
                ? format(new Date(course?.endTime), "h:mm a")
                : "Nil"}
            </div>
          </ViewActivitySection>
          {/* <ViewActivitySection
            sectionTitle="Cancellation Policy"
            contentStyles={{ marginTop: 30 }}
          >
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua.
          </ViewActivitySection> */}
          <ViewActivitySection
            sectionTitle={`Enrolments (${
              course?.maxCount
                ? course?.maxCount -
                  (viewStatus === "active"
                    ? course?.remainingSeats
                    : course?.maxCount)
                : "-"
            }/${course?.maxCount ? course?.maxCount : "-"})`}
            contentStyles={{ marginTop: 30 }}
          >
            <ActivityEnrollmentSection data={course?.bookings?.nodes} />
          </ViewActivitySection>
        </ViewActivityWrapper>
      )}
    </div>
  );
};

export default ClassDetails;
