import React, { useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";
import { DialogWrapper, ContentContainer, FieldsContainer } from "../style";
import { Hidden } from "@material-ui/core";
import DialogHeader from "../../DialogHeader";
import { StyledTextInput } from "../../common/OnboardingFormContainer/styles";
import CustomSelectField from "../../CreateActivity/Fields/CustomSelectField";
import FileUploadInput from "../../common/FileUploadInput";
import { PrimaryCTAButton } from "../../common/MaterialFields";
import { Heading } from "../../SelectTutor/styles";
import { createTutorSchema } from "../../../_helper/ValidationSchema";
import _ from "lodash";
import { useQuery, useMutation } from "react-apollo";
import { useSnackbar } from "notistack";
import { CREATE_TUTOR } from "../../../graphql/mutation";
import { GET_ALLOWED_CATEGORIES } from "../../../graphql/queries";
import useAuth from "../../../hooks/useAuth";
import { ErrorFocus } from "../../../_helper/errorFocus";

interface Props {
  setOpendialog: React.Dispatch<React.SetStateAction<boolean>>;
}

const Addtutor: React.FC<Props> = ({ setOpendialog }) => {
  const [ppCopy, setPpcopy] = useState<string>("");
  const { token } = useAuth();
  const CategoriesResponse = useQuery(GET_ALLOWED_CATEGORIES, {
    fetchPolicy: "no-cache",
  });
  const [categories, setCategories] = useState([]);
  const [callCreateTutor, { loading }] = useMutation(CREATE_TUTOR);
  const { enqueueSnackbar } = useSnackbar();
  const [loadingFile, setFileLoading] = useState<boolean>(false);

  useEffect(() => {
    if (CategoriesResponse?.data?.getAllowedCategories?.nodes?.length) {
      const fetchedCategories: any = _.map(
        CategoriesResponse.data.getAllowedCategories.nodes,
        "category"
      );
      if (fetchedCategories) {
        setCategories(fetchedCategories);
      }
    }
  }, [CategoriesResponse.data]);

  const onFormSubmit = (values: any) => {
    const postObj = JSON.parse(JSON.stringify(values));
    delete postObj.pp_size_photo;
    postObj.avatarUrl = ppCopy;
    callCreateTutor({
      variables: {
        input: postObj,
      },
    })
      .then((res) => {
        if (res?.data?.createTutor?.status) {
          enqueueSnackbar(res.data.createTutor.message, {
            variant: "success",
            autoHideDuration: 3000,
          });
          setOpendialog(false);
          if (token && res?.data?.createTutor?.user?.id) {
            window.open(
              `${process.env.REACT_APP_KYC_VERIFICATION_URL}/verify-aadhar?jwt=${token}&tutor_id=${res?.data?.createTutor?.user?.id}&redirect_url=${process.env.REACT_APP_AADHAR_REDIRECT_URL}/${res?.data?.createTutor?.user?.id}`,
              "_self"
            );
          }
        }
      })
      .catch((err) => {
        console.log(err);
        enqueueSnackbar(err.graphQLErrors[0].message, {
          variant: "error",
          autoHideDuration: 3000,
        });
      });
  };

  return (
    <Formik
      initialValues={{
        firstName: "",
        gender: "",
        phoneNumber: "",
        email: "",
        address: "",
        allowedCategories: [],
        panNumber: "",
        pp_size_photo: "",
        overview: "",
        education: "",
        certification: "",
        totalExperience: "",
        awards: "",
      }}
      validationSchema={createTutorSchema}
      isInitialValid={false}
      enableReinitialize={false}
      onSubmit={onFormSubmit}
      render={({ handleSubmit, values, errors, setFieldValue, touched }) => (
        <Form onSubmit={handleSubmit}>
          <DialogWrapper>
            <Hidden implementation="css" xsDown>
              <DialogHeader
                triggerClose={() => setOpendialog(false)}
                showHeader={true}
                heading="Add tutor"
              />
            </Hidden>
            <Hidden implementation="css" smUp>
              <DialogHeader
                triggerClose={() => setOpendialog(false)}
                showLogo={true}
              />
            </Hidden>
            <Hidden implementation="css" smUp>
              <Heading className="pt30">Add tutor</Heading>
            </Hidden>
            <ContentContainer>
              <FieldsContainer>
                <Field
                  error={errors.firstName && touched.firstName}
                  helperText={touched.firstName && errors.firstName}
                  autoFocus={true}
                  name="firstName"
                  id="outlined-basic"
                  label="Name"
                  variant="outlined"
                  as={StyledTextInput}
                />
              </FieldsContainer>
              <FieldsContainer>
                <Field
                  error={errors.gender && touched.gender}
                  helperText={touched.gender && errors.gender}
                  name="gender"
                  label="Gender"
                  menuItemValues={[
                    { label: "Male", value: "male" },
                    { label: "Female", value: "female" },
                  ]}
                  as={CustomSelectField}
                />
              </FieldsContainer>
              <FieldsContainer>
                <Field
                  error={errors.phoneNumber && touched.phoneNumber}
                  helperText={touched.phoneNumber && errors.phoneNumber}
                  name="phoneNumber"
                  inputProps={{ maxLength: 10 }}
                  id="outlined-basic"
                  label="Phone Number"
                  variant="outlined"
                  as={StyledTextInput}
                />
              </FieldsContainer>
              <FieldsContainer>
                <Field
                  error={errors.email && touched.email}
                  helperText={touched.email && errors.email}
                  name="email"
                  id="outlined-basic"
                  label="Email"
                  variant="outlined"
                  as={StyledTextInput}
                />
              </FieldsContainer>
              <FieldsContainer>
                <Field
                  error={errors.address && touched.address}
                  helperText={touched.address && errors.address}
                  name="address"
                  id="outlined-basic"
                  label="Proof of Address"
                  variant="outlined"
                  as={StyledTextInput}
                />
              </FieldsContainer>
              {categories && (
                <FieldsContainer>
                  <Field
                    error={
                      touched.allowedCategories && errors.allowedCategories
                        ? true
                        : false
                    }
                    helperText={
                      touched.allowedCategories && errors.allowedCategories
                    }
                    name="allowedCategories"
                    id="outlined-basic"
                    label="Specialization"
                    variant="outlined"
                    as={CustomSelectField}
                    value={values.allowedCategories}
                    multiple
                    menuItemValues={categories.map((category: any) => ({
                      label: category.name,
                      value: category.id,
                    }))}
                    selectedItems={values.allowedCategories}
                  />
                </FieldsContainer>
              )}
              <FieldsContainer>
                <Field
                  error={errors.panNumber && touched.panNumber}
                  helperText={touched.panNumber && errors.panNumber}
                  name="panNumber"
                  id="outlined-basic"
                  label="PAN Number"
                  variant="outlined"
                  as={StyledTextInput}
                />
              </FieldsContainer>
              <FieldsContainer>
                <Field
                  error={
                    errors.pp_size_photo && touched.pp_size_photo ? true : false
                  }
                  helperText={
                    touched.pp_size_photo && errors.pp_size_photo
                      ? errors.pp_size_photo
                      : ""
                  }
                  name="pp_size_photo"
                  acceptType="image/*"
                  onChange={(url: string) => setPpcopy(url)}
                  label="Clear Passport Size Photo"
                  setFieldValue={setFieldValue}
                  setFileLoading={(loading: boolean) => setFileLoading(loading)}
                  as={FileUploadInput}
                />
              </FieldsContainer>
              <FieldsContainer>
                <Field
                  error={touched.overview && errors.overview ? true : false}
                  helperText={touched.overview && errors.overview}
                  name="overview"
                  id="outlined-basic"
                  label="Overview (Optional)"
                  variant="outlined"
                  rows="6"
                  multiline
                  as={StyledTextInput}
                />
              </FieldsContainer>
              <FieldsContainer>
                <Field
                  error={touched.education && errors.education ? true : false}
                  helperText={touched.education && errors.education}
                  name="education"
                  id="outlined-basic"
                  label="Education (Optional)"
                  variant="outlined"
                  rows="6"
                  multiline
                  as={StyledTextInput}
                />
              </FieldsContainer>
              <FieldsContainer>
                <Field
                  error={
                    touched.certification && errors.certification ? true : false
                  }
                  helperText={touched.certification && errors.certification}
                  name="certification"
                  id="outlined-basic"
                  label="Certification (Optional)"
                  variant="outlined"
                  rows="6"
                  multiline
                  as={StyledTextInput}
                />
              </FieldsContainer>
              <FieldsContainer>
                <Field
                  error={
                    touched.totalExperience && errors.totalExperience
                      ? true
                      : false
                  }
                  helperText={touched.totalExperience && errors.totalExperience}
                  name="totalExperience"
                  id="outlined-basic"
                  label="Total Experience (Optional)"
                  variant="outlined"
                  as={StyledTextInput}
                />
              </FieldsContainer>
              <FieldsContainer>
                <Field
                  error={touched.awards && errors.awards ? true : false}
                  helperText={touched.awards && errors.awards}
                  name="awards"
                  id="outlined-basic"
                  label="Awards (Optional)"
                  variant="outlined"
                  rows="6"
                  multiline
                  as={StyledTextInput}
                />
              </FieldsContainer>
              <ErrorFocus />
            </ContentContainer>
            <div className="flex center-center">
              <FieldsContainer>
                <PrimaryCTAButton
                  type="submit"
                  disabled={loadingFile || loading}
                  style={{ width: "100%" }}
                >
                  Complete KYC
                </PrimaryCTAButton>
              </FieldsContainer>
            </div>
          </DialogWrapper>
        </Form>
      )}
    />
  );
};

export default Addtutor;
