import React from "react";
import theme from "../../../assets/theme";
import COLORS from "../../../assets/colors";
import styled from "styled-components";

interface Props {
  message: string;
}

const NoResultsWrapper = styled.div`
  padding: 36px;
  background-color: ${COLORS.PRIMARY_WHITE};
  box-shadow: 0 2px 27px 0 rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  display: flex;
  width: 100%;
  justify-content: center;
  max-width: 944px;
  margin-bottom: 30px;

  @media screen and (max-width: 960px) {
    box-shadow: none;
  }

  @media screen and ${theme.breakpoints.xs} {
    margin: 0;
  }
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 387px;
    .heading {
      font-size: 24px;
      font-weight: 500;
      font-family: ${theme.fonts.maxroundedmedium};
    }
    p {
      margin: 36px 0 41px;
      font-size: 16px;
      line-height: 1.31;
      opacity: 0.47;
      text-align: center;
    }
  }
`;

const Index: React.FC<Props> = ({ message }) => {
  return (
    <NoResultsWrapper>
      <div className="content">
        <div className="heading">No matching results</div>
        <p>{message}</p>
      </div>
    </NoResultsWrapper>
  );
};

export default Index;
