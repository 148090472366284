import styled from "styled-components";
import COLORS from "../../../assets/colors";
import { CustomTextField } from "../MaterialFields";
import theme from "../../../assets/theme";

export const StyledFormContainer = styled.div`
  padding: 27px;
  max-width: 520px;
  margin: 0 auto;
  box-shadow: 0 2px 27px 0 rgba(0, 0, 0, 0.06);
  border-radius: 10px;
  background-color: ${COLORS.PRIMARY_WHITE};

  display: flex;
  flex-direction: column;
  align-items: center;
  .form-title {
    text-align: center;
    font-size: 30px;
    font-family: ${(props) => props.theme.fonts.maxroundedmedium};
    margin-bottom: 15px;
  }
  .form-subtitle {
    line-height: 1.5;
    font-size: 16px;
    margin-bottom: 18px;
    text-align: center;
    max-width: 282px;
    color: ${COLORS.TUTOR_OPTION_PARAGRAPH};
  }
  .back-to-home {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    @media (max-width: 575px) {
      margin: 15px 0px;
      bottom: 20px;
      position: fixed;
      top: auto;
    }
  }
  .reset-text {
    font-family: ${theme.fonts.montserrat};
    font-size: 14px;
    font-weight: 300;
    color: #1e2d4f;
    .link {
      margin-left: 5px;
      cursor: pointer;
      color: #7632ff;
      text-decoration: underline;
      .not-allowed {
        cursor: not-allowed;
      }
    }
  }
`;

export const FieldContainer = styled.div`
  margin: 24px auto;
  max-width: 464px;
  @media (max-width: 575px) {
    margin: 15px auto;
    width: 100%;
    max-width: calc(100vw - 15px);
  }
`;

export const StyledTextInput = styled(CustomTextField)<any>`
  max-width: 464px;
  @media (max-width: 575px) {
    width: 100%;
  }
`;

export const TextCenterInput = styled(StyledTextInput)<any>`
  input,
  p {
    text-align: center;
  }
`;

export const TermsNote = styled.div`
  text-align: center;
  a {
    text-decoration: none;
    color: ${COLORS.PRIMARY_PURPLE};
    line-height: 2;
  }
`;

export const SubmitBtnContainer = styled.div`
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const ChangeNumberLink = styled.a`
  text-decoration: none;
  color: ${COLORS.PRIMARY_PURPLE};
  line-height: 2;
  cursor: pointer;
  font-family: ${(props) => props.theme.fonts.maxroundedmedium};
`;

export const RadioLegend = styled.div`
  font-family: ${(props) => props.theme.fonts.maxroundedmedium};
  font-weight: 500;
  font-size: 16px;
  margin: 40px 0 10px;
`;

export const ForgotPasswordText = styled.p`
  text-align: center;
  cursor: pointer;
`;
