import styled from "styled-components";
import COLORS from "../../../assets/colors";

export const ImageUploadBox = styled.div`
  width: 150px;
  height: 150px;
  background-color: ${COLORS.IMAGE_UPLOAD_BG};
  margin: 0 auto 49px;
  border-radius: 14px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
  .overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.8) 0%,
      rgba(62, 62, 62, 0.5) 53%,
      rgba(255, 255, 255, 0.3) 100%
    );
    overflow: hidden;
    border-radius: 14px;
  }
  .preview {
    width: 150px;
    height: 150px;
    object-fit: cover;
    overflow: hidden;
    border-radius: 14px;
  }
  .upload-text {
    font-family: ${props => props.theme.fonts.maxroundedmedium};
    color: ${COLORS.PRIMARY_PURPLE};
    font-size: 16px;
    font-weight: 500;
    position: absolute;
    bottom: 20px;
  }
  .white {
    color: ${COLORS.PRIMARY_WHITE} !important;
  }
`;
