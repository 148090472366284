import React from "react";
import { StyledFormContainer } from "./styles";

interface Props {
  title: string;
  subtitle?: any;
  children?: any;
}

const OnboardingFormContainer: React.FC<Props> = ({
  title,
  subtitle,
  children,
}) => {
  return (
    <StyledFormContainer>
      <div className="form-title">{title}</div>
      <div className="form-subtitle">{subtitle}</div>
      {children}
    </StyledFormContainer>
  );
};

export default OnboardingFormContainer;
