import React from "react";
import styled from "styled-components";
import theme from "../../../assets/theme";
import { Heading } from "./styles";
import { Para } from "./styles";
import { Images } from "../../../assets/images";
import Avatar from "../../common/Avatar";
interface Props {
  data?: any;
  institute?: boolean;
}

const ProfileOverView: React.FC<Props> = ({ institute, data }) => {
  return (
    <Container>
      <ProfileHeader>
        <StyledAvatar
          src={data?.avatarUrl ? data?.avatarUrl : Images.userUploadPlaceHolder}
        />
        <Right>
          <Name>{data?.firstName}</Name>
          <Address>{data?.address}</Address>
          <InfoContainer>
            <Info style={{ marginRight: 20 }}>
              <Key>Classes : </Key>
              <Values>
                {data?.allowedCategories?.nodes?.map(
                  (el: any, index: number) => {
                    return (
                      <Value key={index}>
                        {el?.category?.name}
                        {index < data?.allowedCategories?.nodes.length - 1
                          ? ","
                          : ""}
                      </Value>
                    );
                  }
                )}
              </Values>
            </Info>
            {data?.totalExperience ? (
              <Info>
                <Key>Experience : </Key>
                <Value>{data?.totalExperience}</Value>
              </Info>
            ) : null}
          </InfoContainer>
        </Right>
      </ProfileHeader>
      <MobileInfoContainer>
        <Info style={{ marginRight: 20 }}>
          <Key>Classes : </Key>
          <Values>
            {data?.allowedCategories?.nodes?.map((el: any, index: number) => {
              return (
                <Value key={index}>
                  {el?.category?.name}
                  {index < data?.allowedCategories?.nodes.length - 1 ? "," : ""}
                </Value>
              );
            })}
          </Values>
        </Info>
        {data?.totalExperience ? (
          <Info>
            <Key>Experience : </Key>
            <Value>{data?.totalExperience}</Value>
          </Info>
        ) : null}
      </MobileInfoContainer>
      {data?.overview ? (
        <>
          <Divider></Divider>
          <OverView>
            <Heading>{institute ? "Business Information" : "Overview"}</Heading>
            <Para>{data?.overview}</Para>
          </OverView>
        </>
      ) : null}
    </Container>
  );
};

export default ProfileOverView;

const Container = styled.div``;

const ProfileHeader = styled.div`
  display: flex;
  height: 140px;
  min-height: 140px;
  @media screen and ${theme.breakpoints.xs_down} {
    height: auto;
    min-height: auto;
  }
`;

const StyledAvatar = styled(Avatar)`
  width: 127px;
  height: 127px;
  @media screen and ${theme.breakpoints.xs_down} {
    width: 71px;
    height: 71px;
  }
`;

const Right = styled.div`
  padding-left: 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
  @media screen and ${theme.breakpoints.xs_down} {
    padding-left: 13px;
  }
`;

const Name = styled.p`
  font-size: 16px;
  font-family: ${theme.fonts.montserratsemibold};
  letter-spacing: 0.67px;
  color: #1e2d4f;
`;

const Address = styled.p`
  font-size: 12px;
  color: #202f51;
  font-family: ${theme.fonts.maxrounded};
  margin-top: 16px;
  @media screen and ${theme.breakpoints.xs_down} {
    margin-top: 8px;
  }
`;

const InfoContainer = styled.div`
  margin-top: 16px;
  display: flex;
  flex-wrap: wrap;
  @media screen and ${theme.breakpoints.xs_down} {
    display: none;
  }
`;

const MobileInfoContainer = styled.div`
  display: none;
  @media screen and ${theme.breakpoints.xs_down} {
    margin-top: 20.4px;
    display: flex;
    flex-wrap: wrap;
  }
`;

const Info = styled.div`
  display: flex;
  margin-bottom: 10px;
`;
const Key = styled.p`
  line-height: 1.2;
  opacity: 0.7;
  font-size: 12px;
  color: #202f51;
  font-family: ${theme.fonts.montserratmedium};
  white-space: nowrap;
`;
const Values = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
const Value = styled.p`
  margin-left: 4px;
  color: #202f51;
  font-size: 12px;
  font-family: ${theme.fonts.montserratmedium};
  white-space: nowrap;
`;

const Divider = styled.div`
  width: 100%;
  opacity: 0.19;
  border: solid 0.2px #979797;
  margin: 24px 0;
`;

const OverView = styled.div`
  margin-top: 4px;
`;
