import React, { useState, useEffect } from "react";
import { FormGroup, Checkbox } from "@material-ui/core";
import styled from "styled-components";
import { Images } from "../../../assets/images";
import { CustomLabel, CustomCheckBoxLabel } from "../../common/MaterialFields";
import _ from "lodash";
import theme from "../../../assets/theme";

interface Props {
  selectedDays: any;
  setFieldValue?: any;
  numOfDaysSelected?: any;
  error?: boolean;
  errorText?: any;
}

const Days = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

const SelectDays: React.FC<Props> = ({
  selectedDays = [],
  setFieldValue = () => {},
  numOfDaysSelected = null,
  error,
  errorText = "",
}) => {
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    if (!numOfDaysSelected) {
      setDisabled(true);
    } else {
      const numberOfAlreadySelected = _.compact(selectedDays).length;
      if (numberOfAlreadySelected >= numOfDaysSelected) {
        setDisabled(true);
      } else {
        setDisabled(false);
      }
    }
  }, [selectedDays, numOfDaysSelected]);

  function handleChange(event: any, index: any) {
    let changedDays = [...selectedDays];
    changedDays[index] = event.target.checked;
    setFieldValue("selectedDays", changedDays);
  }

  return (
    <Container>
      <CustomLabel error={error} disabled={!numOfDaysSelected}>
        Select Days of Week {error && `- ${errorText}`}
      </CustomLabel>
      <FormGroup style={{ marginTop: 13 }} row>
        {Days.map((day, index) => (
          <CustomCheckBoxLabel
            key={day}
            control={
              <Checkbox
                checked={selectedDays[index]}
                disabled={selectedDays[index] || !disabled ? false : true}
                checkedIcon={<CheckMark src={Images.checkedIcon} />}
                icon={<CheckMark src={Images.uncheckedIcon} />}
                onChange={(event) => handleChange(event, index)}
              />
            }
            label={day}
          />
        ))}
      </FormGroup>
    </Container>
  );
};

export default SelectDays;

const Container = styled.div`
  max-width: 464px;
  padding: 0 5px;
  margin: 40px auto;
  @media ${theme.breakpoints.sm_down} {
    & .MuiFormGroup-row {
      flex-direction: column !important;
    }
  }
`;

const CheckMark = styled.img`
  height: 20px;
  width: 20px;
`;
