import React from "react";
import SwipeableViews from "react-swipeable-views";
import { makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { SelectTutorCard, TextContainer, Heading, Paragraph } from "./styles";
import { SecondaryCTAButton, PrimaryCTAButton } from "../common/MaterialFields";
import { navigate } from "@reach/router";
import theme from "../../assets/theme";

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3} display="flex" flexDirection="column" alignItems="center">
          {children}
        </Box>
      )}
    </Typography>
  );
}

function a11yProps(index: any) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((muitheme: Theme) => ({
  root: {
    backgroundColor: muitheme.palette.background.paper,
    minWidth: 300,
    maxWidth: 500,
    border: "1px solid #8f96a788",
    borderRadius: 10,
    overflow: "hidden",
  },
  appbar: {
    backgroundColor: "#f0f0f0",
  },
  tabs: {
    fontFamily: theme.fonts.maxroundedmedium,
    marginBottom: "10px !important",
    marginTop: "10px !important",
  },
}));

const SelectTutor = () => {
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (_event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index: number) => {
    setValue(index);
  };

  return (
    <SelectTutorCard>
      <TextContainer>
        <Heading>Select one</Heading>
        <Paragraph>
          You can join Activkido as an individual tutor or as an institute.
          Please select one from below to proceed
        </Paragraph>
      </TextContainer>
      <div className={classes.root}>
        <AppBar position="static" color="default" className={classes.appbar}>
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
            aria-label="full width tabs example"
          >
            <Tab label="Register" className={classes.tabs} {...a11yProps(0)} />
            <Tab label="Login" className={classes.tabs} {...a11yProps(1)} />
          </Tabs>
        </AppBar>
        <SwipeableViews
          axis={theme.direction === "rtl" ? "x-reverse" : "x"}
          index={value}
          onChangeIndex={handleChangeIndex}
        >
          <TabPanel value={value} index={0} dir={theme.direction}>
            <PrimaryCTAButton
              style={{
                width: "100%",
                maxWidth: 350,
                fontSize: 20,
              }}
              onClick={() => navigate("tutor-registration-form")}
            >
              Signup as Individual
            </PrimaryCTAButton>
            <SecondaryCTAButton
              style={{
                width: "100%",
                maxWidth: 350,
                fontSize: 20,
              }}
              onClick={() => navigate("institute-registration-form")}
            >
              Signup as Institute
            </SecondaryCTAButton>
          </TabPanel>
          <TabPanel value={value} index={1} dir={theme.direction}>
            <PrimaryCTAButton
              style={{
                width: "100%",
                maxWidth: 350,
                fontSize: 20,
              }}
              onClick={() => navigate("tutor/signin")}
            >
              Signin as Tutor
            </PrimaryCTAButton>
            <SecondaryCTAButton
              style={{
                width: "100%",
                maxWidth: 350,
                fontSize: 20,
              }}
              onClick={() => navigate("institute/signin")}
            >
              Signin as Institute
            </SecondaryCTAButton>
          </TabPanel>
          <TabPanel value={value} index={2} dir={theme.direction}>
            Item Three
          </TabPanel>
        </SwipeableViews>
      </div>
    </SelectTutorCard>
  );
};

export default SelectTutor;
