import styled from "styled-components";
import theme from "../../assets/theme";
import COLORS from "../../assets/colors";

export const EnrollmentWrapper = styled.div`
  padding: 25px;
  background: #f9f9f9;
  overflow: auto;
  max-width: calc(100vw - 480px);
  @media screen and ${theme.breakpoints.mat_md_down} {
    max-width: 100%;
  }
  .header-section {
    font-family: ${theme.fonts.montserratmedium};
    font-size: 18px;
    font-weight: 500;
    color: ${COLORS.PRIMARY_BLACK};
    display: flex;
    @media screen and ${theme.breakpoints.xs_down} {
      font-size: 14px;
    }
  }
  .content {
    font-family: ${theme.fonts.montserrat};
    font-size: 18px;
    font-weight: 300;
    color: ${COLORS.PRIMARY_BLACK};
    @media screen and ${theme.breakpoints.xs_down} {
      font-size: 14px;
    }
  }

  .flex-5 {
    flex: 5%;
  }
  .flex-30 {
    flex: 30%;
  }
  .flex-20 {
    flex: 20%;
  }
  .hidden {
    visibility: hidden;
  }
  .link {
    color: ${COLORS.LINK_COLOR};
    cursor: pointer;
    border-bottom: 1px solid ${COLORS.LINK_COLOR};
    width: 60px;
    @media screen and ${theme.breakpoints.xs_down} {
      width: 50px;
    }
  }
`;
