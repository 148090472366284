import gql from "graphql-tag";

const TUTOR_SIGNUP = gql`
  mutation tutorSignUp($input: TutorSignUpInput!) {
    tutorSignUp(input: $input) {
      message
      status
      token
      user {
        phoneVerification
      }
    }
  }
`;

const INSTITUTE_SIGNUP = gql`
  mutation instituteSignup($input: InstituteSignupInput!) {
    instituteSignup(input: $input) {
      message
      status
      user {
        phoneVerification
        status
      }
    }
  }
`;

const SEND_OTP = gql`
  mutation sendOtp($input: SendOtpInput!) {
    sendOtp(input: $input) {
      message
      status
    }
  }
`;

const UPDATE_TUTOR = gql`
  mutation updateTutor($input: UpdateTutorInput!) {
    updateTutor(input: $input) {
      message
      status
      token
    }
  }
`;

const UPDATE_INSTITUTE = gql`
  mutation updateInstitution($input: UpdateInstitutionInput!) {
    updateInstitution(input: $input) {
      message
      status
    }
  }
`;

const OTP_LOGIN = gql`
  mutation otpLogin($input: OtpLoginInput!) {
    otpLogin(input: $input) {
      message
      status
      token
    }
  }
`;

const TUTOR_INSTITUTE_LOGIN = gql`
  mutation tutorAndInstitutionLogin($input: TutorAndInstituteLoginInput!) {
    tutorAndInstitutionLogin(input: $input) {
      kycSubmission
      message
      status
      token
      redirectUrl
      user {
        phoneVerification
        id
      }
    }
  }
`;

const GET_S3_URL = gql`
  mutation generateSignedUrl($input: GenerateSignedUrlInput!) {
    generateSignedUrl(input: $input) {
      status
      url
    }
  }
`;

const CREATE_TUTOR = gql`
  mutation createTutor($input: CreateTutorInput!) {
    createTutor(input: $input) {
      message
      status
      user {
        id
      }
    }
  }
`;

const CREATE_CLASS = gql`
  mutation CreateCourse($input: CreateCourseInput!) {
    createCourse(input: $input) {
      message
      status
    }
  }
`;

const UPDATE_CLASS = gql`
  mutation UpdateCourse($input: UpdateCourseInput!) {
    updateCourse(input: $input) {
      message
      status
    }
  }
`;

const CREATE_DRAFT_CLASS = gql`
  mutation CreateDraft($input: CreateDraftInput!) {
    createDraft(input: $input) {
      message
      status
    }
  }
`;

const DELETE_TUTOR = gql`
  mutation deleteTutor($input: DeleteTutorInput!) {
    deleteTutor(input: $input) {
      message
      status
    }
  }
`;

const CONTACT_US = gql`
  mutation contactUs($input: ContactUsInput!) {
    contactUs(input: $input) {
      status
      message
    }
  }
`;

const FORGOT_PASSWORD = gql`
  mutation ForgotPassword($input: ResetPasswordInput!) {
    resetPassword(input: $input) {
      message
      status
    }
  }
`;

const CHANGE_PASSWORD = gql`
  mutation ForgotPassword($input: ChangePasswordInput!) {
    changePassword(input: $input) {
      message
      status
    }
  }
`;

const REPORT_PARENT = gql`
  mutation ReportParent($input: CreateReportInput!) {
    createReport(input: $input) {
      message
      status
    }
  }
`;

const KYC_VERIFY = gql`
  mutation fetchKyc($input: FetchKycInput!) {
    fetchKyc(input: $input) {
      message
      status
    }
  }
`;

export {
  TUTOR_SIGNUP,
  OTP_LOGIN,
  SEND_OTP,
  UPDATE_TUTOR,
  GET_S3_URL,
  CREATE_CLASS,
  INSTITUTE_SIGNUP,
  TUTOR_INSTITUTE_LOGIN,
  UPDATE_INSTITUTE,
  CREATE_TUTOR,
  UPDATE_CLASS,
  CREATE_DRAFT_CLASS,
  DELETE_TUTOR,
  CONTACT_US,
  FORGOT_PASSWORD,
  CHANGE_PASSWORD,
  REPORT_PARENT,
  KYC_VERIFY,
};
