import React from "react";
import { Container } from "../style";
import MyprofileName from "./MyProfileName";

interface Props {
  institute: boolean;
}

const ProfileContainer: React.FC<Props> = ({ institute }) => {
  return (
    <div>
      {/* Profile Name Section */}
      {/* <CardWrapper>
        <Container
          width="320px"
          padding="30px"
          alignItems="center"
          layout="row"
        >
          <MyprofileName institute={institute ? true : false} />
        </Container>
      </CardWrapper> */}
      <Container width="320px" padding="30px" alignItems="center" layout="row">
        <MyprofileName institute={institute ? true : false} />
      </Container>
    </div>
  );
};

export default ProfileContainer;
