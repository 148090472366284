import React, { useEffect, useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import styled from "styled-components";
import theme from "../../assets/theme";
import { Hidden } from "@material-ui/core";

interface Polygon {
  smallGrayBg: boolean;
}

const HeaderWrapper = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  background: rgba(255, 255, 255, 1);
  z-index: 999;
  box-shadow: ${({ scrolledHeader }: Props) =>
    scrolledHeader ? "0 0 3px rgba(0,0,0,0.3)" : "0 0 3px rgba(0,0,0,0.3)"};
`;

const PageWrapper = styled.div<Props>`
  position: relative;
  top: 110px;
  display: flex;
  flex-flow: column;
  align-items: center;
  background: ${({ background }) => (background ? background : "#f0f0f0")};
  min-height: calc(100vh - 110px);
`;

const FooterWrapper = styled.div`
  width: 100%;
  background-color: #1e2d4f;
  padding: 30px 0px;
  position: relative;
  min-height: 290px;
`;

const ChildWrapper = styled.div`
  width: 100%;
  max-width: 1285px;
  position: relative;
  padding: 25px 0px;
`;

export const BackgroundGrayPolygon = styled.div`
  position: fixed;
  top: 110px;
  bottom: 0;
  left: 0;
  right: 0;
  background-image: linear-gradient(
    127deg,
    rgba(30, 45, 79, 0.09) 2%,
    rgba(255, 255, 255, 0) 85%
  );
  clip-path: ${({ smallGrayBg }: Polygon) =>
    smallGrayBg
      ? "polygon(0 44%, 0 100%, 26% 100%)"
      : "polygon(0 0, 19% 0, 100% 100%, 0% 100%)"};

  @media screen and ${theme.breakpoints.xs_down} {
    top: 90px;
  }
`;

interface Props {
  children?: any;
  scrolledHeader?: boolean;
  isFooterEnable?: boolean;
  background?: string;
  smallGrayBg?: boolean;
}

const Layout: React.FC<Props> = ({
  children,
  isFooterEnable,
  background,
  smallGrayBg = false,
}) => {
  const [pos, setpos] = useState<number>(0);

  useEffect(() => {
    window.addEventListener("scroll", updateScroll);
    return () => {
      window.removeEventListener("scroll", updateScroll);
    };
  }, []);

  const updateScroll = (e: any) => {
    setpos(e.currentTarget.scrollY);
  };

  return (
    <>
      {/* Header section */}
      <HeaderWrapper scrolledHeader={pos > 50}>
        <Header />
      </HeaderWrapper>
      <PageWrapper background={background}>
        {smallGrayBg && <BackgroundGrayPolygon smallGrayBg={smallGrayBg} />}
        {/* page content section */}
        <ChildWrapper>
          <Hidden mdDown>{isFooterEnable && <></>}</Hidden>
          {children}
        </ChildWrapper>

        {/* footer section */}
        {isFooterEnable && (
          <FooterWrapper>
            <Footer />
          </FooterWrapper>
        )}
      </PageWrapper>
    </>
  );
};

Layout.defaultProps = {
  isFooterEnable: true,
};

export default Layout;
