import React from "react";
import InstituteRegistrationForm from "../../components/InstituteRegistrationForm";
import { FORM_STATES } from "../../_helper/enums";
import Layout from "../../components/Layout/Layout";

const OtpPage = () => {
  return (
    <Layout isFooterEnable={false}>
      <InstituteRegistrationForm institute={true} userStep={FORM_STATES.OTP} />
    </Layout>
  );
};

export default OtpPage;
