import React, { useState, useEffect } from "react";
import { Images } from "../../assets/images";
import styled from "styled-components";
import { Drawer, makeStyles, Hidden, IconButton } from "@material-ui/core";
import { User } from "../../store";
import { Link, useLocation, navigate } from "@reach/router";
import jwt_decode from "jwt-decode";
import { useSnackbar } from "notistack";
import { SecondaryCTAButton } from "../common/MaterialFields";
import useAuth from "../../hooks/useAuth";
import theme from "../../assets/theme";
import COLORS from "../../assets/colors";

const HeaderStyle = styled.div`
  max-width: 1315px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  padding: 20px 10px;
  align-items: center;
  @media screen and ${theme.breakpoints.lg_down} {
    padding: 20px;
  }
  @media (max-width: 575px) {
    padding: 10px 24px;
  }
`;

const ImageWrapper = styled.div`
  display: flex;
  width: 100%;
  flex: 1;
  img {
    cursor: pointer;
  }
  @media (max-width: 575px) {
    justify-content: center;
    align-items: center;
  }
`;

const Container = styled.div<Props>`
  display: flex;
  align-items: center;
  flex-direction: ${({ layout }) => (layout ? layout : "row")};
`;

const LinkText = styled.div<Props>`
  margin: 0px 20px;
  font-family: ${(props) => props.theme.fonts.maxroundedmedium};
  padding: ${({ padding }) => (padding ? padding : "0px")};
  font-size: ${({ fontSize }) => (fontSize ? fontSize : "16px")};
  font-weight: 500;
  color: #2d2d2d;
  cursor: pointer;
  &:hover {
    color: #7632ff;
  }
  @media (max-width: 768px) {
    margin: 0px 0px;
  }
`;

interface Props {
  enqueueSnackbar?: any;
  layout?: string;
  padding?: string;
  fontSize?: string;
}

const useStyles = makeStyles({
  paperAnchorTop: {
    top: 90,
  },
});

const Header: React.FC<Props> = () => {
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
  const [mode, setMode] = useState<string>("");
  const [tokenVal, setTokenVal] = useState<string | null>(null);
  const [isAddClassEnabled, setIsAddClassEnabled] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();
  const { setToken } = useAuth();

  const classes = useStyles();

  useEffect(() => {
    if (
      location.pathname.includes("my-account") ||
      location.pathname.includes("my-classes") ||
      location.pathname.includes("review-rating") ||
      location.pathname.includes("institute/dashboard/tutor")
    ) {
      setIsAddClassEnabled(true);
    }

    const token = User.getToken();
    setTokenVal(token);
    if (token) {
      let tokenObj: any = jwt_decode(token ? token : "");
      setMode(tokenObj?.claims?.entity);
    }
  }, []);

  const handleLogout = () => {
    console.log(mode);
    User.clearToken().then(() => console.log("logout"));
    setDrawerOpen(false);
    enqueueSnackbar("Logged out successfully", {
      variant: "success",
      autoHideDuration: 3000,
      onExiting: () => {
        setToken("");
        navigate("/");
      },
    });
  };

  const redirectToHome = () => {
    if (location.pathname.includes("verify-otp")) {
      User.clearToken().then(() => console.log("logout"));
      setToken("");
    }
    navigate("/");
  };

  return (
    <HeaderStyle>
      <Hidden smUp>
        {drawerOpen ? (
          <IconButton
            aria-label="closeDrawer drawer"
            onClick={() => setDrawerOpen(false)}
            edge="start"
          >
            {/* <CloseIcon /> */}
            <img src={Images.MenuIcon} alt="menuIcon" />
          </IconButton>
        ) : (
          <IconButton
            aria-label="open drawer"
            onClick={() => setDrawerOpen(true)}
            edge="start"
          >
            <img src={Images.MenuIcon} alt="menuIcon" />
          </IconButton>
        )}
      </Hidden>
      <ImageWrapper>
        {/* <Link to="/"> */}
        <img src={Images.ColorLogo} onClick={redirectToHome} alt="logo" />
        {/* </Link> */}
      </ImageWrapper>

      <Drawer
        classes={{ paperAnchorTop: classes.paperAnchorTop }}
        style={{ zIndex: 100 }}
        anchor="top"
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        transitionDuration={{ enter: 700, exit: 700 }}
      >
        <Container layout="column">
          {!isAddClassEnabled && (
            <>
              <LinkText padding="20px">
                <a
                  style={{ textDecoration: "none" }}
                  href={`${process.env.REACT_APP_PARENT_URL}/about_us`}
                  target="_blank"
                >
                  <LinkText>About Us</LinkText>
                </a>
              </LinkText>
              <LinkText padding="20px">
                <a
                  style={{
                    textDecoration: "none",
                  }}
                  target="_blank"
                  href={`${process.env.REACT_APP_PARENT_URL}/contact_us`}
                >
                  <LinkText>Contact Us</LinkText>
                </a>
              </LinkText>
              {tokenVal !== null && (
                <Link
                  to={`/${
                    mode === "Tutor" ? "tutor" : "institute"
                  }/dashboard/my-classes`}
                >
                  <LinkText padding="20px">My Profile</LinkText>
                </Link>
              )}
            </>
          )}
          {tokenVal !== null && (
            <>
              {isAddClassEnabled && (
                <LinkText padding="20px">
                  <a href="mailto:connect@activkido.com">
                    <LinkText>Contact Support</LinkText>
                  </a>
                </LinkText>
              )}
              <LinkText padding="20px" onClick={() => handleLogout()}>
                <span style={{ color: COLORS.LOGOUT_COLOR }}>Logout</span>
              </LinkText>
            </>
          )}
        </Container>
      </Drawer>

      <Hidden xsDown>
        <Container layout="row">
          {/* <LinkText>About</LinkText> */}
          {!isAddClassEnabled && (
            <>
              <LinkText padding="20px">
                <a
                  style={{ textDecoration: "none" }}
                  href={`${process.env.REACT_APP_PARENT_URL}/about_us`}
                  target="_blank"
                >
                  <LinkText>About Us</LinkText>
                </a>
              </LinkText>
              <LinkText padding="20px">
                <a
                  style={{
                    textDecoration: "none",
                  }}
                  target="_blank"
                  href={`${process.env.REACT_APP_PARENT_URL}/contact_us`}
                >
                  <LinkText>Contact Us</LinkText>
                </a>
              </LinkText>
              {tokenVal !== null && (
                <Link
                  to={`/${
                    mode === "Tutor" ? "tutor" : "institute"
                  }/dashboard/my-classes`}
                >
                  <LinkText padding="20px">My Profile</LinkText>
                </Link>
              )}
            </>
          )}

          {tokenVal !== null && isAddClassEnabled && (
            <>
              <LinkText padding="20px" fontSize="18px">
                <a href="mailto:connect@activkido.com">
                  <LinkText>Contact Support</LinkText>
                </a>
              </LinkText>
              <LinkText>
                <Link
                  to={`/${
                    mode === "Tutor" ? "tutor" : "institute"
                  }/dashboard/create-activity`}
                  // as={`/${
                  //   mode === "Tutor" ? "tutor" : "institute"
                  // }/dashboard/create-activity`}
                >
                  <Hidden smDown>
                    <SecondaryCTAButton
                      style={{ width: "190px", padding: "11px 22px" }}
                    >
                      Add Class
                    </SecondaryCTAButton>
                  </Hidden>
                  <CustomHidden>
                    <LinkText
                      padding="20px"
                      fontSize="18px"
                      onClick={() => handleLogout()}
                    >
                      <span style={{ color: COLORS.LOGOUT_COLOR }}>Logout</span>
                    </LinkText>
                  </CustomHidden>
                </Link>
              </LinkText>
            </>
          )}
        </Container>
      </Hidden>
    </HeaderStyle>
  );
};

export default Header;

const CustomHidden = styled.div`
  display: none;
  @media screen and (min-width: 600px) and (max-width: 960px) {
    display: block;
  }
`;
