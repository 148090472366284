import React from "react";
import Layout from "../../components/Layout/Layout";
import AccountPendingReview from "../../components/AccountPendingReview";

interface Props {
  kycNotVerified?: boolean;
  institute?: boolean;
}

const AccountPendingReviewLayout = ({ kycNotVerified, institute }: Props) => {
  return (
    <Layout isFooterEnable={false}>
      <AccountPendingReview
        kycNotVerified={kycNotVerified}
        institute={institute}
      />
    </Layout>
  );
};

export default AccountPendingReviewLayout;
