import styled from "styled-components";
import COLORS from "../../../assets/colors";
import theme from "../../../assets/theme";

export const ActivityStatsContainer = styled.div`
  display: flex;
  width: 100%;
  border-radius: 22px;
  padding: 0 36px;
  background-color: ${COLORS.SECTION_BG_2};
  align-items: center;
  flex-flow: wrap;
  justify-content: space-around;
  margin-bottom: 50px;
  & > div {
    padding: 36px 20px;
  }
  @media screen and ${theme.breakpoints.mat_xs_down} {
    & > div {
      width: 185px;
    }
  }
`;

export const StatContainer = styled.div`
  display: flex;
  align-items: center;
  .details {
    margin-left: 21px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .label {
      font-family: ${(props) => props.theme.fonts.maxroundedbold};
      font-size: 19px;
    }
    .value {
      font-family: ${(props) => props.theme.fonts.montserratsemibold};
      font-size: 17px;
      color: ${COLORS.HIGHTLIGHT_DETAIL};
      opacity: 0.7;
      margin-top: 10px;
    }
  }
  @media screen and ${theme.breakpoints.mat_xs_down} {
    & > img {
      height: 24px;
      width: 24px;
    }
    .details {
      align-items: flex-start;
      .label,
      .value {
        font-size: 14px;
        align-items: flex-start;
      }
    }
  }
`;
