//@ts-nocheck
import React from "react";
import { Redirect } from "@reach/router";
import jwt_decode from "jwt-decode";
const PrivateRoutes = ({ token, ...props }) => {
  const data = token ? jwt_decode(token) : "";
  return (
    <>
      {token ? (
        <>
          {data?.claims?.entity != "Tutor" ? (
            <>
              {!data?.claims?.phone_verification ? (
                <Redirect to="/institute/verify-otp" noThrow />
              ) : (
                <>
                  {data?.claims?.status === "pending" ? (
                    <Redirect to="/institute/account-pending-review" noThrow />
                  ) : (
                    props.children
                  )}
                </>
              )}
            </>
          ) : (
            <>
              {data?.claims?.entity === "Tutor" && (
                <>
                  {data?.claims?.status === "pending" ? (
                    <Redirect to="/tutor/kyc-pending" noThrow />
                  ) : (
                    props.children
                  )}
                </>
              )}
            </>
          )}
        </>
      ) : (
        <Redirect to="/" noThrow />
      )}
    </>
  );
};
export default PrivateRoutes;
