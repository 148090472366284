import styled from "styled-components";
import theme from "../../../assets/theme";

export const Heading = styled.p`
  font-family: ${theme.fonts.maxroundedbold};
  font-size: 18px;
  line-height: 1.11;
  color: #1e2d4f;
`;
export const Para = styled.p`
  margin-top: 12px;
  opacity: 0.7;
  font-family: ${theme.fonts.montserratlight};
  font-size: 12px;
  line-height: 1.67;
  color: #202f51;
`;
