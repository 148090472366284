import React, { useEffect, useState } from "react";
import OnboardingFormContainer from "../../common/OnboardingFormContainer";
import {
  FieldContainer,
  TextCenterInput,
  SubmitBtnContainer,
  // ChangeNumberLink,
} from "../../common/OnboardingFormContainer/styles";
import { PrimaryCTAButton } from "../../common/MaterialFields";
import { Formik, Form, Field } from "formik";
import { useMutation } from "react-apollo";
import { OTP_LOGIN, SEND_OTP } from "../../../graphql/mutation";
import { useSnackbar } from "notistack";
import { otpSchema } from "../../../_helper/ValidationSchema";
import useAuth from "../../../hooks/useAuth";
import { User } from "../../../store";
import jwt_decode from "jwt-decode";
import { navigate } from "@reach/router";
import moment from "moment";

interface Props {
  institute?: boolean;
}

const initialValues = {
  code: "",
};

const TIMER_COUNT = 60;

const EnterOTP: React.FC<Props> = ({ institute }) => {
  const { userInfo, setUserInfo, setToken } = useAuth();
  const [phoneNumber, setPhoneNumber] = useState();
  const [otpLogin, { loading }] = useMutation(OTP_LOGIN);
  const { enqueueSnackbar } = useSnackbar();
  const [count, setCount] = useState(TIMER_COUNT);
  const [sendOtp] = useMutation(SEND_OTP);
  const [enableTimer, setEnableTimer] = useState(true);

  useEffect(() => {
    const token = User.getToken();
    if (token) {
      let tokenObj: any = jwt_decode(token ? token : "");
      setPhoneNumber(tokenObj?.claims?.phone_number);
    }
  }, []);

  // Effect for timer
  useEffect(() => {
    if (count === 0) {
      setEnableTimer(false);
    }
    const timer = setInterval(function () {
      setCount((prev) => prev - 1);
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  }, [count]);

  /**reset Oto function */
  const resendOtp = () => {
    const postObj = {
      phoneNumber: phoneNumber ? phoneNumber : userInfo?.institute?.phoneNumber,
      mode: "INSTITUTION",
    };
    setCount(TIMER_COUNT);
    setEnableTimer(true);

    sendOtp({
      variables: { input: postObj },
    })
      .then((res) => {
        if (res.data.sendOtp.status) {
          enqueueSnackbar(res.data.sendOtp.message, {
            variant: "success",
            autoHideDuration: 3000,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  /**
   * Function to submit the OTP and navigate to signup/home screen
   * @param values
   */
  const onFormSubmit = (values: any) => {
    const postObj = { ...values };
    postObj.mode = institute ? "INSTITUTION" : "TUTOR";
    postObj.phoneNumber = phoneNumber
      ? phoneNumber
      : institute
      ? userInfo?.institute?.phoneNumber
      : userInfo?.tutor?.phoneNumber;

    otpLogin({
      variables: { input: postObj },
    })
      .then((res) => {
        if (res.data.otpLogin.status) {
          enqueueSnackbar(res.data.otpLogin.message, {
            variant: "success",
            autoHideDuration: 3000,
          });
          User.setToken(res?.data?.otpLogin?.token);
          setToken(res?.data?.otpLogin?.token);
          if (institute) {
            navigate("/institute/dashboard/my-classes");
          } else {
            navigate("/tutor-registration-form/complete-kyc");
          }
          setUserInfo({});
        }
      })
      .catch((err) => {
        enqueueSnackbar(err.graphQLErrors[0].message, {
          variant: "error",
          autoHideDuration: 3000,
        });
      });
  };
  return (
    <OnboardingFormContainer
      title="Verify OTP"
      subtitle={
        <span>
          We’ve sent a One Time Password to your mobile number.
          <br />
          Please enter below for validation.
          {/* {institute ? (
            <Link href="/institute-registration-form">
              <ChangeNumberLink>Edit Number</ChangeNumberLink>
            </Link>
          ) : (
            <Link href="/tutor-registration-form/basic">
              <ChangeNumberLink>Edit Number</ChangeNumberLink>
            </Link>
          )} */}
        </span>
      }
    >
      <Formik
        onSubmit={onFormSubmit}
        initialValues={initialValues}
        isInitialValid={false}
        validationSchema={otpSchema}
        render={({
          handleSubmit,
          handleChange,
          touched,
          errors,
          isValid,
          resetForm,
        }) => (
          <Form onSubmit={handleSubmit}>
            <FieldContainer>
              <Field
                error={errors.code && touched.code}
                autoFocus={true}
                helperText={touched.code && errors.code}
                name="code"
                // inputProps={{ maxLength: 4 }}
                style={{ textAlign: "center" }}
                onChange={handleChange}
                id="outlined-basic"
                placeholder="One Time Password"
                variant="outlined"
                as={TextCenterInput}
              />
            </FieldContainer>
            <SubmitBtnContainer>
              <PrimaryCTAButton
                type="submit"
                disabled={loading || !isValid}
                style={{ minWidth: 360 }}
              >
                Continue
              </PrimaryCTAButton>
            </SubmitBtnContainer>
            <div className="reset-text center mt20">
              Didn’t Receive OTP.
              <span className="link">
                {!enableTimer ? (
                  <span
                    onClick={() => {
                      // setTimeout(() => {
                      resetForm();
                      resendOtp();
                      // }, 0);
                    }}
                  >
                    Resend
                  </span>
                ) : (
                  <span className="not-allowed">
                    Resend in {moment.utc(count * 1000).format("mm:ss")}
                  </span>
                )}
              </span>
            </div>
          </Form>
        )}
      />
    </OnboardingFormContainer>
  );
};

export default EnterOTP;
