import React from "react";
import OnboardingFormContainer from "../common/OnboardingFormContainer";
import {
  FieldContainer,
  StyledTextInput,
  SubmitBtnContainer,
} from "../common/OnboardingFormContainer/styles";
import { Formik, Form, Field } from "formik";
import { PrimaryCTAButton } from "../common/MaterialFields";
import { CHANGE_PASSWORD } from "../../graphql/mutation";
import { useMutation } from "react-apollo";
import { useSnackbar } from "notistack";
import { passwordSchema } from "../../_helper/ValidationSchema";
import { navigate, useLocation } from "@reach/router";

function ResetPassword({ mode }: { mode: string }) {
  const userMode = mode === "tutor" ? "TUTOR" : "INSTITUTION";
  const [callChangePasswordApi, { loading }] = useMutation(CHANGE_PASSWORD);
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const userToken = searchParams.get("token") || null;
  const TIMEOUT = 3000;

  async function onFormSubmit(values: any, actions: any) {
    console.log(values, actions, userMode);

    try {
      if (userToken) {
        const result = await callChangePasswordApi({
          variables: {
            input: { newPassword: values.password, token: userToken },
          },
        });

        if (result.data?.changePassword?.status) {
          actions.resetForm();
          enqueueSnackbar(result.data?.changePassword?.message, {
            variant: "success",
            autoHideDuration: TIMEOUT,
          });

          setTimeout(() => {
            navigate(`/${mode}/signin`);
          }, TIMEOUT);
        }

        console.log({ result });
      } else {
        enqueueSnackbar("Invalid Url", {
          variant: "error",
          autoHideDuration: 3000,
        });
      }
    } catch (error) {
      console.log("error", error);
      enqueueSnackbar(error?.graphQLErrors[0]?.message, {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  }

  return (
    <OnboardingFormContainer title="Reset Password">
      <Formik
        onSubmit={onFormSubmit}
        initialValues={{
          password: "",
        }}
        isInitialValid={false}
        validationSchema={passwordSchema}
      >
        {({ touched, errors, handleSubmit, isValid }) => (
          <Form onSubmit={handleSubmit}>
            <FieldContainer>
              <Field
                error={touched.password && errors.password ? true : false}
                helperText={touched.password && errors.password}
                name="password"
                label="Enter Password"
                variant="outlined"
                type="password"
                as={StyledTextInput}
              />
            </FieldContainer>
            <SubmitBtnContainer>
              <PrimaryCTAButton
                disabled={!isValid || loading}
                type="submit"
                style={{ minWidth: 360 }}
              >
                Reset
              </PrimaryCTAButton>
            </SubmitBtnContainer>
          </Form>
        )}
      </Formik>
    </OnboardingFormContainer>
  );
}

export default ResetPassword;
