import React, { useEffect, useState } from "react";
import { CardWrapper, ButtonStyle } from "../style";
import ProfileOverView from "./ProfileOverView";
import styled from "styled-components";
// import ServicesOffered from "./ServicesOffered";
import Certifications from "./Certifications";
import Awards from "./Awards";
import theme from "../../../assets/theme";
import { useLazyQuery } from "react-apollo";
import {
  GET_TUTOR_INFO,
  GET_INSTITUEINFO_INFO,
} from "../../../graphql/queries";
import { ColorCircularProgress } from "../../common/MaterialFields";

interface Props {
  institute?: boolean;
  setEdit: React.Dispatch<React.SetStateAction<boolean>>;
}

const Index: React.FC<Props> = ({ institute, setEdit }) => {
  const [getTutorInformation, tutorRes] = useLazyQuery(GET_TUTOR_INFO, {
    fetchPolicy: "no-cache",
  });
  const [getInsituteInformation, insRes] = useLazyQuery(GET_INSTITUEINFO_INFO, {
    fetchPolicy: "no-cache",
  });

  const [info, setInfo] = useState<any>(null);

  useEffect(() => {
    if (institute) {
      getInsituteInformation();
    } else {
      getTutorInformation();
    }
  }, []);

  useEffect(() => {
    if (institute && insRes?.data) {
      const { me: Institution } = insRes?.data;
      setInfo(Institution);
    } else if (tutorRes?.data) {
      const { me: Tutor } = tutorRes?.data;
      setInfo(Tutor);
    }
  }, [tutorRes.data, insRes.data]);

  if (info) {
    return (
      <>
        <StyledCardWrapper className="ml10 mr10 pl10 pr10 pt30 pb30">
          <Container>
            <Header>
              <MainHeading>
                {institute ? "Institute" : "Tutor"} Profile
              </MainHeading>
              <ButtonStyle
                onClick={() => setEdit(true)}
                //  disabled={updateTutorRes.loading || updateIns.loading}
                className="mt5 ml15"
              >
                Edit
              </ButtonStyle>
            </Header>
            <ProfileOverView institute={institute} data={info} />
          </Container>
        </StyledCardWrapper>
        {/* <StyledCardWrapper className="ml10 mr10 pl10 pr10 pt30 pb30">
          <Container>
            <ServicesOffered institute={institute} data={info} />
          </Container>
        </StyledCardWrapper> */}
        {info?.certification ? (
          <StyledCardWrapper className="ml10 mr10 pl10 pr10 pt30 pb30">
            <Container>
              <Certifications data={info} />
            </Container>
          </StyledCardWrapper>
        ) : null}
        {info?.awards ? (
          <StyledCardWrapper className="ml10 mr10 pl10 pr10 pt30 pb30">
            <Container>
              <Awards data={info} />
            </Container>
          </StyledCardWrapper>
        ) : null}
      </>
    );
  } else {
    return (
      <StyledCardWrapper
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        className="ml10 mr10 pl10 pr10 pt30 pb30"
      >
        <ColorCircularProgress size={56} thickness={3} />
      </StyledCardWrapper>
    );
  }
};

export default Index;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 44.4px;
  @media screen and ${theme.breakpoints.xs_down} {
    align-items: center;
    margin: 0;
    margin-bottom: 30px;
  }
`;

const MainHeading = styled.p`
  font-family: ${theme.fonts.montserratbold};
  font-size: 20px;
  line-height: 1;
  color: #1e2d4f;
  @media screen and ${theme.breakpoints.xs_down} {
    font-size: 16px;
  }
`;

const StyledCardWrapper = styled(CardWrapper)`
  margin-bottom: 30px;
  @media screen and ${theme.breakpoints.md_down} {
    box-shadow: none;
    margin-bottom: 0px;
  }
  @media screen and ${theme.breakpoints.xs_down} {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }
`;

const Container = styled.div`
  padding: 0 34px;
  @media (max-width: 625px) {
    padding: 0;
  }
`;
