import React from "react";
import DashBoardNavs from "./DashBoardNavs";
import DashBoardMain from "./DashBoardMain";
import { Hidden } from "@material-ui/core";
import DashBoardMainMob from "./DashBoardMainMob";
import DashboardMobNav from "./DashboardMobNav";
import { Link } from "@reach/router";
import { SecondaryCTAButton } from "../../common/MaterialFields";

interface Props {
  userStep: string;
  institute?: boolean;
  id?: string;
  viewStatus?: string;
}

const DashboardLayout: React.FC<Props> = ({
  userStep,
  institute,
  id,
  viewStatus,
}) => {
  return (
    <>
      <Hidden smDown>
        <div className="flex row ml20 mr20">
          {/* Left Section */}
          <div>
            {/* MenusContainer */}
            <DashBoardNavs
              institute={institute ? true : false}
              userStep={userStep}
            />
          </div>

          {/* Right section */}
          <div className="pl25 flex-1">
            <DashBoardMain
              viewStatus={viewStatus}
              institute={institute ? true : false}
              id={id}
              userStep={userStep}
            />
          </div>
        </div>
      </Hidden>

      <Hidden mdUp>
        <div className="flex center-center">
          <Link
            to={`/${
              institute ? "institute" : "tutor"
            }/dashboard/create-activity`}
            // as={`/${
            //   institute ? "institute" : "tutor"
            // }/dashboard/create-activity`}
          >
            <SecondaryCTAButton>Add Class</SecondaryCTAButton>
          </Link>
        </div>

        <div className="flex row center-center ml10 mr10">
          <DashboardMobNav
            institute={institute ? true : false}
            userStep={userStep}
          />
        </div>
        {/* Tab section */}
        <div>
          <DashBoardMainMob
            viewStatus={viewStatus}
            institute={institute ? true : false}
            userStep={userStep}
            id={id}
          />
        </div>
      </Hidden>
    </>
  );
};

export default DashboardLayout;
