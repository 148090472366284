import React, { useEffect, useState } from "react";
import OnboardingFormContainer from "../common/OnboardingFormContainer";
import { useSnackbar } from "notistack";
import jwt_decode from "jwt-decode";
import { Images } from "../../assets/images";
import useAuth from "../../hooks/useAuth";
import { navigate, useLocation } from "@reach/router";
interface Props {
  institute?: boolean;
  kycNotVerified?: boolean;
  id?: string;
  isZoom?: boolean;
}

const ZoomWarning: React.FC<Props> = () => {
  const [data, setData] = useState<any>();
  const { enqueueSnackbar } = useSnackbar();
  const { token } = useAuth();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  useEffect(() => {
    setData(searchParams.get("msg"));
    enqueueSnackbar(
      searchParams.get("msg") ? searchParams.get("msg") : "Something wrong",
      {
        variant: "error",
        autoHideDuration: 3000,
        onExiting: () => {
          redirectToPage();
        },
      }
    );
  }, []);

  const redirectToPage = () => {
    if (token) {
      let tokenObj: any = jwt_decode(token ? token : "");
      let mode = tokenObj?.claims?.entity;
      if (mode === "Tutor") {
        navigate("/tutor/dashboard/my-classes");
      } else {
        navigate(`/institute/dashboard/my-classes`);
      }
    }
  };

  return (
    <div style={{ height: "50vh" }} className="flex center-center">
      <OnboardingFormContainer title={data}>
        <img src={Images.stop} className="h200" />
        <div className="mt20">Redirecting...</div>
      </OnboardingFormContainer>
    </div>
  );
};

export default ZoomWarning;
