import React from "react";
import Avatar from "../Avatar";
import { StyledTutorInfo, TutorName } from "./styles";
import { PlaceBadge } from "../../DashBoard/MyClasses/style";
import COLORS from "../../../assets/colors";

interface Props {
  src: string;
  name: string;
  className?: string;
  fontSize?: string;
  isFromView?: boolean;
  place?: string;
}

const ActivityCardTutorInfo: React.FC<Props> = (props: Props) => {
  const { name, src, className } = props;
  return (
    <StyledTutorInfo className={className}>
      <Avatar src={src} width={"22px"} height={"22px"} />
      <TutorName fontSize={props.fontSize}>By {name}</TutorName>
      {props.isFromView && (
        <>
          <div>
            {props.place && (
              <PlaceBadge
                className="ml10"
                background={
                  props.place === "online"
                    ? "rgba(46, 46, 46, 0.1)"
                    : props.place === "home"
                    ? "rgba(1, 123, 255, 0.1)"
                    : "rgba(0, 173, 168, 0.16)"
                }
                colorValue={
                  props.place === "online"
                    ? COLORS.PRIMARY_BLACK
                    : props.place === "home"
                    ? COLORS.PRIMARY_PURPLE
                    : COLORS.INSTITUTE_TAG_TEXT
                }
              >
                {props.place === "online"
                  ? `${props.place} class`
                  : props.place === "home"
                  ? `${props.place} visit`
                  : props.place}
              </PlaceBadge>
            )}
          </div>
        </>
      )}
    </StyledTutorInfo>
  );
};

export default ActivityCardTutorInfo;
