import React from "react";
import styled from "styled-components";
import { ContentPageWrapper, Content } from "../../styles/component/style";
import Layout from "../Layout/Layout";

const index = () => {
  return (
    <Layout>
      <ContentPageWrapper>
        <StyledContent>
          <h1>SERVICE PROVIDER/INSTRUCTOR TERMS</h1>
          <p>
            When you sign up to become an instructor on the ActivKido platform,
            you agree to abide by these Service Provider/Instructor Terms
            (&quot;
            <strong>Terms</strong>&quot;). These Terms cover details about the
            aspects of the ActivKido platform relevant to instructors and are
            incorporated by reference into our{" "}
            <span>
              Terms of Use, the general terms that govern your use of our
              Services. Any capitalized te
            </span>
            rms that aren&#39;t defined in these Terms are defined as specified
            in the Terms of Use.
          </p>
          <p>As an instructor, you are contracting directly with ActivKido.</p>
          <h4>1. Instructor Obligations</h4>
          <p>
            As an instructor, you are responsible for all content that you post,
            including lectures, quizzes, coding exercises, practice tests,
            assignments, resources, answers, course landing page content, and
            announcements (&quot;<strong>Submitted Content</strong>&quot;).
          </p>
          <p>You represent and warrant that:</p>
          <ul>
            <li>you will provide and maintain accurate account information;</li>
            <li>
              you own or have the necessary licenses, rights, consents,
              permissions, and authority to authorize ActivKido to use your
              Submitted Content as specified in these Terms and the Terms of
              Use;
            </li>
            <li>
              your Submitted Content will not infringe or misappropriate any
              third party&#39;s intellectual property rights;
            </li>
            <li>
              you have the required qualifications, credentials, and expertise
              (including education, training, knowledge, and skill sets) to
              teach and offer the services that you offer through your Submitted
              Content and use of the Services; and
            </li>
            <li>
              you will respond promptly to students and ensure a quality of
              service that corresponds with the standards of your industry and
              instruction services in general.
            </li>
          </ul>
          <p>
            You warrant that you will <span>not</span>:
          </p>
          <ul>
            <li>
              post or provide any inappropriate, offensive, religious, casteist,
              racist, hateful, sexist, pornographic, false, misleading,
              incorrect, infringing, defamatory or libelous content or
              information;
            </li>
            <li>
              post or transmit any unsolicited or unauthorized advertising,
              promotional materials, junk mail, spam, or any other form of
              solicitation (commercial or otherwise) through the Services or to
              any user;
            </li>
            <li>
              use the Services for business other than providing tutoring,
              teaching, and instructional services to students;
            </li>
            <li>
              engage in any activity that would require us to obtain licenses
              from or pay royalties to any third party, including the need to
              pay royalties for the public performance of a musical work or
              sound recording;
            </li>
            <li>
              frame or embed the Services (such as to embed a free version of a
              course) or otherwise circumvent the Services;
            </li>
            <li>
              impersonate another person or gain unauthorized access to another
              person&#39;s account;
            </li>
            <li>
              interfere with or otherwise prevent other instructors from
              providing their services or courses; or
            </li>
            <li>abuse ActivKido resources, including support services.</li>
          </ul>
          <h4>2. License to ActivKido</h4>
          <p>
            You grant ActivKido the rights detailed in the{" "}
            <span>Terms of Use</span> to offer, market, and otherwise exploit
            your Submitted Content, and to sublicense it to students for these
            purposes directly or through third parties. This includes the right
            to add captions or otherwise modify content to ensure accessibility.
          </p>
          <p>
            Unless otherwise agreed, you have the right to remove all or any
            portion of your Submitted Content from the Services at any time.
            Except as otherwise agreed, ActivKido&#39;s right to sublicense the
            rights in this section will terminate with respect to new users 60
            days after the Submitted Content&#39;s removal. However, (1) rights
            given to students before the Submitted Content&#39;s removal will
            continue in accordance with the terms of those licenses (including
            any grants of lifetime access) and (2) ActivKido&#39;s right to use
            such Submitted Content for marketing purposes shall survive
            termination.
          </p>
          <p>
            We may record all or any part of your Submitted Content for quality
            control and for delivering, marketing, promoting, demonstrating, or
            operating the Services. You grant ActivKido permission to use your
            name, likeness, voice, and image in connection with offering,
            delivering, marketing, promoting, demonstrating, and selling the
            Services, your Submitted Content, or ActivKido&#39;s content, and
            you waive any rights of privacy, publicity, or other rights of a
            similar nature, to the extent permissible under applicable law.
          </p>
          <h4>3. Trust &amp; Safety</h4>
          <b>3.1 Trust &amp; Safety Policies</b>
          <br />
          <br />
          <p>
            You agree to abide by ActivKido&#39;s Policies with regard to{" "}
            <span>Trust, Safety and Restricted Topics,</span> and other course
            quality standards or policies prescribed by ActivKido from time to
            time. You should check these policies periodically to ensure that
            you comply with any updates to them. You understand that your use of
            the Services is subject to ActivKido&#39;s approval, which we may
            grant or deny at our sole discretion.
          </p>
          <p>
            We reserve the right to remove courses, suspend payouts, and/or ban
            instructors for any reason at any time, without prior notice,
            including in cases where:
          </p>
          <ul>
            <li>
              an instructor or course does not comply with our policies or legal
              terms (including the Terms of Use);
            </li>
            <li>
              a course falls below our quality standards or has a negative
              impact on the student experience;
            </li>
            <li>
              an instructor engages in behavior that might reflect unfavorably
              on ActivKido or bring ActivKido into public disrepute, contempt,
              scandal, or ridicule;
            </li>
            <li>
              an instructor engages the services of a marketer or other business
              partner who violates ActivKido&#39;s policies; or
            </li>
            <li>as determined by ActivKido in its sole discretion.</li>
          </ul>
          <b>3.2 Relationship to Other Users</b>
          <br />
          <br />
          <p>
            Instructors don&#39;t have a direct contractual relationship with
            students, so the only information you&#39;ll receive about students
            is what is provided to you through the Services. You agree that you
            will not use the data you receive for any purpose other than
            providing your services to those students on the ActivKido platform,
            and that you won&#39;t solicit additional personal data or store
            students&#39; personal data outside the ActivKido platform. You will
            indemnify ActivKido against any claims arising from your use of
            students&#39; personal data.Instructors should not connect student
            on ActivKido platform as direct students.
          </p>
          <h4>4. Pricing</h4>
          <b>4.1 Price Setting</b>
          <br />
          <br />
          <p>
            When creating a course, you will be prompted to select a base price
            (&quot;<strong>Base Price</strong>&quot;) for your course from a
            list of available price tiers. Alternatively, you may choose to
            offer your course for free. As a premium instructor, you will also
            be given the opportunity to participate in certain promotional
            programs under the terms of our Promotions Policy (&quot;
            <strong>Promotional Programs</strong>&quot;).
          </p>
          <p>
            You give us permission to share your courses for free with our
            employees, with selected partners, and in cases where we need to
            restore access accounts who have previously purchased your courses.
            You understand that you will not receive compensation in these
            cases.
          </p>
          <br />
          <b>4.2 Transaction Taxes</b>
          <br />
          <br />
          <p>
            If a student purchases a product or service that requires ActivKido
            to remit GST or other similar transaction taxes (&quot;
            <strong>Transaction Taxes</strong>&quot;), under applicable law, we
            will collect and remit those Transaction Taxes to the competent tax
            authorities for those sales. We may increase the sale price at our
            discretion where we determine that such taxes may be due. For
            purchases through mobile applications, applicable Transaction Taxes
            are collected by the mobile platform (such as Apple&#39;s App Store
            or Google Play).
          </p>
          <br />
          <b>4.3 Promotional Programs</b>
          <br />
          <br />
          <p>
            ActivKido offers several optional marketing programs (Promotional
            Programs) in which you can choose to participate, as detailed in our{" "}
            <span>Promotions Policy</span>. These programs can help increase
            your revenue potential on ActivKido by finding the optimal price
            point for your courses and promoting them through additional
            marketing channels.
          </p>
          <p>
            There is no up-front cost to participate in these programs, and you
            can modify your participation status at any time, though changes you
            make will not apply to currently active campaigns.
          </p>
          <h4>5. Payments</h4>
          <b>5.1 Revenue Share</b>
          <br />
          <br />
          <p>
            When a student purchases your course, we calculate the gross amount
            of the sale as the amount actually received by ActivKido from the
            student (&quot;<strong>Gross Amount</strong>&quot;). From this, we
            subtract 15% as our share towards Transaction Taxes, any mobile
            platform fees applied to mobile application sales, administrative
            and handling fee for any non-mobile-app sales, and any amounts paid
            to third parties in connection with the Promotional Programs to
            calculate the net amount of the sale (&quot;
            <strong>Net Amount</strong>&quot;).
          </p>
          <p>
            If you opt into any of the Promotional Programs, you will get an
            amount which will be agreed between the parties as and when it
            required.<span>&nbsp;</span>
          </p>
          <br />
          <b>5.2 Receiving Payments</b>
          <br />
          <br />
          <p>
            For us to pay you in a timely manner, you must have an active bank
            account in good standing and must keep us informed of the correct
            email and phone number associated with your account. You must also
            provide any identifying information or tax documentation (such as
            GST Registration &amp; PAN Details) necessary for payment of amounts
            due, and you agree that we have the right to withhold appropriate
            taxes from your payments. We reserve the right to withhold payments
            or impose other penalties if we do not receive proper identifying
            information or tax documentation from you. You understand and agree
            there will be TDS deduction applicable. That you are ultimately
            responsible for any taxes on your income.
          </p>
          <p>
            Payment will be made on weekly basis based on the number of sessions
            handled. Payment cycle subject to change.
          </p>
          <p>
            We reserve the right not to pay out funds in the event of identified
            fraud, violations of intellectual property rights, or other
            violations of the law on your part.
          </p>
          <b>5.3 Refunds</b>
          <p>
            You acknowledge and agree that students have the right to receive a
            refund, as detailed in the&nbsp;Terms of Use, Refund &amp;
            Cancellation Policy. Instructors will not receive any revenue from
            transactions for which a refund has been granted under the Terms of
            Use. If you cancel the sessions for any reason on your part, you
            have to reschedule the classes which were cancelled by you.
          </p>
          <p>
            If a student asks for a refund after we have paid the relevant
            instructor payment, we reserve the right to either (1) deduct the
            amount of the refund from the next payment sent to the instructor or
            (2) where no further payments are due to the instructor or the
            payments are insufficient to cover the refunded amounts, require the
            instructor to refund any amounts refunded to students for the
            instructor's courses.
          </p>
          <h4>6. Trademarks</h4>
          <p>
            While you are a published instructor and subject to the requirements
            below, you may use our trademarks where we authorize you to do so.
          </p>
          <b>You must:</b>
          <ul>
            <li>
              only use the images of our trademarks that we make available to
              you, as detailed in any guidelines we may publish;
            </li>
            <li>
              only use our trademarks in connection with the promotion and sale
              of your ActivKido courses or your participation on ActivKido; and
            </li>
            <li>immediately comply if we request that you discontinue use.</li>
          </ul>
          <b>You must not:</b>
          <ul>
            <li>use our trademarks in a misleading or disparaging way;</li>
            <li>
              use our trademarks in a way that implies that we endorse, sponsor,
              or approve of your courses or services; or
            </li>
            <li>
              use our trademarks in a way that violates applicable law or in
              connection with an obscene, indecent, or unlawful topic or
              material.
            </li>
            <li>
              use our trademark for activities outside Activkido platform.
            </li>
          </ul>
          <p>
            <h4>7. Term of Agreement</h4>
          </p>
          <ul>
            <li>
              The term of this Agreement (the &ldquo;Term&rdquo;) will begin on
              the date of this Agreement and will remain in full force and
              effect indefinitely until terminated as provided in this
              Agreement.
            </li>
            <li>
              In the event that either Party wishes to terminate this Agreement,
              that Party will be required to provide 60 days notice to the other
              Party.
            </li>
            <li>
              Except as otherwise provided in this Agreement, the obligations of
              the Service Provider will end upon the termination of this
              Agreement.
            </li>
            <li>
              You can delete your account as per the Terms agreed and
              termination policy. We will use commercially reasonable efforts to
              make any remaining scheduled payments that are owed to you before
              deleting your account. You understand that if students have
              previously enrolled in your courses, your name and that Submitted
              Content will remain accessible to those students after your
              account is deleted. If you need help or encounter difficulty
              deleting your account, you can contact us via our&nbsp;Support
              Team.
            </li>
          </ul>
          <h4>8. Updating These Terms</h4>
          <p>
            Being a Start-up Company, we may update these Terms from time to
            time, to clarify our practices or to reflect new or different
            practices (such as when we add new features), and ActivKido reserves
            the right in its sole discretion to modify and/or make changes to
            these Terms at any time. If we make any material change, we will
            notify you using prominent means such as by email notice sent to the
            email address specified in your account or by posting a notice
            through our Services. Modifications will become effective on the day
            they are posted unless stated otherwise.
          </p>
          <p>
            Your continued use of our Services after changes become effective
            shall mean that you accept those changes. Any revised Terms shall
            supersede all previous Terms.
          </p>
          <h4>9. Governing Law Dispute Resolution</h4>
          <p>
            This Site is arranged, sponsored, or managed by ActivKido in the
            state of Tamil Nadu, INDIA. The laws of the state of Tamil Nadu
            govern these Terms, without giving effect to any principles of
            conflicts of laws that would result in the application of the laws
            of a different jurisdiction.
          </p>
          <p>
            If a dispute arises between you and ActivKido, our goal is to
            provide you with a neutral and cost effective means of resolving the
            dispute quickly. Accordingly, you and ActivKido agree that any claim
            or controversy at law or equity that arises out of these Terms or
            our services (a &ldquo;Claim&rdquo;) will be resolved in accordance
            with one of the subsection below or as otherwise agreed to the in
            writing. Before resorting to these alternatives, we strongly
            encourage you to first contact us directly to seek a resolution
            through our Customer Support.
          </p>
          <ul>
            <li>
              <strong>Law and Forum for Disputes</strong> &ndash; For any claim
              or dispute against ActivKido where the total amount of the award
              sought is more than Rs 1, 00,000/- you agree that it must be
              resolved by a court located in Chennai, Tamil Nadu. You agree to
              irrevocably and unconditionally submit to the personal
              jurisdiction of the courts located within Chennai, Tamil Nadu for
              the purpose of litigating all such claims or disputes. To the
              extent you have in any manner violated or threatened to violate
              ActivKido&rsquo;s intellectual property rights, you agree that we
              may seek injunctive or other appropriate relief in the courts of
              Chennai, and you consent to exclusive jurisdiction and venue in
              such courts.
            </li>
            <li>
              <strong>Arbitration Option</strong> &ndash; For any claim
              (excluding claims for injunctive or other equitable relief) where
              the total amount of the award is Rs 1, 00,000/- or less, you agree
              to submit such claim to confidential arbitration in Chennai, Tamil
              Nadu. There shall be one arbitrator that shall be appointed by
              ActivKido.The arbitration shall be governed by the Arbitration and
              Conciliation Act, 1996 and the venue of the Arbitration shall be
              Chennai. The terms and conditions of this terms shall be governed
              and construed in accordance with the laws of India and subject to
              the provisions as to resort to arbitration, in the event of any
              dispute arising out of this terms, the Courts at Chennai shall
              have exclusive jurisdiction. The arbitration decision or award
              shall be in writing and shall be binding and may be entered as a
              judgment in any court of competent jurisdiction. To the fullest
              extent permitted by applicable law, no arbitration under these
              Terms shall be joined to an arbitration involving any other party
              subject to these Terms whether through class arbitration
              proceedings or otherwise.
            </li>
          </ul>
          <h4>10. General Provisions</h4>
          <ul>
            <li>
              <b>No Assignment</b>. These Terms are personal to you. You may not
              transfer, assign or delegate them to anyone at any time. Any
              attempt by you to assign, transfer or delegate these Terms will be
              null and void. We shall have the right to transfer, assign and/or
              delegate these Terms without your permission.
            </li>
            <li>
              <b>Entire Assignment</b>. These Terms, including the Additional
              Terms and Conditions for the Service Provider/Instructors,
              Cancellation Policy and Privacy Policy which are incorporated
              herein by reference, constitute the entire agreement of the
              parties with respect to the subject matter hereof and supersede
              and cancel all prior and contemporaneous agreements, claims,
              representations, and understandings of the parties in connection
              with the subject matter hereof.
            </li>
            <li>
              <b>No Agency; Third-Party Beneficiary</b>. We are not your agent,
              fiduciary, trustee, or other representative. Nothing expressed or
              mentioned in or implied from these Terms in intended or shall be
              construed to give to any other person any legal or equitable
              right, remedy, or claim under or in respect to these Terms. These
              Terms are intended to be and are for the sole and exclusive
              benefit of ActivKido and you.
            </li>
            <li>
              <b>Severability</b>. If any provision of these Terms shall be
              deemed unlawful, void, or for any reason unenforceable, then that
              provision shall be deemed severable from these Terms and shall not
              affect the validity and enforceability of any remaining
              provisions.
            </li>
            <li>
              <b>No Waiver</b>. We will not be considered to have waived any of
              our rights or remedies described in these Terms unless the waiver
              is in writing and signed by us. No delay or omission by us in
              exercising our rights or remedies will impair or be construed as a
              waiver. Any single or partial exercise of a right or remedy by us
              will not preclude further of any other right or remedy. Our
              failure to enforce the strict performance of any provision of
              these Terms will not constitute a waiver of ActivKido&rsquo;s
              right to subsequently enforce such provision or any other
              provisions of these Terms.
            </li>
          </ul>
          <h4>11. Survival</h4>
          <p>
            The following sections shall survive the expiration or termination
            of these Terms: Sections <b>2 (License to ActivKido)</b>,{" "}
            <b>3.3 (Relationship to Other Users)</b>,{" "}
            <b>5.2 (Receiving Payments)</b>, <b>5.3 (Refunds)</b>,{" "}
            <b>7 (Term of Agreement)</b>,{" "}
            <b>9 (Governing Law; Dispute Resolution)</b>
            and <b>10 (General Provisions)</b>.
          </p>
        </StyledContent>
      </ContentPageWrapper>
    </Layout>
  );
};

export default index;

const StyledContent = styled(Content)`
  h4,
  p {
    text-align: left;
  }
`;
