import React, { useState, useEffect } from "react";
import { CardWrapper, Container, NavFonts, List } from "../style";
import COLORS from "../../../assets/colors";
import { Link, navigate } from "@reach/router";
import {
  navLinksTutor,
  navLinksInstitute,
} from "../../../_helper/dashboardLinks";
import { DASHBOARD_NAVS } from "../../../_helper/enums";
import { useSnackbar } from "notistack";
import { User } from "../../../store";
import useAuth from "../../../hooks/useAuth";

interface Props {
  userStep: string;
  institute?: boolean;
}

const DashBoardNavs: React.FC<Props> = ({ userStep, institute }) => {
  const { setToken } = useAuth();
  const [navLinks, setNavLinks] = useState<any>([]);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (institute) {
      setNavLinks(navLinksInstitute);
    } else {
      setNavLinks(navLinksTutor);
    }
  }, []);

  const handleLogout = () => {
    User.clearToken().then(() => {
      console.log("logout");
    });
    enqueueSnackbar("Logged out successfully", {
      variant: "success",
      autoHideDuration: 3000,
      onExiting: () => {
        setToken("");
        navigate("/");
      },
    });
  };

  function isSupport(linkKey: string) {
    return linkKey === "SUPPORT";
  }

  function isLogout(linkKey: string) {
    return linkKey === "LOGOUT";
  }

  return (
    <div>
      <CardWrapper>
        <Container
          width="320px"
          padding="30px"
          alignItems="flex-start"
          layout="column"
        >
          {navLinks?.map((link: any, index: number) => (
            <List key={index}>
              <img
                className="w15"
                src={
                  userStep === link.key ||
                  (userStep === DASHBOARD_NAVS.TUTORREVIEW &&
                    link.key === DASHBOARD_NAVS.TUTOR)
                    ? link.imgBlack
                    : link.imgSrc
                }
                alt={link.key}
              />
              <div className="flex pl25">
                {isSupport(link.key) && (
                  <NavFonts fontSize="18px">
                    <a
                      href={link.href}
                      style={{
                        color: COLORS.BLACK_OPACITY,
                        textDecoration: "none",
                      }}
                    >
                      {link.name}
                    </a>
                  </NavFonts>
                )}
                {isLogout(link.key) && (
                  <NavFonts
                    fontSize="18px"
                    colorValue={COLORS.LOGOUT_COLOR}
                    onClick={handleLogout}
                  >
                    {link.name}
                  </NavFonts>
                )}

                {!isSupport(link.key) && !isLogout(link.key) && (
                  <Link to={link.href}>
                    <NavFonts
                      fontSize="18px"
                      isBold={
                        userStep === link.key ||
                        (userStep === DASHBOARD_NAVS.TUTORREVIEW &&
                          link.key === DASHBOARD_NAVS.TUTOR)
                          ? true
                          : false
                      }
                      colorValue={
                        userStep === link.key ||
                        (userStep === DASHBOARD_NAVS.TUTORREVIEW &&
                          link.key === DASHBOARD_NAVS.TUTOR)
                          ? COLORS.PRIMARY_BLACK
                          : COLORS.BLACK_OPACITY
                      }
                    >
                      {link.name}
                    </NavFonts>
                  </Link>
                )}
              </div>
            </List>
          ))}
        </Container>
      </CardWrapper>
    </div>
  );
};

export default DashBoardNavs;
