import React from "react";
import styled from "styled-components";
import { IconButton } from "@material-ui/core";
import { Images } from "../../assets/images";
import useDialog from "../../hooks/useDialog";

interface Props {
  showHeader?: boolean;
  showLogo?: boolean;
  heading?: string;
  triggerClose?: any;
}

const FlexCenter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
`;
const CrossPosition = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const Heading = styled(FlexCenter)`
  font-family: ${props => props.theme.fonts.maxroundedmedium};
  font-size: 30px;
  font-weight: 500;
  text-align: center;
  color: #1e2d4f;
  flex: 1;
`;

const LogoContainer = styled.img.attrs({
  src: `${Images.ColorLogo}`,
  alt: "activikido logo"
})`
  display: flex;
  justify-content: center;
  flex: 1;
`;

const DialogHeader: React.FC<Props> = ({
  showHeader,
  heading,
  showLogo,
  triggerClose
}) => {
  const { setDialogState } = useDialog();

  const triggerDialogClose = () => {
    setDialogState("");
    triggerClose();
  };

  return (
    <HeaderContainer>
      {showHeader && (
        <Heading>
          <span>{heading}</span>
        </Heading>
      )}
      {showLogo && <LogoContainer />}
      <CrossPosition>
        <IconButton
          color="inherit"
          aria-label="close Icon"
          edge="start"
          onClick={triggerDialogClose}
        >
          <img src={Images.closeIcon} alt="closeIcon" />
        </IconButton>
      </CrossPosition>
    </HeaderContainer>
  );
};

export default DialogHeader;
