//@ts-nocheck
import React from "react";
import Home from "./components/Home";
import InstructorTermsAndConditions from "../src/components/InstructorTermsAndConditions";
import { Router } from "@reach/router";
import Tutor from "./pages/tutor";
import TutorSignin from "./pages/tutor/TutorSignin";
import TutorForgotPassword from "./pages/tutor/TutorForgotPassword";
import TutorResetPassword from "./pages/tutor/TutorResetPassword";
import TutorRegistration from "./pages/tutor/TutorRegistration";

import Institute from "./pages/institute";
import InstituteSignin from "./pages/institute/InstituteSignin";
import InstituteForgotPassword from "./pages/institute/InstituteForgotPassword";
import InstituteResetPassword from "./pages/institute/InstituteResetPassword";
import InstituteRegistration from "./pages/institute/InstituteRegistration";

import PrivateRoutes from "./components/PrivateRoutes/PrivateRoutes";

//Tutor Dashboard
import TutorDashboard from "./pages/tutor/dashboard";
import MyClassesPage from "./pages/tutor/dashboard/MyClassesPage";
import TutorMyAccount from "./pages/tutor/dashboard/MyAccountPage";
import ReviewRatingsPage from "./pages/tutor/dashboard/ReviewRatingsPage";
import CreateActivityPage from "./pages/tutor/dashboard/CreateActivityPage";
import ViewActivityPage from "./pages/tutor/dashboard/ViewActivityPage";

//Institute Dashboard
import InstituteDashboard from "./pages/institute/dashboard";
import InstituteMyAccount from "./pages/institute/dashboard/MyAccountPage";
import AllTutorsPage from "./pages/institute/dashboard/AllTutorsPage";
import AccountPendingReview from "./pages/tutor/account-pending-review";
import KycVerifying from "./pages/tutor/kyc-verifying";
import { ApolloProvider } from "react-apollo";
import ApolloClient from "apollo-boost";
import useAuth from "./hooks/useAuth";
import EditDraftActivityPage from "./pages/tutor/dashboard/EditDraftActivityPage";
import OtpPage from "./pages/institute/verify-otp";
import ZoomWarningPage from "./pages/tutor/zoom-warning";

function AppRouter() {
  const { token } = useAuth();

  const client = new ApolloClient({
    uri: process.env.REACT_APP_END_POINT_URL,
    headers: {
      authorization: token ? token : "",
    },
  });

  return (
    <ApolloProvider client={client}>
      <Router>
        <Home token={token} path="/" />
        <InstructorTermsAndConditions path="/instructor_terms_and_conditions" />
        <Tutor path="/tutor">
          <TutorSignin path="signin">
            <TutorForgotPassword path="forgot-password" />
            <TutorResetPassword path="reset-password" />
          </TutorSignin>

          <AccountPendingReview kycNotVerified={true} path="kyc-pending" />
          <KycVerifying path="kyc-verifying" />
          <KycVerifying path="kyc-verifying/:id" />
          <ZoomWarningPage path="zoom-warning" />

          <PrivateRoutes token={token} path="dashboard">
            <TutorDashboard path="/">
              <MyClassesPage path="my-classes" />
              <TutorMyAccount path="my-account" />
              <ReviewRatingsPage path="review-rating" />
              <CreateActivityPage path="create-activity" />
              <CreateActivityPage path="my-classes/:id/edit" />
              <EditDraftActivityPage path="draft/:id" />
              <ViewActivityPage path="my-classes/:id/:viewStatus" />
            </TutorDashboard>
          </PrivateRoutes>
        </Tutor>

        <Institute path="institute">
          <InstituteSignin path="signin">
            <InstituteForgotPassword path="forgot-password" />
            <InstituteResetPassword path="reset-password" />
          </InstituteSignin>
          <OtpPage path="verify-otp" />
          <AccountPendingReview institute path="account-pending-review" />
          <PrivateRoutes token={token} path="dashboard">
            <InstituteDashboard path="/">
              <MyClassesPage path="my-classes" institute />
              <InstituteMyAccount path="my-account" />
              <ReviewRatingsPage path="review-rating" institute />
              <ReviewRatingsPage path="tutor/:name" institute />
              <CreateActivityPage path="create-activity" />
              <CreateActivityPage path="my-classes/:id/edit" institute />
              <EditDraftActivityPage path="draft/:id" institute />
              <ViewActivityPage path="my-classes/:id/:viewStatus" institute />
              <AllTutorsPage path="tutor" />
            </InstituteDashboard>
          </PrivateRoutes>
        </Institute>

        {/* Registration */}
        <InstituteRegistration path="institute-registration-form" />
        <TutorRegistration path="tutor-registration-form" />
      </Router>
    </ApolloProvider>
  );
}

export default AppRouter;
