import * as Yup from "yup";

const numberExp = /^[0-9]\d*$/;
const PanExp = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;
const nospecial = /\/|\\/; // Restrict only slash and backslash

const phoneNumberMsgObj = {
  message: "Phone number is not valid",
  excludeEmptyString: true,
};

const PanMsgObj = {
  message: "PAN is not valid",
  excludeEmptyString: true,
};

const otpNumberMsgObj = {
  message: "Otp is not valid",
  excludeEmptyString: true,
};

const reportIssueSchema = Yup.object({
  issue: Yup.string().trim().required("Issue is Required"),
});

const emailVerificationSchema = Yup.object({
  email: Yup.string()
    .trim()
    .email("Email must be valid")
    .required("Email Required"),
});

const passwordSchema = Yup.object({
  password: Yup.string().trim().required("Password is Required"),
});

const phoneNumberSchema = Yup.object({
  phoneNumber: Yup.string()
    .trim()
    .min(10, "Phone number must contain atleast 10 characters")
    .required("Phone number Required")
    .matches(numberExp, phoneNumberMsgObj),
});

const tutorSignUpSchema = Yup.object({
  tutor: Yup.object({
    firstName: Yup.string().trim().required("Name Required"),
    phoneNumber: Yup.string()
      .trim()
      .min(10, "Phone number must contain atleast 10 characters")
      .required("Phone number Required")
      .matches(numberExp, phoneNumberMsgObj),
    email: Yup.string().trim().email().required("Email Required"),
    gender: Yup.string().required("Gender Required"),
    password: Yup.string()
      .trim()
      .min(6, "password must contain atleast 6 characters")
      .required("password Required"),
    address: Yup.string().trim().required("Address Required"),
    panNumber: Yup.string().required("PAN Required").matches(PanExp, PanMsgObj),
    pp_size_photo: Yup.mixed().required("Please upload the required file"),
  }),
  allowedCategories: Yup.string().trim().required("Category Required"),
});

const upadateTutorSchema = Yup.object({
  firstName: Yup.string().trim().required("Name Required"),
  phoneNumber: Yup.string()
    .trim()
    .min(10, "Phone number must contain atleast 10 characters")
    .required("Phone number Required")
    .matches(numberExp, phoneNumberMsgObj),
  email: Yup.string().trim().email().required("Email Required"),
  address: Yup.string().trim().required("Address Required"),
  // location: Yup.string()
  //   .trim()
  //   .required("Address Required"),
  alternatePhoneNumber: Yup.string()
    .trim()
    .min(10, "Phone number must contain atleast 10 characters"),
  pan: Yup.string().required("PAN Required").matches(PanExp, PanMsgObj),
  pp_size_photo: Yup.mixed().required("Please upload the required file"),
});

const updateProfileSchemaInMyaccount = Yup.object({
  alternatePhoneNumber: Yup.string()
    .trim()
    .min(10, "Phone number must contain atleast 10 characters")
    .matches(numberExp, phoneNumberMsgObj),
  allowedCategories: Yup.string().trim().required("Category Required"),
  overview: Yup.string(),
  certification: Yup.string(),
  awards: Yup.string(),
  education: Yup.string(),
  totalExperience: Yup.string(),
});

const createTutorSchema = Yup.object({
  firstName: Yup.string().trim().required("Name Required"),
  phoneNumber: Yup.string()
    .trim()
    .min(10, "Phone number must contain atleast 10 characters")
    .required("Phone number Required")
    .matches(numberExp, phoneNumberMsgObj),
  email: Yup.string().trim().email().required("Email Required"),
  address: Yup.string().trim().required("Address Required"),
  gender: Yup.string().required("Gender Required"),
  // location: Yup.string()
  //   .trim()
  //   .required("Address Required"),
  alternatePhoneNumber: Yup.string()
    .trim()
    .min(10, "Phone number must contain atleast 10 characters"),
  panNumber: Yup.string().required("PAN Required").matches(PanExp, PanMsgObj),
  pp_size_photo: Yup.mixed().required("Please upload the required file"),
  allowedCategories: Yup.string().trim().required("Category Required"),
  overview: Yup.string(),
  education: Yup.string(),
  certification: Yup.string(),
  totalExperience: Yup.string(),
  awards: Yup.string(),
});

const createActivity = Yup.object({
  title: Yup.string()
    .trim()
    .required("Title Required")
    .test("title-invalid", "Title can't have '/' or '\\'", function (value) {
      return !nospecial.test(value);
    }),
  activity_image: Yup.string(),
  category: Yup.number().required("Category Required"),
  price: Yup.number().min(1).required("Price Required"),
  description: Yup.string().trim().required("Description Required"),
  numOfWeeks: Yup.string().trim().required("Number of weeks Required"),
  numOfDaysPerWeek: Yup.string().trim().required("Days per week Required"),
  selectedDays: Yup.array().of(Yup.boolean()),
  startDate: Yup.string().trim().required("Start date Required"),
  duration: Yup.number().required("Duration Required"),
  startTime: Yup.date().nullable().required("Start Time required"),
  batchSize: Yup.number()
    .min(1)
    .required("Batch size Required")
    .when("place", {
      is: "online",
      then: Yup.number().max(7),
      otherwise: Yup.number().max(25),
    }),
  ageRange: Yup.string().required("Age range Required"),
  place: Yup.string().required("Place Required"),
  tutor: Yup.number(),
});

const otpSchema = Yup.object({
  code: Yup.string()
    .trim()
    .min(4, "Invalid OTP")
    .matches(numberExp, otpNumberMsgObj)
    .required("Otp Required"),
});

const instituteSignUpSchema = Yup.object({
  institute: Yup.object({
    firstName: Yup.string().trim().required("Name Required"),
    phoneNumber: Yup.string()
      .trim()
      .min(10, "Phone number must contain atleast 10 characters")
      .required("Phone number Required")
      .matches(numberExp, phoneNumberMsgObj),
    email: Yup.string().trim().email().required("Email Required"),
    panNumber: Yup.string().required("PAN Required").matches(PanExp, PanMsgObj),
    password: Yup.string()
      .trim()
      .min(6, "password must contain atleast 6 characters")
      .required("password Required"),
    address: Yup.string().trim().required("Address Required"),
    registrationDocument: Yup.mixed().required(
      "Please upload the required file"
    ),
    gstDocument: Yup.mixed(),
  }),
  allowedCategories: Yup.string().trim().required("Category Required"),
});

const loginSchema = Yup.object({
  email: Yup.string().trim().email().required("Email Required"),
  password: Yup.string().trim().required("password Required"),
});

const contactUsSchema = Yup.object({
  fullName: Yup.string().trim().required("Name required"),
  email: Yup.string().trim().email().required("Email Required"),
  subject: Yup.string().trim().required("Subject required"),
  message: Yup.string()
    .trim()
    .max(300, "Message cannot exceed characters")
    .required("Message required"),
});

export {
  phoneNumberSchema,
  otpSchema,
  tutorSignUpSchema,
  upadateTutorSchema,
  createTutorSchema,
  createActivity,
  instituteSignUpSchema,
  loginSchema,
  updateProfileSchemaInMyaccount,
  contactUsSchema,
  emailVerificationSchema,
  passwordSchema,
  reportIssueSchema,
};
