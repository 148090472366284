import React, { useState, useEffect } from 'react';
import en from '../i18n/en.json';
import ta from '../i18n/ta.json';
import { User } from '../store';

interface Props {
  children: any;
}

interface Iprops {
  t: (key: string) => any;
  changeLanguage: (key: string) => void;
}

export const LanguageContext = React.createContext({} as Iprops);

export const LangProvider: React.FC<Props> = ({ children }) => {
  const [language, setLanguage] = useState<string | null>('');
  const [languageResource, setLanguageResource] = useState({} as any);

  useEffect(() => {
    User.getLang().then(val => changeLanguage(val));
  }, []);

  const t = (key: string) => {
    return languageResource[key];
  };

  const changeLanguage = (key: string | null) => {
    switch (key) {
      case 'ta': {
        setLanguage(language);
        setLanguageResource(ta);
        break;
      }
      default: {
        setLanguage('en');
        setLanguageResource(en);
      }
    }
  };

  return (
    <LanguageContext.Provider value={{ t, changeLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};
