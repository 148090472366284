import React from "react";
import { InputLabel, SelectProps, Select } from "@material-ui/core";
import { StyledSelectFormControl } from "./styles";
import { StyledMenuItem, CustomCheckBox } from "../../../common/MaterialFields";
interface OtherProps {
  menuItemValues: any;
  selectedItems?: any;
  helperText?: any;
}

const CustomSelectField = ({
  label,
  name,
  value = [],
  onChange,
  disabled,
  error,
  helperText,
  menuItemValues,
  multiple = false,
  selectedItems = [],
}: SelectProps & OtherProps) => {
  function renderValue(selectValue: any) {
    if (multiple) {
      const selectedKeys = menuItemValues
        .filter((menuItemValue: any) =>
          selectValue?.includes(menuItemValue?.value)
        )
        .map((menuItemValues: any) => menuItemValues?.label);
      return selectedKeys?.join(", ");
    } else {
      const selectedKey = menuItemValues.find(
        (menuItemValue: any) => menuItemValue.value === selectValue
      );
      return selectedKey?.label;
    }
  }
  return (
    <StyledSelectFormControl variant="outlined">
      <InputLabel>{label}</InputLabel>
      <Select
        {...{
          multiple,
          name,
          value,
          onChange,
          renderValue,
          disabled,
          error,
        }}
      >
        {menuItemValues.map((item: any, idx: number) => (
          <StyledMenuItem key={idx} value={item.value}>
            {multiple && (
              <CustomCheckBox checked={selectedItems.includes(item.value)} />
            )}
            {item.label}
          </StyledMenuItem>
        ))}
      </Select>
      <p className="errorlabel">{helperText}</p>
    </StyledSelectFormControl>
  );
};

export default CustomSelectField;
