import styled from "styled-components";
import theme from "../../assets/theme";
import COLORS from "../../assets/colors";

export const ReviewSingleContainer = styled.div`
  width: 100%;
  margin-bottom: 36px;
  @media screen and ${theme.breakpoints.mat_md_down} {
    max-width: 100%;
  }

  @media screen and ${theme.breakpoints.mat_xs_down} {
    .head {
      .header-detail {
        display: flex;
        flex-direction: column;
      }
    }
  }

  .head {
    display: flex;
    flex-flow: wrap;
    align-items: center;
    .header-detail {
      display: flex;
      @media screen and ${theme.breakpoints.sm_down} {
        .MuiRating-root {
          font-size: 12px;
        }
      }
    }
    .name {
      margin-left: 12px;
      font-family: ${props => props.theme.fonts.maxroundedmedium};
      font-weight: 500;
      font-size: 16px;
    }
    .filler {
      margin: 0 11px 0 10px;
      font-size: 12px;
      opacity: 0.47;
      color: ${COLORS.SUBTITLE};
      margin-top: 3px;
    }
  }
`;

export const StyledReviewText = styled.div`
  font-family: ${props => props.theme.fonts.montserrat};
  font-weight: 300;
  line-height: 1.56;
  color: ${COLORS.HIGHTLIGHT_DETAIL};
  font-size: 16px;
  margin-top: 19px;
  @media screen and ${theme.breakpoints.sm_down} {
    font-size: 12px;
  }
`;
