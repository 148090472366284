import React, { useState, useEffect } from "react";
import { Heading } from "../style";
import {
  ActivitiesSortFormControl,
  SortActivitiesSelect,
  StyledMenuItem,
  ColorCircularProgress,
} from "../../common/MaterialFields";
import { Hidden } from "@material-ui/core";
import ActivityCard from "./ActivityCard";
import { useLazyQuery } from "react-apollo";
import {
  GET_COURSE_LIST,
  GET_COURSE_DRAFR_LIST,
  GET_COURSE_UNDER_REVIEW_LIST,
} from "../../../graphql/queries";
import NoMatchingActivities from "../../common/NoResultFound";
import InfiniteScroll from "react-infinite-scroller";

interface Props {}

const MyClasses: React.FC<Props> = () => {
  const [sortVal, setSortVal] = useState("active");
  const [courseList, setCourseList] = useState<any>([]);
  const [pageInfo, setPageInfo] = useState<any>();
  const [courseLoading, setCourseLoading] = useState<boolean>(false);
  const [getAll, allData] = useLazyQuery(GET_COURSE_LIST, {
    variables: {
      first: 20,
    },
    fetchPolicy: "no-cache",
  });

  const [getDraft, draftData] = useLazyQuery(GET_COURSE_DRAFR_LIST, {
    variables: {
      first: 20,
    },
    fetchPolicy: "no-cache",
  });

  const [getUnderReview, underReviewData] = useLazyQuery(
    GET_COURSE_UNDER_REVIEW_LIST,
    {
      variables: {
        first: 20,
      },
      fetchPolicy: "no-cache",
    }
  );

  useEffect(() => {
    if (sortVal === "active" && allData?.data?.getCourses?.nodes) {
      setCourseList(allData?.data?.getCourses?.nodes);
      setPageInfo(allData?.data?.getCourses?.pageInfo);
      setCourseLoading(false);
    }

    if (sortVal === "draft" && draftData?.data?.drafts?.nodes) {
      setCourseList(draftData?.data?.drafts?.nodes);
      setPageInfo(draftData?.data?.drafts?.pageInfo);
      setCourseLoading(false);
    }

    if (
      sortVal === "under_review" &&
      underReviewData?.data?.coursesWaitingForApproval?.nodes
    ) {
      setCourseList(underReviewData?.data?.coursesWaitingForApproval?.nodes);
      setPageInfo(underReviewData?.data?.coursesWaitingForApproval?.pageInfo);
      setCourseLoading(false);
    }
  }, [allData.data, draftData.data, underReviewData.data]);

  useEffect(() => {
    const loadCourseList = () => {
      setCourseLoading(true);
      switch (sortVal) {
        case "active": {
          getAll();
          break;
        }
        case "draft": {
          getDraft();
          break;
        }
        case "under_review": {
          getUnderReview();
          break;
        }
        default: {
          break;
        }
      }
    };
    loadCourseList();
  }, [sortVal]);

  const handleSortChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSortVal(event.target.value as string);
  };

  const loadMore = () => {
    switch (sortVal) {
      case "active": {
        loadMoreData(allData, "getCourses");
        break;
      }
      case "draft": {
        loadMoreData(draftData, "drafts");
        break;
      }
      case "under_review": {
        loadMoreData(underReviewData, "coursesWaitingForApproval");
        break;
      }
      default: {
        break;
      }
    }
  };

  const loadMoreData = (data: any, type: any) => {
    data?.fetchMore({
      variables: {
        first: 20,
        after: pageInfo?.endCursor,
      },
      updateQuery: (_: any, { fetchMoreResult }: any) => {
        if (fetchMoreResult && fetchMoreResult[type]?.nodes) {
          setCourseList([...courseList, ...fetchMoreResult[type].nodes]);
          setPageInfo(fetchMoreResult[type].pageInfo);
        }
      },
    });
  };

  return (
    <>
      <div className="flex row wrap center-center">
        <Heading className="flex-1" fontSize="20px" padding="30px 0px">
          My Classes
        </Heading>
        <ActivitiesSortFormControl>
          <SortActivitiesSelect value={sortVal} onChange={handleSortChange}>
            <StyledMenuItem value={"active"}>
              Active <Hidden smDown> Classes</Hidden>
            </StyledMenuItem>
            <StyledMenuItem value={"draft"}>
              Drafts <Hidden smDown>Classes</Hidden>
            </StyledMenuItem>
            <StyledMenuItem value={"under_review"}>
              Under review <Hidden smDown>Classes</Hidden>
            </StyledMenuItem>
          </SortActivitiesSelect>
        </ActivitiesSortFormControl>
      </div>
      <div>
        {courseLoading ? (
          <div style={{ minHeight: "50vh" }} className="flex center-center">
            <ColorCircularProgress size={50} thickness={5} />
          </div>
        ) : (
          <InfiniteScroll
            loadMore={loadMore}
            hasMore={pageInfo?.hasNextPage}
            loader={
              <div key={"loader"} className="flex center-center">
                <ColorCircularProgress size={30} thickness={5} />
              </div>
            }
          >
            {courseList?.length > 0 ? (
              <div>
                {courseList?.map((course: any, idx: number) => (
                  <div key={idx}>
                    {sortVal != "active" ? (
                      <ActivityCard
                        key={idx}
                        course={course?.params}
                        DraftTutor={course?.tutor}
                        id={course.id}
                        sortVal={sortVal}
                      />
                    ) : (
                      <ActivityCard
                        key={idx}
                        course={course}
                        sortVal={sortVal}
                      />
                    )}
                  </div>
                ))}
              </div>
            ) : (
              <NoMatchingActivities
                message={
                  "We have no results to show based on the filters you’ve selected. Try changing the filters to view activities"
                }
              />
            )}
          </InfiniteScroll>
        )}
      </div>
    </>
  );
};

export default MyClasses;
