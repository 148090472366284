import React, { useState, useEffect } from "react";
import OnboardingFormContainer from "../../common/OnboardingFormContainer";
import {
  FieldContainer,
  StyledTextInput,
  SubmitBtnContainer,
  TermsNote,
  RadioLegend,
} from "../../common/OnboardingFormContainer/styles";
import { PrimaryCTAButton, CCTVRadioGroup } from "../../common/MaterialFields";
import { Formik, Form, Field } from "formik";
import { tutorSignUpSchema } from "../../../_helper/ValidationSchema";
import { useMutation, useQuery } from "react-apollo";
import { TUTOR_SIGNUP } from "../../../graphql/mutation";
import { GET_CATEGORIES } from "../../../graphql/queries";
import { useSnackbar } from "notistack";
import useAuth from "../../../hooks/useAuth";
import CustomSelectField from "../../CreateActivity/Fields/CustomSelectField";
import _ from "lodash";
import FileUploadInput from "../../common/FileUploadInput";
import { User } from "../../../store";

interface Props {}

const BasicDetails: React.FC<Props> = () => {
  const CategoriesResponse = useQuery(GET_CATEGORIES);
  const [tutorSignUp, { loading }] = useMutation(TUTOR_SIGNUP);
  const { userInfo, setUserInfo, setToken } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const [ppFile, setPpfile] = useState<string>("");
  const [lodingFile, setLoadingFile] = useState<boolean>(false);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    if (!_.isEmpty(CategoriesResponse.data)) {
      if (CategoriesResponse.data?.categories?.nodes) {
        setCategories(CategoriesResponse.data.categories.nodes);
      }
    }
  }, [CategoriesResponse.data]);

  const onFormSubmit = (values: any) => {
    let postObj = JSON.parse(JSON.stringify(values));
    delete postObj.tutor.pp_size_photo;
    postObj.tutor.avatarUrl = ppFile;
    postObj.tutor.cctvAvailability =
      values?.tutor?.cctvAvailability === "yes" ? true : false;
    tutorSignUp({
      variables: {
        input: postObj,
      },
    })
      .then((res) => {
        if (res?.data?.tutorSignUp?.status) {
          enqueueSnackbar(res.data.tutorSignUp.message, {
            variant: "success",
            autoHideDuration: 3000,
          });
          User.setToken(res.data.tutorSignUp.token);
          setToken(res.data.tutorSignUp.token);
          window.open(
            `${process.env.REACT_APP_KYC_VERIFICATION_URL}/verify-aadhar?jwt=${res?.data?.tutorSignUp?.token}&redirect_url=${process.env.REACT_APP_AADHAR_REDIRECT_URL}`,
            "_self"
          );
          setUserInfo(values);
        }
      })
      .catch((err) => {
        console.log(err);
        enqueueSnackbar(err.graphQLErrors[0].message, {
          variant: "error",
          autoHideDuration: 3000,
        });
      });
  };

  return (
    <OnboardingFormContainer
      title="Registration Form"
      subtitle="Please fill out the following details"
    >
      <Formik
        onSubmit={onFormSubmit}
        initialValues={{
          tutor: {
            firstName: userInfo?.tutor?.firstName
              ? userInfo?.tutor?.firstName
              : "",
            phoneNumber: userInfo?.tutor?.phoneNumber
              ? userInfo?.tutor?.phoneNumber
              : "",
            email: userInfo?.tutor?.email ? userInfo?.tutor?.email : "",
            password: userInfo?.tutor?.password
              ? userInfo?.tutor?.password
              : "",
            panNumber: userInfo?.tutor?.panNumber
              ? userInfo?.tutor?.panNumber
              : "",
            address: userInfo?.tutor?.address ? userInfo?.tutor?.address : "",
            gender: userInfo?.tutor?.gender ? userInfo?.tutor?.gender : "",
            pp_size_photo: "",
            cctvAvailability: "no",
          },
          allowedCategories: userInfo?.allowedCategories && [],
        }}
        isInitialValid={false}
        validationSchema={tutorSignUpSchema}
        render={({
          handleSubmit,
          touched,
          errors,
          values,
          setFieldValue,
          handleChange,
        }) => (
          <Form onSubmit={handleSubmit}>
            <FieldContainer>
              <Field
                error={
                  touched.tutor?.firstName && errors.tutor?.firstName
                    ? true
                    : false
                }
                autoFocus={true}
                helperText={touched.tutor?.firstName && errors.tutor?.firstName}
                name="tutor.firstName"
                id="outlined-basic"
                label="Name"
                variant="outlined"
                as={StyledTextInput}
              />
            </FieldContainer>
            <FieldContainer>
              <Field
                name="tutor.gender"
                label="Gender"
                error={
                  touched.tutor?.gender && errors.tutor?.gender ? true : false
                }
                helperText={touched.tutor?.gender && errors.tutor?.gender}
                menuItemValues={[
                  { label: "Male", value: "male" },
                  { label: "Female", value: "female" },
                ]}
                as={CustomSelectField}
              />
            </FieldContainer>
            <FieldContainer>
              <Field
                error={
                  touched.tutor?.phoneNumber && errors.tutor?.phoneNumber
                    ? true
                    : false
                }
                helperText={
                  touched.tutor?.phoneNumber && errors.tutor?.phoneNumber
                }
                name="tutor.phoneNumber"
                inputProps={{ maxLength: 10 }}
                id="outlined-basic"
                label="Phone Number"
                variant="outlined"
                as={StyledTextInput}
              />
            </FieldContainer>
            <FieldContainer>
              <Field
                error={
                  touched.allowedCategories && errors.allowedCategories
                    ? true
                    : false
                }
                helperText={
                  touched.allowedCategories && errors.allowedCategories
                }
                name="allowedCategories"
                id="outlined-basic"
                label="Categories"
                variant="outlined"
                as={CustomSelectField}
                value={values.allowedCategories}
                multiple
                menuItemValues={categories.map((category: any) => ({
                  label: category.name,
                  value: category.id,
                }))}
                selectedItems={values.allowedCategories}
              />
            </FieldContainer>
            <FieldContainer>
              <Field
                error={
                  touched.tutor?.email && errors.tutor?.email ? true : false
                }
                helperText={touched.tutor?.email && errors.tutor?.email}
                name="tutor.email"
                id="outlined-basic"
                label="Email"
                variant="outlined"
                as={StyledTextInput}
              />
            </FieldContainer>
            <FieldContainer>
              <Field
                error={
                  touched.tutor?.password && errors.tutor?.password
                    ? true
                    : false
                }
                helperText={touched.tutor?.password && errors.tutor?.password}
                name="tutor.password"
                type="password"
                id="outlined-basic"
                label="Set Password"
                variant="outlined"
                as={StyledTextInput}
              />
            </FieldContainer>
            <FieldContainer>
              <Field
                error={
                  touched.tutor?.panNumber && errors.tutor?.panNumber
                    ? true
                    : false
                }
                helperText={touched.tutor?.panNumber && errors.tutor?.panNumber}
                name="tutor.panNumber"
                id="outlined-basic"
                label="Permanent Account Number"
                variant="outlined"
                as={StyledTextInput}
              />
            </FieldContainer>
            <FieldContainer>
              <Field
                error={
                  touched.tutor?.address && errors.tutor?.address ? true : false
                }
                helperText={touched.tutor?.address && errors.tutor?.address}
                name="tutor.address"
                id="outlined-basic"
                label="Address"
                variant="outlined"
                as={StyledTextInput}
              />
            </FieldContainer>
            <FieldContainer>
              <Field
                error={
                  touched.tutor?.pp_size_photo && errors.tutor?.pp_size_photo
                    ? true
                    : false
                }
                helperText={
                  touched.tutor?.pp_size_photo && errors.tutor?.pp_size_photo
                    ? errors.tutor?.pp_size_photo
                    : ""
                }
                name="tutor.pp_size_photo"
                onChange={(url: string) => setPpfile(url)}
                acceptType="image/*"
                label="Clear Passport Size Photo"
                setFileLoading={(iLoading: boolean) => setLoadingFile(iLoading)}
                setFieldValue={setFieldValue}
                as={FileUploadInput}
              />
            </FieldContainer>
            <FieldContainer>
              <RadioLegend>
                Do you have CCTV camera inside the premises?
              </RadioLegend>
              <Field
                error={
                  touched.tutor?.cctvAvailability &&
                  errors.tutor?.cctvAvailability
                }
                name="tutor.cctvAvailability"
                onChange={handleChange}
                as={CCTVRadioGroup}
              />
            </FieldContainer>
            <TermsNote>
              <span>
                By clicking on "Complete KYC", you agree to all of our
              </span>
              <br />
              <span>
                <a
                  style={{
                    textDecoration: "none",
                  }}
                  target="_blank"
                  href={"/instructor_terms_and_conditions"}
                >
                  Terms & Conditions
                </a>
              </span>
            </TermsNote>
            <SubmitBtnContainer>
              <PrimaryCTAButton
                type="submit"
                disabled={loading || lodingFile}
                style={{ minWidth: 360 }}
              >
                Complete KYC
              </PrimaryCTAButton>
            </SubmitBtnContainer>
          </Form>
        )}
      />
    </OnboardingFormContainer>
  );
};

export default BasicDetails;
