import React from "react";
import { CardWrapper } from "../DashBoard/style";
import ReviewsHeadSection from "../ReviewsHeadSection";

interface Props {
  data?: any;
}

const index: React.FC<Props> = ({ data }) => {
  return (
    <CardWrapper className="flex column pt10 pb10 pl25 pr30 maxwidth370 pointer">
      <ReviewsHeadSection
        name={data?.firstName}
        isPending={data?.status === "pending" ? true : false}
        numOfReviews={data?.ratingCount}
        rating={data?.ratingAverage}
        specialization={data?.allowedCategories?.nodes}
        avatar={data.avatarUrl}
        id={data.id}
      />
    </CardWrapper>
  );
};

export default index;
