import React from "react";
import OnboardingFormContainer from "../common/OnboardingFormContainer";
import { Font } from "../DashBoard/style";
import COLORS from "../../assets/colors";
import { User } from "../../store";
import { useSnackbar } from "notistack";
import { useQuery } from "react-apollo";
import {
  ColorCircularProgress,
  PrimaryCTAButton,
  NegativeCTAButton,
} from "../common/MaterialFields";
import { navigate } from "@reach/router";
import { GET_INSTITUEINFO_INFO, GET_TUTOR_INFO } from "../../graphql/queries";
import useAuth from "../../hooks/useAuth";

interface Props {
  institute?: boolean;
  kycNotVerified?: boolean;
}
const AccountPendingReview: React.FC<Props> = ({
  institute,
  kycNotVerified,
}) => {
  const { token, setToken } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const { data, loading } = useQuery(
    institute ? GET_INSTITUEINFO_INFO : GET_TUTOR_INFO,
    {
      fetchPolicy: "no-cache",
    }
  );

  const redirectToKyc = () => {
    if (token) {
      window.open(
        `${process.env.REACT_APP_KYC_VERIFICATION_URL}/verify-aadhar?jwt=${token}&redirect_url=${process.env.REACT_APP_AADHAR_REDIRECT_URL}`,
        "_self"
      );
    }
  };

  const handleLogout = () => {
    User.clearToken().then(() => {
      console.log("logged out");
    });
    enqueueSnackbar("Logged out successfully", {
      variant: "success",
      autoHideDuration: 3000,
      onExiting: () => {
        setToken("");
        navigate("/");
      },
    });
  };
  return (
    <OnboardingFormContainer
      title={`${kycNotVerified ? "" : "Account under verification"}`}
      subtitle={
        <>
          {loading ? (
            <ColorCircularProgress size={50} thickness={5} />
          ) : (
            <>
              <div>
                <br />
                Dear {data?.me?.firstName} ,
                <br />
                <br />
                {kycNotVerified
                  ? `Thank You for showing interest in Activkido,Your KYC details are not verified yet. To Complete the KYC click on 'Complete kyc'`
                  : `Thank You for showing interest in Activkido,Your given details
                are under verification will get back to you shortly once it is
                done.`}
              </div>
              {kycNotVerified && token && (
                <div className="flex center-center">
                  <PrimaryCTAButton
                    className="mt20"
                    onClick={() => redirectToKyc()}
                    type="submit"
                  >
                    Complete KYC
                  </PrimaryCTAButton>

                  <NegativeCTAButton
                    className="mt20 ml20"
                    onClick={() => handleLogout()}
                    type="submit"
                  >
                    logout
                  </NegativeCTAButton>
                </div>
              )}
              {!kycNotVerified && (
                <div className="back-to-home">
                  <Font
                    onClick={() => handleLogout()}
                    className="mt20 pointer"
                    fontSize="20px"
                    colorValue={COLORS.PRIMARY_PURPLE}
                    isBold={true}
                  >
                    Back To Home
                  </Font>
                </div>
              )}
            </>
          )}
        </>
      }
    ></OnboardingFormContainer>
  );
};

export default AccountPendingReview;
