import React from "react";
import styled from "styled-components";
import { Heading } from "./styles";
import { Para } from "./styles";
import theme from "../../../assets/theme";

interface Props {
  data?: any;
}

const Awards: React.FC<Props> = ({ data }) => {
  return (
    <Container>
      <Heading>Awards and Medal any</Heading>
      <Para style={{ fontFamily: theme.fonts.montserrat }}>{data.awards}</Para>
    </Container>
  );
};

export default Awards;

const Container = styled.div``;
