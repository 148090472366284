import React from "react";
import Layout from "../../../components/Layout/Layout";
import DashboardLayout from "../../../components/DashBoard/Layout/DashboardLayout";
import { DASHBOARD_NAVS } from "../../../_helper/enums";

function TutorMyAccount() {
  return (
    <Layout isFooterEnable={false} background="#f9f9f9">
      <DashboardLayout userStep={DASHBOARD_NAVS.MYACCOUNT} />
    </Layout>
  );
}

export default TutorMyAccount;
