import styled from "styled-components";
import COLORS from "../../assets/colors";
import theme from "../../assets/theme";

export const ReviewsHeadSectionContainer = styled.div`
  display: flex;
  align-items: center;
  .details {
    margin-left: 33px;
  }
`;

export const BoldTutorName = styled.div`
  font-size: 20px;
  font-family: ${props => props.theme.fonts.montserratbold};
  color: ${COLORS.SUBTITLE};
  margin: 14px 0;
  @media screen and ${theme.breakpoints.sm_down} {
    font-size: 14px;
  }
`;
