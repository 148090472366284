const storeKeys = {
  userToken: "vendor_token",
  lang: "user_lang",
  userName: "username",
  uuid: "uuid",
};

const User = {
  async setToken(token: string) {
    localStorage.setItem(storeKeys.userToken, token);
  },

  async setName(name: string) {
    localStorage.setItem(storeKeys.userName, name);
  },

  async setLang(lang: string) {
    localStorage.setItem(storeKeys.lang, lang);
  },
  getToken() {
    const token = localStorage.getItem(storeKeys.userToken);
    return token ? token : null;
  },
  async getLang() {
    const lang = localStorage.getItem(storeKeys.lang);
    return lang ? lang : null;
  },
  async getName() {
    const name = localStorage.getItem(storeKeys.userName);
    return name ? name : null;
  },
  async setUUID(uuid: string) {
    localStorage.setItem(storeKeys.uuid, uuid);
  },

  async getUUID() {
    const uuid = localStorage.getItem(storeKeys.uuid);
    return uuid ? uuid : null;
  },

  async removeStore(key: string) {
    localStorage.removeItem(key);
  },

  async clearToken() {
    localStorage.clear();
  },
};

export { User };
