import React, { useState } from "react";
import Myaccount from "../Myaccount";
import MyClasses from "../MyClasses";
import ReviewRatings from "../ReviewRatings.tsx";
import { CardWrapper } from "../style";
import TutorList from "../TutorList";
import { DASHBOARD_NAVS } from "../../../_helper/enums";
import ClassDetails from "../ClassDetails";
import ViewMyAccount from "../ViewMyAccount";
import styled from "styled-components";
import theme from "../../../assets/theme";

interface Props {
  userStep: string;
  institute?: boolean;
  id?: string;
  viewStatus?: string;
}

const DashBoardMainMob: React.FC<Props> = ({
  userStep,
  institute,
  id,
  viewStatus,
}) => {
  const [edit, setEdit] = useState(false);
  return (
    <div>
      <StyledCardWrapper className="ml10 mr10 pl10 pr10 pt30 pb30">
        {userStep === DASHBOARD_NAVS.MYACCOUNT && (
          <>
            {edit ? (
              <Myaccount
                setIsEdit={(bool) => setEdit(bool)}
                institute={institute ? true : false}
                isEdit={edit}
              />
            ) : (
              <ViewMyAccount
                institute={institute ? true : false}
                setEdit={(bool) => setEdit(bool)}
              />
            )}
          </>
        )}
        {userStep === DASHBOARD_NAVS.MYCLASSES && <MyClasses />}
        {(userStep === DASHBOARD_NAVS.REVIEWRATINGS ||
          userStep === DASHBOARD_NAVS.TUTORREVIEW) && (
          <ReviewRatings id={id} institute={institute ? true : false} />
        )}
        {userStep === DASHBOARD_NAVS.TUTOR && <TutorList />}
        {userStep === DASHBOARD_NAVS.CLASSDETAILS && (
          <CardWrapper className="flex column">
            <ClassDetails
              institute={institute ? true : false}
              id={id}
              viewStatus={viewStatus}
            />
          </CardWrapper>
        )}
      </StyledCardWrapper>
    </div>
  );
};

export default DashBoardMainMob;

const StyledCardWrapper = styled(CardWrapper)`
  @media screen and ${theme.breakpoints.xs_down} {
    padding-top: 0;
  }
`;
