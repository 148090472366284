import React from "react";
import { ReviewsHeadSectionContainer, BoldTutorName } from "./styles";
import Avatar from "../common/Avatar";
import { Images } from "../../assets/images";
import ReviewsForTutor from "../ReviewsForTutor";
import { Font } from "../DashBoard/style";
import COLORS from "../../assets/colors";

interface Props {
  name: string;
  numOfReviews: number;
  rating: number;
  isPending?: boolean;
  specialization?: any;
  avatar?: string;
  id?: string;
}

const ReviewsHeadSection: React.FC<Props> = (props: Props) => {
  const setSpecialization = (data: any) => {
    return data?.map((value: any) => value?.category?.name).join(", ");
  };
  return (
    <ReviewsHeadSectionContainer>
      <Avatar
        src={props.avatar ? props.avatar : Images.userPlaceHolder}
        width="97px"
        height="97px"
      />
      <div className="details">
        <BoldTutorName>{props.name}</BoldTutorName>
        <ReviewsForTutor
          isPending={props.isPending}
          numOfReviews={props.numOfReviews}
          rating={props.rating}
          hideExtraDetails={true}
          id={props.id}
        />
        <Font
          className="mt14 mb14"
          fontSize="12px"
          isBold={true}
          colorValue={COLORS.PRIMARY_BLACK}
        >
          Specialization: {setSpecialization(props.specialization)}
        </Font>
      </div>
    </ReviewsHeadSectionContainer>
  );
};

export default ReviewsHeadSection;
