import React from "react";
import { ReviewSingleContainer, StyledReviewText } from "./styles";
import Rating from "@material-ui/lab/Rating";
import Avatar from "../common/Avatar";
import { Images } from "../../assets/images";

interface Props {
  avatar?: string;
  name: string;
  rating?: number;
  review?: string;
}

const ReviewSingle: React.FC<Props> = (props: Props) => {
  return (
    <ReviewSingleContainer>
      <div className="head">
        <Avatar
          src={props?.avatar ?? Images.ParentProfile}
          width="47px"
          height="47px"
        />
        <div className="header-detail">
          <div className="name">{props.name}</div>
          <div className="flex row center-center">
            <div className="filler">rated</div>
            <Rating
              name="read-only"
              value={props.rating}
              readOnly
              precision={0.5}
              size="large"
            />
          </div>
        </div>
      </div>
      <div className="body">
        <StyledReviewText>{props.review}</StyledReviewText>
      </div>
    </ReviewSingleContainer>
  );
};

export default ReviewSingle;
