import React from "react";
import OnboardingFormContainer from "../common/OnboardingFormContainer";
import {
  FieldContainer,
  StyledTextInput,
  SubmitBtnContainer,
} from "../common/OnboardingFormContainer/styles";
import { Formik, Form, Field } from "formik";
import { PrimaryCTAButton } from "../common/MaterialFields";
import { emailVerificationSchema } from "../../_helper/ValidationSchema";
import { FORGOT_PASSWORD } from "../../graphql/mutation";
import { useMutation } from "react-apollo";
import { useSnackbar } from "notistack";

function ForgotPassword({ mode }: { mode: string }) {
  const userMode = mode === "tutor" ? "TUTOR" : "INSTITUTION";
  const [callForgotPasswordApi, { loading }] = useMutation(FORGOT_PASSWORD);
  const { enqueueSnackbar } = useSnackbar();

  async function onFormSubmit(values: any, actions: any) {
    console.log(values, actions);
    try {
      const result = await callForgotPasswordApi({
        variables: { input: { mode: userMode, email: values.email } },
      });
      console.log({ result });
      if (result?.data?.resetPassword?.status) {
        actions.resetForm();
        enqueueSnackbar("Reset link is sent to your acccount!", {
          variant: "success",
          autoHideDuration: 3000,
        });
      }
    } catch (error) {
      console.log("error", error);
      enqueueSnackbar(error?.graphQLErrors[0]?.message, {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  }

  return (
    <OnboardingFormContainer title="Forgot Password">
      <Formik
        onSubmit={onFormSubmit}
        initialValues={{
          email: "",
        }}
        isInitialValid={false}
        validationSchema={emailVerificationSchema}
      >
        {({ touched, errors, handleSubmit, isValid }) => (
          <Form onSubmit={handleSubmit}>
            <FieldContainer>
              <Field
                error={touched.email && errors.email ? true : false}
                helperText={touched.email && errors.email}
                name="email"
                label="Email"
                variant="outlined"
                as={StyledTextInput}
              />
            </FieldContainer>
            <SubmitBtnContainer>
              <PrimaryCTAButton
                disabled={!isValid || loading}
                type="submit"
                style={{ minWidth: 360 }}
              >
                Send Reset Link
              </PrimaryCTAButton>
            </SubmitBtnContainer>
          </Form>
        )}
      </Formik>
    </OnboardingFormContainer>
  );
}

export default ForgotPassword;
