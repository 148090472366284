import React from "react";
import BasicDetails from "./steps/BasicDetails";
import EnterOTP from "./steps/EnterOTP";
import CompleteKYC from "./steps/CompleteKYC";
import { FORM_STATES } from "../../_helper/enums";

interface Props {
  userStep: string;
}

const TutorRegistrationForm: React.FC<Props> = ({ userStep }) => {
  return (
    <div>
      {userStep === FORM_STATES.BASIC && <BasicDetails />}
      {userStep === FORM_STATES.OTP && <EnterOTP />}
      {userStep === FORM_STATES.KYC && <CompleteKYC />}
    </div>
  );
};

export default TutorRegistrationForm;
