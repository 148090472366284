const COLORS = {
  PRIMARY_WHITE: "#FFF",
  PRIMARY_PURPLE: "#7632ff",
  TUTOR_OPTION_PARAGRAPH: "#8f96a7",
  BREADCRUMB_GREY: "#868686",
  INPUT_ACTIVE_GREEN: "#00ada8",
  PRIMARY_BLACK: "#1e2d4f",
  ICON_BTN_BG: "#e1e4e9",
  HIGHTLIGHT_DETAIL: "#202f51",
  PRICE: "#00ada8",
  OLD_PRICE: "#1e2d4f",
  VERIFIED_BADGE: "#fa6400",
  SUBTITLE: "#1e2d4f",
  BLACK_OPACITY: "#8f96a7",
  LOGOUT_COLOR: "#fc4545",
  STATUS_YELLOW: "#ffc107",
  NEGATIVE_COLOR: "#ff293a",
  IMAGE_UPLOAD_BG: "#f0f0f0",
  POLICE_VERIFIED_BG: "rgba(250, 100, 0, 0.14)",
  SECTION_SEPARATOR: "#dadada",
  SECTION_BG_1: "#f9f9f9",
  SECTION_BG_2: "#f5f6f6",
  LINK_COLOR: "#7a38ff",
  INSTITUTE_TAG_TEXT: "#00ada8",
};

export default COLORS;
