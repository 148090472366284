import styled from "styled-components";
import COLORS from "../../../assets/colors";
import theme from "../../../assets/theme";

interface Props {
  desktop?: number;
  mobile?: number;
  tab?: number;
  type?: string;
  background?: string;
  colorValue?: string;
}

export const ActivityCardWrapper = styled.div`
  padding: 22px;
  background-color: ${COLORS.PRIMARY_WHITE};
  box-shadow: 0 2px 27px 0 rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  display: flex;
  width: 100%;
  max-width: 944px;
  margin-bottom: 30px;
  & .booking-id {
    font-size: 14px;
    font-family: ${theme.fonts.montserratmedium};
    color: #202f51;
    font-weight: 500;
    @media screen and ${theme.breakpoints.xs_down} {
      font-size: 12px;
      font-family: ${theme.fonts.montserratmedium};
      color: #00ada8;
      font-weight: 500;
    }
  }

  @media screen and ${theme.breakpoints.mat_md_down} {
    margin-left: 0;
    flex-direction: column;
  }
  & > .left-section {
    @media screen and ${theme.breakpoints.mat_md_down} {
      display: flex;
    }
    .mobile-title-address {
      display: none;
      margin-left: 16px;
      @media screen and ${theme.breakpoints.mat_md_down} {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-bottom: 5px;
      }
    }
  }
  & > .right-section {
    margin-left: 21px;
    flex: 1;
    display: flex;
    justify-content: space-between;
    @media screen and ${theme.breakpoints.mat_md_down} {
      margin-left: 0;
    }
    @media screen and ${theme.breakpoints.xs_down} {
      flex-direction: column;
    }
    & > .left-section {
      margin-right: 25px;
      display: flex;
      flex-direction: column;
      flex: 1;
      @media screen and ${theme.breakpoints.mat_md_down} {
        margin-right: 0;
      }
      .desktop-title-address {
        display: flex;
        flex-direction: column;
        @media screen and ${theme.breakpoints.mat_md_down} {
          display: none;
        }
      }
      & .tutor-section {
        display: flex;
        align-items: center;
        flex-flow: wrap;
        justify-content: space-between;
        @media screen and ${theme.breakpoints.xs_down} {
          flex-direction: column;
          align-items: flex-start;
        }
      }
      .duration-start-date {
        display: flex;
        flex-wrap: wrap;
      }
    }
    & > .right-section {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-end;
      min-width: 155px;
      @media screen and ${theme.breakpoints.mat_md_down} {
        min-width: unset;
      }
      @media screen and ${theme.breakpoints.xs_down} {
        flex-direction: row;
        flex-flow: wrap;
        & > div {
          margin-top: 30px;
        }
      }
    }
    & > .booking-right-section {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-end;
      min-width: 155px;
      margin-left: 20px;
      @media screen and ${theme.breakpoints.mat_md_down} {
        min-width: unset;
        margin-top: 30px;
      }
      @media screen and ${theme.breakpoints.xs_down} {
        align-items: flex-start;
        flex-direction: column;
        margin-left: 0px;
      }
    }
    .tutor-info-police-badge {
      display: flex;
      align-items: center;
      @media screen and ${theme.breakpoints.mat_md_down} {
        margin-top: 21px;
      }
    }
    .police-verification-desktop {
      @media screen and ${theme.breakpoints.mat_md_down} {
        display: none;
      }
    }
    .activity-price {
      display: flex;
      flex-direction: column;
      flex: 1;
      align-items: flex-end;
      justify-content: flex-end;
      margin-bottom: 20px;
      @media screen and ${theme.breakpoints.xs_down} {
        align-items: flex-start;
      }
    }
    .police-verification-mobile {
      display: none;
      @media screen and ${theme.breakpoints.mat_md_down} {
        display: block;
      }
    }
  }
  .button-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    @media screen and ${theme.breakpoints.xs_down} {
      justify-content: center;
      flex-flow: wrap;
      width: 100%;
    }
  }
`;

export const ActivityTitle = styled.h2`
  font-family: ${theme.fonts.montserratbold};
  font-size: 22px;
  font-weight: 600;
  @media screen and ${theme.breakpoints.xs} {
    font-size: 16px;
    font-family: ${theme.fonts.montserratsemibold};
  }
`;

export const ActivityAddress = styled.div`
  font-family: ${theme.fonts.maxrounded};
  color: ${COLORS.HIGHTLIGHT_DETAIL};
  margin: 13px 0;
  @media screen and ${theme.breakpoints.xs} {
    font-size: 12px;
    margin: 10px 0 0 0;
  }
`;

export const ActivityDescription = styled.p`
  font-family: ${theme.fonts.montserrat};
  color: ${COLORS.HIGHTLIGHT_DETAIL};
  opacity: 1;
  font-size: 12px;
  font-weight: 300;
  line-height: 1.67;
  margin-top: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
`;

export const ActivityImage = styled.img<Props>`
  width: ${({ desktop }) => desktop}px;
  height: ${({ desktop }) => desktop}px;
  border-radius: 10px;
  @media screen and ${theme.breakpoints.mat_md_down} {
    min-width: ${({ mobile }) => mobile}px;
    width: ${({ mobile }) => mobile}px;
    height: ${({ mobile }) => mobile}px;
  }

  @media screen and ${theme.breakpoints.mat_md_only} {
    min-width: ${({ tab }) => tab}px;
    width: ${({ tab }) => tab}px;
    height: ${({ tab }) => tab}px;
  }
`;

//Details Sectopm
export const ActivityDetailWrapper = styled.div`
  display: flex;
  margin-top: 13px;
  margin-right: 17px;
  flex-wrap: wrap;
  .colon-separator {
    margin: 0 4px 0 2px;
    line-height: 1.43;
  }
`;

export const DetailElement = styled.div<Props>`
  font-family: ${theme.fonts.montserratsemibold};
  line-height: 1.43;
  color: ${COLORS.HIGHTLIGHT_DETAIL};
  opacity: ${({ type }) => (type == "isLabel" ? 0.7 : 1)};
`;

export const EnrolledSection = styled.div`
  display: flex;
  flex-direction: column;
  letter-spacing: 0.7px;
  align-items: flex-end;
  color: #7632ff;
  flex: 1;
  .bold {
    font-family: ${theme.fonts.montserratbold};
    font-size: 20px;
  }
  .medium {
    font-family: ${theme.fonts.montserratmedium};
    font-size: 14px;
  }
`;

export const PlaceBadge = styled.div<Props>`
  padding: 6px 12px;
  text-transform: capitalize;
  font-family: ${theme.fonts.maxroundedmedium};
  border-radius: 9px;
  background-color: ${({ background }) => background};
  color: ${({ colorValue }) => colorValue};
`;
