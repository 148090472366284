import styled from "styled-components";
import theme from "../../assets/theme";

export const ContentPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  box-shadow: 0 2px 27px 0 rgba(0, 0, 0, 0.06);
  margin-top: 15px;
  margin-bottom: 190px;
  width: 100%;
  padding: 58px 50px;
  @media (max-width: 768px) {
    width: 90%;
    margin: 0 auto;
    margin-top: 30px;
    padding: 23px 17px;
  }
`;

export const Content = styled.div`
  h1 {
    font-family: ${theme.fonts.montserratbold};
    font-size: 32px;
    text-align: center;
    color: #1e2d4f;
    margin-bottom: 58px;
  }
  h4 {
    font-family: ${theme.fonts.montserratbold};
    font-size: 20px;
    color: #7632ff;
    margin: 29px 0 15px;
  }
  h6 {
    font-family: ${theme.fonts.montserrat};
    font-size: 18px;
    margin-bottom: 27px;
    margin-top: 12px;
    letter-spacing: 0.62px;
    color: #000000;
  }
  p {
    font-family: ${theme.fonts.montserratlight};
    font-size: 16px;
    line-height: 1.5;
    color: #1e2d4f;
  }
  ul {
    margin-top: 15px;
    margin-bottom: 15px;
    margin-left: 30px;
  }
  li {
    font-family: ${theme.fonts.montserratlight};
    font-size: 16px;
    line-height: 1.5;
    color: #1e2d4f;
  }

  b {
    font-family: ${theme.fonts.montserratsemibold};
    font-size: 16px;
    color: #1e2d4f;
  }
  @media (max-width: 768px) {
    h1,
    h4,
    h6,
    p {
      text-align: center;
    }
    h1 {
      margin-bottom: 40px;
      font-size: 20px;
    }
    h4 {
      margin-bottom: 21px;
      font-size: 14px;
    }
    h6 {
      margin: 0 0 11px;
      font-size: 12px;
    }
    p {
      font-size: 12px;
    }
    ul {
      margin: 0 auto;
      padding: 0 20px;
      margin-top: 15px;
      margin-bottom: 15px;
    }
    li {
      font-family: ${theme.fonts.montserratlight};
      font-size: 12px;
      line-height: 1.5;
      color: #1e2d4f;
    }

    b {
      font-family: ${theme.fonts.montserratsemibold};
      font-size: 12px;
      color: #1e2d4f;
    }
  }
`;
