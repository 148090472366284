import React from "react";
import { StyledActivityFormContainer } from "./styles";

interface Props {
  title: string;
  subtitle?: any;
  children?: any;
}

const CreateActivityFormContainer: React.FC<Props> = ({
  title,
  subtitle,
  children,
}) => {
  return (
    <StyledActivityFormContainer>
      <div className="form-title">{title}</div>
      <div className="form-subtitle">{subtitle}</div>
      {children}
    </StyledActivityFormContainer>
  );
};

export default CreateActivityFormContainer;
