import _ from "lodash";
import jwt_decode from "jwt-decode";
import moment from "moment";

const nospecial = /\/|\\/; // Restrict only slash and backslash

export const restrictSplChar = (e: any) => {
  if (nospecial.test(e.key)) {
    e.preventDefault();
    return false;
  }
};

export const isTexHasSplChar = (value: any) => {
  if (nospecial.test(value)) {
    return true;
  }
};

export function getPayloadObject(params: any) {
  const [
    sunday,
    monday,
    tuesday,
    wednesday,
    thursday,
    friday,
    saturday,
  ] = params.selectedDays;
  let payload: any = {
    title: params.title,
    description: params.description,
    bannerImageUrl: params.activity_image,
    urlSlug: _.kebabCase(params.title),
    ageGroup: params.ageRange,
    numberOfWeeks: params.numOfWeeks,
    daysPerWeek: params.numOfDaysPerWeek,
    startDate: params.startDate,
    sunday,
    monday,
    tuesday,
    wednesday,
    thursday,
    friday,
    saturday,
    price: params.price,
    maxCount: params.batchSize,
    categoryId: params.category,
    classHours: params.duration / 60,
    place: params.place,
    startTime: params.startTime,
  };
  if (payload.startTime && params.duration) {
    payload.endTime = moment(payload.startTime)
      .add(params.duration, "minutes")
      .toISOString();
  }
  return payload;
}

export function isDraftDisabled(values: any) {
  let submitValues = { ...values };
  if (!_.compact(submitValues.selectedDays).length)
    delete submitValues.selectedDays;
  if (submitValues.startDate.getDate() === new Date().getDate())
    delete submitValues.startDate;
  const array = _.compact(_.values(submitValues));
  if (array.length) {
    return false;
  } else {
    return true;
  }
}

export function getTutorId(userType: string | undefined, params: any) {
  if (userType === "individual_tutor") {
    const token = localStorage.getItem("vendor_token");
    if (token) {
      const tutor: any = jwt_decode(token);
      return tutor.uid;
      // } else {
      //   return 85;
    }
  } else {
    return params.tutor;
  }
}

export function getInstituteId() {
  const token = localStorage.getItem("vendor_token");
  if (token) {
    const institute: any = jwt_decode(token);
    return institute.uid;
  }
}

function getStaticValues(label: string, length: number) {
  return new Array(length).fill("").map((a, index) => ({
    label: `${index + 1} ${label}${index > 0 ? "s" : a}`,
    value: index + 1,
  }));
}

export const numOfWeeks = getStaticValues("Week", 8);

export const numOfDays = getStaticValues("Day", 7);

export const duration = [
  {
    label: `30mins`,
    value: 30,
  },
  {
    label: `1hr`,
    value: 60,
  },
  {
    label: `1hr 30mins`,
    value: 90,
  },
  {
    label: `2hrs`,
    value: 120,
  },
  {
    label: `2hrs 30mins`,
    value: 150,
  },
  {
    label: `3hrs`,
    value: 180,
  },
  {
    label: `3hrs 30mins`,
    value: 210,
  },
  {
    label: `4hrs`,
    value: 240,
  },
];

export const places = [
  {
    label: "Centre",
    value: "centre",
  },
  {
    label: "Home",
    value: "home",
  },
  {
    label: "Online",
    value: "online",
  },
];

export const ageRange = [
  {
    label: "1-3",
    value: "toddler",
  },
  {
    label: "3-5",
    value: "kinder_garden",
  },
  {
    label: "6-8",
    value: "primary",
  },
  {
    label: "8-10",
    value: "middle",
  },
  {
    label: "10-12",
    value: "high",
  },
  {
    label: "12-16",
    value: "senior_high",
  },
  {
    label: "16+",
    value: "adult",
  },
];

export const initialActivityState = {
  title: "",
  activity_image: "",
  category: "",
  tutor: "",
  price: "",
  description: "",
  numOfWeeks: "",
  numOfDaysPerWeek: "",
  selectedDays: new Array(7).fill(false),
  startDate: new Date(),
  duration: "",
  startTime: new Date(
    new Date().getFullYear(),
    new Date().getMonth(),
    new Date().getDate(),
    9,
    0
  ),
  batchSize: "",
  ageRange: "",
  place: "",
};

function checkValidDate(d: string) {
  const returnDate = new Date(d);
  if (Object.prototype.toString.call(returnDate) === "[object Date]") {
    if (isNaN(returnDate.getTime())) {
      return new Date();
    } else {
      return returnDate;
    }
  } else {
    return new Date();
  }
}

export function getInitialValues(data: any) {
  const {
    sunday = false,
    monday = false,
    tuesday = false,
    wednesday = false,
    thursday = false,
    friday = false,
    saturday = false,
  } = data;
  const selectedDays = [
    sunday,
    monday,
    tuesday,
    wednesday,
    thursday,
    friday,
    saturday,
  ];
  const payload = {
    title: data.title ? data.title : "",
    activity_image: data.bannerImageUrl ? data.bannerImageUrl : "",
    category: data?.category?.id
      ? data.category.id
      : data?.categoryId
      ? data?.categoryId
      : "",
    tutor: data?.tutor?.id
      ? data?.tutor?.id
      : data?.tutorId
      ? data.tutorId
      : "",
    price: data.price ? data.price : "",
    description: data.description ? data.description : "",
    numOfWeeks: data.numberOfWeeks ? data.numberOfWeeks : "",
    numOfDaysPerWeek: data.daysPerWeek ? data.daysPerWeek : "",
    selectedDays,
    startDate: checkValidDate(data.startDate),
    duration: data.classHours ? data.classHours * 60 : "",
    startTime: data.startTime
      ? data.startTime
      : new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate(),
          9,
          0
        ),
    batchSize: data.maxCount ? data.maxCount : "",
    ageRange: data.ageGroup ? data.ageGroup : "",
    place: data.place ? data.place : "",
  };
  return payload;
}
