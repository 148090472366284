export const FORM_STATES = {
  BASIC: "BASIC",
  OTP: "OTP",
  KYC: "KYC",
  INSTITUTE_DETAILS: "INSTITUTE_DETAILS",
};

export const DASHBOARD_NAVS = {
  MYACCOUNT: "MY_ACC",
  MYCLASSES: "MY_CLASSES",
  TUTOR: "TUTOR",
  REVIEWRATINGS: "REVIEW_RATINGS",
  TUTORREVIEW: "TUTOR_REVIEW",
  CLASSDETAILS: "CLASS_DETAILS",
};

export const AGE_GROUP: any = {
  toddler: "1-3",
  kinder_garden: "3-5",
  primary: "6-8",
  middle: "8-10",
  high: "10-12",
  senior_high: "12-16",
  adult: "16+",
};
