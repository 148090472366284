import React from "react";
import { Images } from "../../../assets/images";
import {
  ActivityCardWrapper,
  ActivityImage,
  ActivityTitle,
  ActivityAddress,
  ActivityDescription,
  EnrolledSection,
  DetailElement,
  PlaceBadge,
} from "./style";
import { PrimaryCTAButton } from "../../common/MaterialFields";
import ActivityDetail from "./ActivityDetail";
import ActivityPrice from "../../ActivityPrice";
import { format } from "date-fns";
import { Link } from "@reach/router";
import ActivityCardTutorInfo from "../../common/ActivityCardTutorInfo";
import COLORS from "../../../assets/colors";
import { Hidden } from "@material-ui/core";
import useAuth from "../../../hooks/useAuth";

interface Props {
  course: any;
  id?: string;
  sortVal?: string;
  DraftTutor?: any;
}

const ActivityCard: React.FC<Props> = ({ id, course, sortVal, DraftTutor }) => {
  const { token } = useAuth();

  const openLink = () => {
    let URL = `${process.env.REACT_APP_KYC_VERIFICATION_URL}/start-zoom?jwt=${token}&course_id=${course?.id}&redirect_url=${process.env.REACT_APP_ZOOM_REDIRECTION}`;
    window.open(URL, "_blank");
  };

  return (
    <ActivityCardWrapper>
      <div className="left-section">
        <div>
          <ActivityImage
            desktop={229}
            mobile={77}
            tab={130}
            src={
              course?.bannerImageUrl
                ? course.bannerImageUrl
                : Images.coursePlaceHolder
            }
          />
          <Hidden implementation="css" only={["xs", "sm"]}>
            {course?.zoomMeeting?.startTime && (
              <div className="w150">
                <DetailElement type="isLabel" className="noWrap p5">
                  Online Class Link
                </DetailElement>
                <div className="pl5">
                  <PrimaryCTAButton
                    className="ml10 w100 f12"
                    disableElevation
                    color="primary"
                    variant="contained"
                    onClick={openLink}
                  >
                    Start
                  </PrimaryCTAButton>
                </div>
              </div>
            )}
          </Hidden>
        </div>
        <div className="mobile-title-address">
          <ActivityTitle>{course?.title}</ActivityTitle>
          {course?.address && (
            <ActivityAddress>{course?.address}</ActivityAddress>
          )}
          <Hidden implementation="css" only={["xs", "lg", "md", "xl"]}>
            {course?.zoomMeeting?.startTime && (
              <div className="w150">
                <DetailElement type="isLabel" className="noWrap p5">
                  Online Class Link
                </DetailElement>
                <div className="pl5">
                  <PrimaryCTAButton
                    className="ml10 w100 f12"
                    disableElevation
                    color="primary"
                    variant="contained"
                    onClick={openLink}
                  >
                    Start
                  </PrimaryCTAButton>
                </div>
              </div>
            )}
          </Hidden>
        </div>
      </div>
      <div className="right-section">
        <div className="left-section">
          <div className="desktop-title-address">
            <ActivityTitle>{course?.title}</ActivityTitle>
            {course?.address && (
              <ActivityAddress>{course?.address}</ActivityAddress>
            )}
          </div>
          <div className="flex wrap pt10">
            {course?.tutor?.firstName && sortVal === "active" ? (
              <ActivityCardTutorInfo
                name={course?.tutor?.firstName}
                src={course?.tutor?.avatarUrl}
              />
            ) : (
              <>
                {DraftTutor?.firstName && (
                  <ActivityCardTutorInfo
                    name={DraftTutor?.firstName}
                    src={DraftTutor?.avatarUrl}
                  />
                )}
              </>
            )}
            {course?.place && (
              <PlaceBadge
                background={
                  course?.place === "online"
                    ? "rgba(46, 46, 46, 0.1)"
                    : course?.place === "home"
                    ? "rgba(1, 123, 255, 0.1)"
                    : "rgba(0, 173, 168, 0.16)"
                }
                colorValue={
                  course?.place === "online"
                    ? COLORS.PRIMARY_BLACK
                    : course?.place === "home"
                    ? COLORS.PRIMARY_PURPLE
                    : COLORS.INPUT_ACTIVE_GREEN
                }
              >
                {course?.place === "online"
                  ? `${course?.place} class`
                  : course?.place === "home"
                  ? `${course?.place} visit`
                  : course?.place}
              </PlaceBadge>
            )}
          </div>

          <ActivityDescription>{course?.description}</ActivityDescription>
          <div>
            <ActivityDetail
              label="Timings"
              value={`${
                course?.startTime
                  ? format(new Date(course?.startTime), "h:mm a")
                  : "Nil"
              } - ${
                course?.endTime
                  ? format(new Date(course?.endTime), "h:mm a")
                  : "Nil"
              }
            `}
            />
            <ActivityDetail
              label="Duration"
              value={
                course?.numberOfWeeks ? `${course?.numberOfWeeks} Weeks` : "Nil"
              }
            />
            <ActivityDetail
              label="Start Date"
              value={
                course?.startDate
                  ? format(new Date(course?.startDate), "dd MMMM yyyy")
                  : "Nil"
              }
            />
            <ActivityDetail
              label="Days of Class"
              value={setWeeks(course) ? setWeeks(course) : "Nil"}
            />
          </div>
        </div>
        <div className="right-section">
          <ActivityPrice
            className="flex column wm50p"
            originalPrice={"5000"}
            price={course?.price ? course?.price : "-"}
          />
          <EnrolledSection className="wm50p">
            <div className="bold">
              {course?.maxCount
                ? course?.maxCount -
                  (sortVal === "active"
                    ? course?.remainingSeats
                    : course?.maxCount)
                : "-"}
              /{course?.maxCount ? course?.maxCount : "-"}
            </div>
            <div className="medium">Enrolled</div>
          </EnrolledSection>
          <Hidden implementation="css" only={["sm", "lg", "md", "xl"]}>
            {course?.zoomMeeting?.startTime && (
              <div className="w150">
                <DetailElement type="isLabel" className="noWrap p5">
                  Online Class Link
                </DetailElement>
                <div className="pl5">
                  <PrimaryCTAButton
                    className="ml10 w100 f12"
                    disableElevation
                    color="primary"
                    variant="contained"
                    onClick={openLink}
                  >
                    Start
                  </PrimaryCTAButton>
                </div>
              </div>
            )}
          </Hidden>
          <div className="button-wrapper">
            {sortVal === "draft" ? (
              <Link
                to={`../draft/${id}`}
                // as={`draft/${id}`}
              >
                <PrimaryCTAButton
                  className="ml10 w100 f12"
                  disableElevation
                  color="primary"
                  variant="contained"
                >
                  Edit
                </PrimaryCTAButton>
              </Link>
            ) : (
              <>
                {sortVal === "active" && (
                  <Link to={`${course?.id}/edit`}>
                    <PrimaryCTAButton
                      className="ml10 w100 f12"
                      disableElevation
                      color="primary"
                      variant="contained"
                    >
                      Edit
                    </PrimaryCTAButton>
                  </Link>
                )}
              </>
            )}
            <Link to={`${id ? id : course?.id}/${sortVal}`}>
              <PrimaryCTAButton
                className="ml10 w100 f12"
                disableElevation
                color="primary"
                variant="contained"
              >
                View
              </PrimaryCTAButton>
            </Link>
          </div>
        </div>
      </div>
    </ActivityCardWrapper>
  );
};

export default ActivityCard;

export const setWeeks = (course: any) => {
  let weekArry: Array<string> = [];
  if (course?.sunday) {
    weekArry.push("Sun");
  }
  if (course?.monday) {
    weekArry.push("Mon");
  }
  if (course?.tuesday) {
    weekArry.push("Tue");
  }
  if (course?.wednesday) {
    weekArry.push("Wed");
  }
  if (course?.thursday) {
    weekArry.push("Thu");
  }
  if (course?.friday) {
    weekArry.push("Fri");
  }
  if (course?.saturday) {
    weekArry.push("Sat");
  }
  return weekArry.join(", ");
};
