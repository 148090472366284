import React, { useState } from "react";
import { EnrollmentWrapper } from "./style";
import ReportParent from "../DashBoard/MyClasses/ReportParent";

interface Props {
  data: any;
}

const ActivityEnrollment: React.FC<Props> = ({ data }) => {
  const [showReportDialog, setShowReportDialog] = useState({} as any);
  return (
    <EnrollmentWrapper>
      {data?.length > 0 ? (
        <div>
          <div className="header-section">
            <div className="mwidth40">Sl.</div>
            <div className="mwidth150">Kid Name</div>
            <div className="mwidth200">Parent Name</div>
            <div className="mwidth150">Contact</div>
            <div className="mwidth150">Booking ID</div>
            <div className="mwidth60 hidden">Report</div>
          </div>
          <div className="content pt15">
            {data?.map((value: any, idx: number) => {
              return (
                <>
                  {value?.status === "activated" && (
                    <div className="flex row pt15 pb15">
                      <div className="mwidth40">{idx + 1}</div>
                      <div className="mwidth150">{value?.child?.firstName}</div>
                      <div className="mwidth200">
                        {value?.parent?.firstName}
                      </div>
                      <div className="mwidth150">
                        {value?.parent?.phoneNumber}
                      </div>
                      <div className="mwidth150">{value.id}</div>
                      <div className="mwidth60">
                        <div
                          className="link"
                          onClick={() =>
                            setShowReportDialog({
                              show: !showReportDialog.show,
                              id: value.parent.id,
                            })
                          }
                        >
                          Report
                        </div>
                      </div>
                    </div>
                  )}
                </>
              );
            })}
          </div>
        </div>
      ) : (
        <div className="content p20 flex center-center f20">
          No one enroll this course yet!!!
        </div>
      )}

      {/* Report Dialog */}
      {showReportDialog?.show && (
        <ReportParent
          setShowReportDialog={setShowReportDialog}
          showReportDialog={showReportDialog}
        />
      )}
    </EnrollmentWrapper>
  );
};

export default ActivityEnrollment;
