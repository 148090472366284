import React from "react";
import Signin from "../../components/Signin";
import Layout from "../../components/Layout/Layout";
import { useLocation } from "@reach/router";
import InstituteForgotPassword from "./InstituteForgotPassword";
import InstituteResetPassword from "./InstituteResetPassword";

function InstituteSignin() {
  const { pathname } = useLocation();
  const isForgotPassword = pathname.includes("forgot-password");
  const isResetPassword = pathname.includes("reset-password");

  function renderComponent() {
    if (isForgotPassword) {
      return <InstituteForgotPassword />;
    } else if (isResetPassword) {
      return <InstituteResetPassword />;
    } else return <Signin mode="institute" />;
  }

  return <Layout isFooterEnable={false}>{renderComponent()}</Layout>;
}

export default InstituteSignin;
