import React from "react";
import styled from "styled-components";
import { Heading } from "./styles";
import { Para } from "./styles";

interface Props {
  data?: any;
}

const Certifications: React.FC<Props> = ({ data }) => {
  return (
    <Container>
      <Heading>Certifications</Heading>
      <Para>{data?.certification}</Para>
    </Container>
  );
};

export default Certifications;

const Container = styled.div``;
