import React, { useState, useEffect } from "react";
import { Heading, ContentWrapper, Font } from "../style";
import {
  PrimaryCTAButton,
  CustomDialog,
  ColorCircularProgress,
} from "../../common/MaterialFields";
import TutorCard from "../../TutorCard";
import { Link } from "@reach/router";
// import { TransitionProps } from "@material-ui/core/transitions";
import { useMediaQuery } from "@material-ui/core";
import Addtutor from "./Addtutor";
import { useLazyQuery } from "react-apollo";
import { GET_TUTOR_LIST } from "../../../graphql/queries";

interface Props {}

// const Transition = React.forwardRef<unknown, TransitionProps>(
//   function Transition(props, ref) {
//     return <Slide direction="up" ref={ref} {...props} />;
//   }
// );

const TutorList: React.FC<Props> = () => {
  const fullScreen = useMediaQuery("(max-width:640px)");
  const [openDialog, setOpendialog] = useState<boolean>(false);
  const [tutorList, setTutorList] = useState<any>([]);
  const [callGetTutorList, { data, loading }] = useLazyQuery(GET_TUTOR_LIST, {
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    callGetTutorList();
  }, []);

  useEffect(() => {
    if (data?.tutors?.nodes) {
      setTutorList(data?.tutors?.nodes);
    }
  }, [data]);

  return (
    <div>
      <ContentWrapper className="flex row wrap center-center">
        <Heading className="flex-1" fontSize="20px">
          Tutors
        </Heading>
        <PrimaryCTAButton
          onClick={() => setOpendialog(true)}
          disableElevation
          color="primary"
          variant="contained"
        >
          Add Tutor
        </PrimaryCTAButton>
      </ContentWrapper>
      <div style={{ minHeight: "calc(100vh - 250px)" }}>
        {loading ? (
          <div className="flex center-center h50p">
            <ColorCircularProgress size={50} thickness={5} />
          </div>
        ) : (
          <>
            {tutorList.length > 0 ? (
              <div className="flex wrap">
                {tutorList?.map((tutor: any, index: number) => (
                  <Link
                    key={index}
                    to={`/institute/dashboard/tutor/${tutor.id}`}
                  >
                    <ContentWrapper>
                      <TutorCard data={tutor} />
                    </ContentWrapper>
                  </Link>
                ))}
              </div>
            ) : (
              <div className="flex center-center h100p">
                <Font fontSize="24px" className="maxwidth500 center">
                  Click the "Add Tutor" button to get Get Started!
                </Font>
              </div>
            )}
          </>
        )}
      </div>

      <CustomDialog
        onExit={() => callGetTutorList()}
        fullScreen={fullScreen}
        open={openDialog}
        aria-labelledby="Dialog"
        // TransitionComponent={Transition}
      >
        <Addtutor setOpendialog={setOpendialog} />
      </CustomDialog>
    </div>
  );
};

export default TutorList;
