import React from "react";
import Rating from "@material-ui/lab/Rating";
import { ReviewsForTutorContainer } from "./styles";
import { Hidden } from "@material-ui/core";
import { PrimaryCTAButton } from "../common/MaterialFields";
import useAuth from "../../hooks/useAuth";

interface Props {
  numOfReviews: number;
  rating: number;
  type?: string;
  hideExtraDetails?: boolean;
  className?: string;
  isPending?: boolean;
  id?: string;
}

const ReviewsForTutor: React.FC<Props> = (props: Props) => {
  const { token } = useAuth();

  const redirectToAadhar = (ev: any, id?: string) => {
    ev.preventDefault();
    if (token && id) {
      window.open(
        `${process.env.REACT_APP_KYC_VERIFICATION_URL}/verify-aadhar?jwt=${token}&tutor_id=${id}&redirect_url=${process.env.REACT_APP_AADHAR_REDIRECT_URL}/${id}`,
        "_self"
      );
    }
  };

  return (
    <ReviewsForTutorContainer className={props.className}>
      <Hidden implementation="css" lgUp>
        {!props.isPending && (
          <div className="max-rounded-medium f16">{props.numOfReviews}</div>
        )}
      </Hidden>

      {props.isPending ? (
        <PrimaryCTAButton
          className="p6 f12"
          onClick={(ev) => redirectToAadhar(ev, props.id)}
        >
          Complete KYC
        </PrimaryCTAButton>
      ) : (
        <>
          <Rating
            name="read-only"
            value={props.rating}
            readOnly
            precision={0.5}
            size="large"
          />
          <span className={props.hideExtraDetails ? "small text" : "text"}>
            <Hidden implementation="css" mdDown>
              <span>&nbsp;&nbsp;{props.numOfReviews}</span>
              <span>&nbsp;reviews</span>
            </Hidden>
            {!props.hideExtraDetails && (
              <>
                <span>&nbsp;for &nbsp;</span>
                <span>
                  {props.type === "tutor" ? "instructor" : "institute"}
                </span>{" "}
              </>
            )}
          </span>
        </>
      )}
    </ReviewsForTutorContainer>
  );
};

export default ReviewsForTutor;
