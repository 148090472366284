import React, { useState, useEffect } from "react";
import OnboardingFormContainer from "../../common/OnboardingFormContainer";
import {
  FieldContainer,
  StyledTextInput,
  TermsNote,
  SubmitBtnContainer,
} from "../../common/OnboardingFormContainer/styles";
import {
  PrimaryCTAButton,
  ColorCircularProgress,
} from "../../common/MaterialFields";
import { Formik, Form, Field } from "formik";
import FileUploadInput from "../../common/FileUploadInput";
import useAuth from "../../../hooks/useAuth";
import { instituteSignUpSchema } from "../../../_helper/ValidationSchema";
import { useSnackbar } from "notistack";
import { useMutation, useQuery } from "react-apollo";
import { INSTITUTE_SIGNUP } from "../../../graphql/mutation";
import CustomSelectField from "../../CreateActivity/Fields/CustomSelectField";
import { GET_CATEGORIES } from "../../../graphql/queries";
import _ from "lodash";
import { navigate } from "@reach/router";

interface Props {}

const InstituteDetailsForm: React.FC<Props> = () => {
  const { userInfo, setUserInfo } = useAuth();
  const [registrationDoc, setRegistrationDoc] = useState<string>("");
  const [regisDocLoading, setRegisDocLoading] = useState<boolean>(false);
  const CategoriesResponse = useQuery(GET_CATEGORIES);
  const [gstDoc, setGstDoc] = useState<string>("");
  const [gstfileLoading, setgstfileLoading] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();
  const [instituteSignup, { loading }] = useMutation(INSTITUTE_SIGNUP);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    if (!_.isEmpty(CategoriesResponse.data)) {
      if (CategoriesResponse.data?.categories?.nodes) {
        setCategories(CategoriesResponse.data.categories.nodes);
      }
    }
  }, [CategoriesResponse.data]);

  const onFormSubmit = (values: any) => {
    let postObj: any = JSON.parse(JSON.stringify(values));
    postObj.institute.kycDocuments = [];
    postObj.institute.kycDocuments.push(registrationDoc);
    delete postObj.institute.registrationDocument;
    delete postObj.institute.gstDocument;
    if (gstDoc) {
      postObj.institute.kycDocuments.push(gstDoc);
    }
    instituteSignup({
      variables: {
        input: postObj,
      },
    })
      .then((res) => {
        if (res?.data?.instituteSignup?.status) {
          enqueueSnackbar(res.data.instituteSignup.message, {
            variant: "success",
            autoHideDuration: 3000,
          });
          setUserInfo(values);
          navigate("/institute/verify-otp");
        }
      })
      .catch((err) => {
        console.log(err);
        enqueueSnackbar(err.graphQLErrors[0].message, {
          variant: "error",
          autoHideDuration: 3000,
        });
      });
  };

  return (
    <OnboardingFormContainer
      title="Registration Form"
      subtitle="Please fill out the following details"
    >
      {CategoriesResponse?.loading ? (
        <div className="flex center-center h50p">
          <ColorCircularProgress size={50} thickness={5} />
        </div>
      ) : (
        <Formik
          onSubmit={onFormSubmit}
          initialValues={{
            institute: {
              firstName: userInfo?.institute?.firstName
                ? userInfo?.institute?.firstName
                : "",
              phoneNumber: userInfo?.institute?.phoneNumber
                ? userInfo?.institute?.phoneNumber
                : "",
              email: userInfo?.institute?.email
                ? userInfo?.institute?.email
                : "",
              password: userInfo?.institute?.password
                ? userInfo?.institute?.password
                : "",
              panNumber: userInfo?.institute?.panNumber
                ? userInfo?.institute?.panNumber
                : "",
              address: userInfo?.institute?.address
                ? userInfo?.institute?.address
                : "",
              registrationDocument: "",
              gstDocument: "",
            },
            allowedCategories: userInfo?.allowedCategories
              ? userInfo.allowedCategories
              : [],
          }}
          validationSchema={instituteSignUpSchema}
          isInitialValid={false}
        >
          {({ handleSubmit, touched, errors, setFieldValue, values }) => (
            <Form onSubmit={handleSubmit}>
              <FieldContainer>
                <Field
                  error={
                    touched.institute?.firstName && errors.institute?.firstName
                      ? true
                      : false
                  }
                  autoFocus={true}
                  helperText={
                    touched.institute?.firstName && errors.institute?.firstName
                  }
                  name="institute.firstName"
                  id="outlined-basic"
                  label="Institute Name"
                  variant="outlined"
                  as={StyledTextInput}
                />
              </FieldContainer>
              <FieldContainer>
                <Field
                  error={
                    touched.institute?.email && errors.institute?.email
                      ? true
                      : false
                  }
                  helperText={
                    touched.institute?.email && errors.institute?.email
                  }
                  name="institute.email"
                  id="outlined-basic"
                  label="Official Email"
                  variant="outlined"
                  as={StyledTextInput}
                />
              </FieldContainer>
              <FieldContainer>
                <Field
                  error={
                    touched.institute?.phoneNumber &&
                    errors.institute?.phoneNumber
                      ? true
                      : false
                  }
                  helperText={
                    touched.institute?.phoneNumber &&
                    errors.institute?.phoneNumber
                  }
                  inputProps={{ maxLength: 10 }}
                  name="institute.phoneNumber"
                  id="outlined-basic"
                  label="Phone Number"
                  variant="outlined"
                  as={StyledTextInput}
                />
              </FieldContainer>
              <FieldContainer>
                <Field
                  error={
                    touched.allowedCategories && errors.allowedCategories
                      ? true
                      : false
                  }
                  helperText={
                    touched.allowedCategories && errors.allowedCategories
                  }
                  name="allowedCategories"
                  id="outlined-basic"
                  label="Categories"
                  variant="outlined"
                  as={CustomSelectField}
                  value={values.allowedCategories}
                  multiple
                  menuItemValues={categories.map((category: any) => ({
                    label: category.name,
                    value: category.id,
                  }))}
                  selectedItems={values.allowedCategories}
                />
              </FieldContainer>
              <FieldContainer>
                <Field
                  error={
                    touched.institute?.password && errors.institute?.password
                      ? true
                      : false
                  }
                  helperText={
                    touched.institute?.password && errors.institute?.password
                  }
                  name="institute.password"
                  id="outlined-basic"
                  label="Set Password"
                  variant="outlined"
                  type="password"
                  as={StyledTextInput}
                />
              </FieldContainer>
              <FieldContainer>
                <Field
                  error={
                    touched.institute?.address && errors.institute?.address
                      ? true
                      : false
                  }
                  helperText={
                    touched.institute?.address && errors.institute?.address
                  }
                  name="institute.address"
                  id="outlined-basic"
                  label="Address"
                  variant="outlined"
                  as={StyledTextInput}
                />
              </FieldContainer>
              <FieldContainer>
                <Field
                  error={
                    errors.institute?.registrationDocument &&
                    touched.institute?.registrationDocument
                      ? true
                      : false
                  }
                  helperText={
                    touched.institute?.registrationDocument &&
                    errors.institute?.registrationDocument
                      ? errors.institute?.registrationDocument
                      : ""
                  }
                  name="institute.registrationDocument"
                  onChange={(url: string) => setRegistrationDoc(url)}
                  setFileLoading={(loading: boolean) =>
                    setRegisDocLoading(loading)
                  }
                  label="Registration Documents"
                  setFieldValue={setFieldValue}
                  as={FileUploadInput}
                />
              </FieldContainer>
              <FieldContainer>
                <Field
                  error={
                    touched.institute?.panNumber && errors.institute?.panNumber
                      ? true
                      : false
                  }
                  helperText={
                    touched.institute?.panNumber && errors.institute?.panNumber
                  }
                  name="institute.panNumber"
                  id="outlined-basic"
                  label="Permanent Account Number"
                  variant="outlined"
                  as={StyledTextInput}
                />
              </FieldContainer>
              <FieldContainer>
                <Field
                  error={
                    errors.institute?.gstDocument &&
                    touched.institute?.gstDocument
                      ? true
                      : false
                  }
                  helperText={
                    touched.institute?.gstDocument &&
                    errors.institute?.gstDocument
                      ? errors.institute?.gstDocument
                      : ""
                  }
                  name="institute.gstDocument"
                  onChange={(url: string) => setGstDoc(url)}
                  label="GST Document (Optional)"
                  setFieldValue={setFieldValue}
                  setFileLoading={(loading: boolean) =>
                    setgstfileLoading(loading)
                  }
                  as={FileUploadInput}
                />
              </FieldContainer>
              <TermsNote>
                <span>By clicking on "Submit", you agree to all of our</span>
                <br />
                <span>
                  <a
                    style={{
                      textDecoration: "none",
                    }}
                    target="_blank"
                    href={"/instructor_terms_and_conditions"}
                  >
                    Terms & Conditions
                  </a>
                </span>
              </TermsNote>
              <SubmitBtnContainer>
                <PrimaryCTAButton
                  disabled={regisDocLoading || gstfileLoading || loading}
                  type="submit"
                  style={{ minWidth: 360 }}
                >
                  Submit
                </PrimaryCTAButton>
              </SubmitBtnContainer>
            </Form>
          )}
        </Formik>
      )}
    </OnboardingFormContainer>
  );
};

export default InstituteDetailsForm;
