import styled, { css } from "styled-components";
import {
  StyledFormContainer,
  StyledTextInput,
  FieldContainer,
} from "../OnboardingFormContainer/styles";
import theme from "../../../assets/theme";

export const StyledActivityFormContainer = styled(StyledFormContainer)`
  max-width: 702px;
  position: relative;
  .go-back {
    position: absolute;
    top: 30px;
    left: 30px;
    font-size: 16px;
    font-family: ${(props) => props.theme.fonts.maxroundedmedium};
    color: #7632ff;
    cursor: pointer;
    @media screen and ${theme.breakpoints.xs_down} {
      display: none;
    }
  }
`;

export const ActivityTextInput = styled(StyledTextInput)`
  width: 464px;
  max-width: 100%;
  & textarea {
    font-size: 16px;
    line-height: 20px;
    font-family: ${(props) => props.theme.fonts.maxroundedmedium};
  }
`;

export const HorizontalFieldsContainer = styled.div`
  display: flex;
  margin: 24px auto;
  max-width: 464px;
  justify-content: space-between;
  @media screen and ${theme.breakpoints.xs_down} {
    flex-direction: column;
    width: 100%;
    max-width: none;
  }
`;

interface HalfFieldProps {
  fullWidth?: boolean;
}

export const FieldContainer50 = styled(FieldContainer)<HalfFieldProps>`
  margin: 0;
  width: 100%;
  ${({ fullWidth }) =>
    !fullWidth &&
    css`
      max-width: calc(50% - 19px);
    `}
`;

interface Props {
  error?: any;
}

export const StyledTextArea = styled.textarea`
  width: 100%;
  padding: 18px 12px;
  font-family: ${(props) => props.theme.fonts.maxroundedmedium};
  font-size: 16px;
  font-weight: 500;
  border: ${({ error }: Props) =>
    error ? "solid 1px #f44336;" : "solid 1px rgba(79,30,45,0.2)"};
  background-color: #ffffff;
  border-radius: 10px;
  outline: none;
`;

export const DraftButtonContainer = styled.div`
  width: 360px;
  @media screen and ${theme.breakpoints.xs_down} {
    width: 100%;
  }
`;

export const CreateButtonContainer = styled.div`
  width: 360px;
  margin-top: 21px;
  @media screen and ${theme.breakpoints.xs_down} {
    width: 100%;
  }
`;
