import React, { useState } from "react";

interface Props {
  children: any;
}

interface Iprops {
  dialogState: any;
  setDialogState: React.Dispatch<React.SetStateAction<any>>;
  phoneNumber: string;
  setPhoneNumber: React.Dispatch<React.SetStateAction<string>>;
}

export const DialogContext = React.createContext({} as Iprops);

export const DialogProvider: React.FC<Props> = ({ children }) => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [dialogState, setDialogState] = useState({});
  const value = React.useMemo(() => {
    return {
      dialogState,
      setDialogState,
      phoneNumber,
      setPhoneNumber,
    };
  }, [dialogState, setDialogState, phoneNumber, setPhoneNumber]);

  return (
    <DialogContext.Provider value={value}>{children}</DialogContext.Provider>
  );
};
