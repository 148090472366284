import React, { useRef } from "react";
import AppRouter from "./AppRouter";
import { ThemeProvider } from "styled-components";
import theme from "./assets/theme";
import { DialogProvider } from "./Context/DialogContext";
import { SnackbarProvider } from "notistack";
import { SnackbarButton } from "./components/common/MaterialFields";
import { makeStyles } from "@material-ui/core";
import "./styles/main.scss";
import { LangProvider } from "./Context/LanguageContext";
import { AuthProvider } from "./Context/AuthContext";

const useStyles = makeStyles({
  root: {
    "&MuiSnackbarContent-message": {
      fontSize: "16px",
    },
  },
});

function App() {
  // const [token, setToken] = useState<string | null>();

  // useEffect(() => {
  //   setToken(User.getToken());
  // }, []);

  const snackBarRef = useRef();
  const classes = useStyles();

  function onClickDismiss(key: any) {
    // @ts-ignore
    snackBarRef?.current?.closeSnackbar(key);
  }

  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider
        ref={snackBarRef}
        maxSnack={1}
        classes={{
          root: classes.root,
        }}
        action={(key) => (
          <SnackbarButton onClick={() => onClickDismiss(key)}>
            Dismiss
          </SnackbarButton>
        )}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <AuthProvider>
          <LangProvider>
            <DialogProvider>
              <AppRouter />
            </DialogProvider>
          </LangProvider>
        </AuthProvider>
      </SnackbarProvider>
    </ThemeProvider>
  );
}

export default App;
