import React from "react";
import Layout from "../../components/Layout/Layout";
import AccountVerifying from "../../components/AccountPendingReview/AccountVerifying";

const KycVerifying = () => {
  return (
    <Layout isFooterEnable={false}>
      <AccountVerifying />
    </Layout>
  );
};

export default KycVerifying;
