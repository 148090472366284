import styled from "styled-components";
import theme from "../../assets/theme";
import COLORS from "../../assets/colors";

export const ReviewsForTutorContainer = styled.div`
  display: flex;
  align-items: center;

  @media screen and ${theme.breakpoints.mat_xs_down} {
    .MuiRating-root {
      font-size: 12px;
    }
  }
  .text {
    font-size: 14px;
    color: ${COLORS.SUBTITLE};
    opacity: 0.47;
    line-height: 19px;
    margin-top: 2px;
    &.small {
      font-size: 12px;
    }
  }
`;
