import React from "react";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { ButtonStyle } from "../../DashBoard/style";

interface Props {
  handleCancel: () => void;
  handleOk: () => void;
  deleteLoading?: boolean;
  heading: string;
  content: string;
}

const index: React.FC<Props> = ({
  handleCancel,
  handleOk,
  deleteLoading,
  heading,
  content
}) => {
  return (
    <>
      <DialogTitle>
        <span className="f24 max-rounded-bold">{heading}</span>
      </DialogTitle>
      <DialogContent>
        <div className="f16 max-rounded">{content}</div>
      </DialogContent>
      <DialogActions>
        <ButtonStyle
          disabled={deleteLoading}
          color="primary"
          onClick={handleCancel}
        >
          <span className="f16 max-rounded">Cancel</span>
        </ButtonStyle>
        <ButtonStyle
          disabled={deleteLoading}
          color="primary"
          onClick={handleOk}
          autoFocus
        >
          <span className="f16 max-rounded">Ok</span>
        </ButtonStyle>
      </DialogActions>
    </>
  );
};

export default index;
