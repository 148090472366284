import styled from "styled-components";
import COLORS from "../../assets/colors";
import theme from "../../assets/theme";

export const StyledActivityPrice = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  font-family: ${theme.fonts.montserratsemibold};
  font-size: 28px;
  font-weight: 500;
  flex: 1;
  color: ${COLORS.PRICE};
  @media screen and ${theme.breakpoints.xs} {
    margin-bottom: 0;
    align-items: flex-start;
    justify-content: flex-start;
  }
  &.in-view-details {
    flex-direction: row-reverse;
    justify-content: flex-end;
  }
  .old-price {
    text-decoration: line-through;
    color: ${COLORS.OLD_PRICE};
    font-family: ${theme.fonts.montserratsemibold};
    font-size: 16px;
    margin-right: 8px;
    line-height: 28px;
  }
`;
