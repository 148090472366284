import React, { useState, useEffect } from "react";
import { Link } from "@reach/router";
import { TabContainer } from "../style";
import {
  navLinksInstitute,
  navLinksTutor,
} from "../../../_helper/dashboardLinks";
import { DASHBOARD_NAVS } from "../../../_helper/enums";

interface Props {
  userStep: string;
  institute?: boolean;
}

const DashboardMobNav: React.FC<Props> = ({ userStep, institute }) => {
  const [navLinks, setNavLinks] = useState<any>([]);

  useEffect(() => {
    if (institute) {
      setNavLinks(navLinksInstitute.filter((el) => el.key != "LOGOUT"));
    } else {
      setNavLinks(
        navLinksTutor.filter((el) => el.key != "LOGOUT" && el.key != "SUPPORT")
      );
    }
  }, []);

  return (
    <>
      {navLinks?.map((link: any, index: number) => (
        <Link key={index} to={link.href} className="flex-1">
          <TabContainer
            selected={
              userStep === link.key ||
              (userStep === DASHBOARD_NAVS.TUTORREVIEW &&
                link.key === DASHBOARD_NAVS.TUTOR)
            }
          >
            <div>
              <img
                src={
                  userStep === link.key ||
                  (userStep === DASHBOARD_NAVS.TUTORREVIEW &&
                    link.key === DASHBOARD_NAVS.TUTOR)
                    ? link.imgBlack
                    : link.imgSrc
                }
                alt={link.key}
              />
            </div>
          </TabContainer>
        </Link>
      ))}
    </>
  );
};

export default DashboardMobNav;
