import styled from "styled-components";
import theme from "../../../assets/theme";
import COLORS from "../../../assets/colors";

export const ViewActivityWrapper = styled.div`
  padding: 35px;
  background-color: ${COLORS.PRIMARY_WHITE};
  max-width: 1130px;
  border-radius: 16px;
  @media screen and ${theme.breakpoints.sm_down} {
    padding: 0px;
  }

  .reviews-title {
    font-family: ${(props) => props.theme.fonts.maxroundedmedium};
    color: ${COLORS.SUBTITLE};
    font-size: 16px;
    margin: 23px 0 29px;
    opacity: 0.47;
  }
  .session-timming {
    opacity: 0.7;
    font-family: ${theme.fonts.montserratmedium};
    font-size: 20px;
    font-weight: 500;
    line-height: 1.2;
    color: ${COLORS.HIGHTLIGHT_DETAIL};
  }

  .image-title-section {
    position: relative;
    display: flex;
    padding-bottom: 36px;
    .section-separator {
      position: absolute;
      left: -35px;
      right: -35px;
      bottom: 0;
      height: 1px;
      background-color: ${COLORS.SECTION_SEPARATOR};
      opacity: 0.5;
    }
    .content {
      margin-left: 48px;
      .title {
        margin-bottom: 32px;
      }
      .tutor-info {
        margin-bottom: 20px;
      }
      .police-verified {
        margin-bottom: 12px;
        background-color: ${COLORS.POLICE_VERIFIED_BG};
        padding: 5px 14px;
        display: inline-flex;
        border-radius: 12px;
        font-size: 16px;
      }
      .cta-container {
        .MuiButtonBase-root {
          width: 159px;
          margin-right: 36px;
          margin-top: 10px;
        }
      }
    }
  }
  .reviews-overview {
    display: flex;
    align-items: center;
    margin: 26px 0 40px 0;
    .overview {
      margin-right: 35px;
    }
  }
  .reviews-section-container {
    display: flex;
    .separator {
      width: 1px;
      height: 100%;
      background-color: ${COLORS.SECTION_SEPARATOR};
      margin: 0 35px;
    }
    @media screen and ${theme.breakpoints.sm_down} {
      flex-direction: column;
    }
  }

  @media screen and ${theme.breakpoints.mat_xs_down} {
    .image-title-section {
      flex-flow: wrap;
      padding-bottom: 10px;
      .content {
        margin: 20px 0px;
        .cta-container {
          margin-top: 40px;
          display: flex;
          justify-content: space-around;
          .MuiButtonBase-root {
            width: 107px;
            margin-right: 18px;
            .MuiButton-label {
              font-size: 12px;
            }
          }
        }
      }
    }
    .reviews-overview {
      justify-content: space-around;
      margin: 0px 0 40px 0;
      .overview {
        margin-right: 15px;
        flex-direction: column;
      }
    }
  }
`;

export const StyledDescription = styled.p`
  color: ${COLORS.HIGHTLIGHT_DETAIL};
  font-family: ${(props) => props.theme.fonts.montserrat};
  font-size: 16px;
  line-height: 1.56;
  @media screen and ${theme.breakpoints.sm_down} {
    font-size: 12px;
  }
`;

export const ReviewsSectionContainer = styled.div`
  padding-top: 16px;
  flex: 1;
`;

export const StyledSectionTitle = styled.div`
  font-family: ${theme.fonts.montserratsemibold};
  font-size: 18px;
  font-weight: 500;
  color: ${COLORS.PRIMARY_PURPLE};
  @media screen and ${theme.breakpoints.sm_down} {
    font-size: 14px;
  }
`;

export const ActiityStatusBadge = styled.div`
  padding: 8px 16px;
  font-family: ${theme.fonts.maxroundedmedium};
  font-size: 16px;
  font-weight: 500;
  width: 200px;
  margin: 25px 0px;
  color: ${COLORS.PRIMARY_BLACK};
  border-radius: 11px;
  background-color: #e1e4e9;
`;
