import React from "react";
import Layout from "../../components/Layout/Layout";
import ZoomWarning from "../../components/AccountPendingReview/zoom-warning";

const ZoomWarningPage = () => {
  return (
    <Layout isFooterEnable={false}>
      <ZoomWarning />
    </Layout>
  );
};

export default ZoomWarningPage;
