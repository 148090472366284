import React from "react";
import { ActivityDetailWrapper, DetailElement } from "./style";

interface Props {
  label: string;
  value: string;
}

const ActivityDetail: React.FC<Props> = (props: Props) => {
  return (
    <ActivityDetailWrapper>
      <DetailElement type="isLabel" className="noWrap">
        {props.label} : &nbsp;
      </DetailElement>
      <DetailElement>{props.value}</DetailElement>
    </ActivityDetailWrapper>
  );
};

export default ActivityDetail;
