import React from "react";
import Layout from "../../../components/Layout/Layout";
import DashboardLayout from "../../../components/DashBoard/Layout/DashboardLayout";
import { DASHBOARD_NAVS } from "../../../_helper/enums";
import { useParams } from "@reach/router";

function ViewActivityPage({ institute }: { institute: boolean }) {
  const params = useParams();

  return (
    <Layout isFooterEnable={false} background="#f9f9f9">
      <DashboardLayout
        userStep={DASHBOARD_NAVS.CLASSDETAILS}
        id={params.id}
        viewStatus={params.viewStatus}
        institute={institute}
      />
    </Layout>
  );
}

export default ViewActivityPage;
