import styled from "styled-components";
import COLORS from "../../assets/colors";
import theme from "../../assets/theme";
import { Button } from "@material-ui/core";
import { CustomTextField } from "../common/MaterialFields";

interface Props {
  layout?: string;
  fontSize?: string;
  alignItems?: string;
  padding?: string;
  width?: string;
  selected?: boolean;
  colorValue?: string;
  marginValue?: string;
  isBold?: boolean;
  fontFamily?: string;
}

export const CardWrapper = styled.div`
  border-radius: 10px;
  box-shadow: 0 2px 27px 0 rgba(0, 0, 0, 0.06);
  background-color: #ffffff;
  height: 100%;
  & .fabButton {
    background: ${COLORS["PRIMARY_PURPLE"]};
  }

  & .UploadButton-wrapper {
    position: relative;
    border-radius: 50%;
    cursor: pointer;
  }

  & .upload-fabButton {
    position: absolute;
    right: 0px;
    bottom: 15px;
    min-width: 40px;
    max-height: 40px;
    max-width: 40px;
    min-height: 40px;
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

export const List = styled(Row)`
  padding: 20px;
  cursor: pointer;
`;

export const Container = styled.div`
  padding: ${({ padding }: Props) => padding};
  align-items: ${({ alignItems }: Props) => alignItems};
  justify-content: flex-start;
  width: ${({ width }: Props) => (width ? width : "auto")};
  display: flex;
  flex-direction: ${({ layout }: Props) => layout};
  & .end {
    justify-content: flex-end;
  }
  & .center {
    justify-content: center;
  }
  @media screen and ${theme.breakpoints.xs_down} {
    padding: 10px;
  }
`;

export const Font = styled.div<Props>`
  font-family: ${({ isBold }) =>
    isBold ? theme.fonts.maxroundedbold : theme.fonts.maxrounded};
  font-size: ${({ fontSize }: Props) => fontSize};
  line-height: 1.42;
  color: ${({ colorValue }: Props) => colorValue};
`;

export const NavFonts = styled(Font)<Props>`
  font-family: ${({ fontFamily }) => fontFamily}
  &:hover {
    color: #1e2d4f;
  }
`;

export const LoaderPos = styled.div`
  position: absolute;
  top: 0;
  left: 0;
`;

export const ProfileName = styled.div`
  font-family: ${(props) => props.theme.fonts.maxroundedbold};
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.11;
  letter-spacing: 0.75px;
  color: #1e2d4f;
  margin-top: 5px;
`;

export const Heading = styled.div`
  font-family: ${(props) => props.theme.fonts.maxroundedbold};
  font-size: ${({ fontSize }: Props) => (fontSize ? fontSize : "18px")};
  font-weight: 600;
  line-height: 1;
  letter-spacing: 0.83px;
  color: #1e2d4f;
  padding: ${({ padding }: Props) => (padding ? padding : "0px")};
  @media (max-width: 575px) {
    padding: 20px 0px;
  }
  @media screen and ${theme.breakpoints.xs_down} {
    font-size: 18px;
  }
`;

export const ButtonContainer = styled(Row)`
  display: flex;
  height: 45px;
  @media (max-width: 575px) {
    flex: 1;
    justify-content: flex-end;
  }
`;

export const ButtonStyle = styled(Button)`
  font-size: 16px !important;
  font-weight: 500 !important;
  color: #7632ff !important;
  cursor: pointer !important;
  text-transform: none !important;
  font-family: ${(props) => props.theme.fonts.maxroundedmedium} !important;
  &:disabled {
    color: rgba(0, 0, 0, 0.4) !important;
  }
`;

export const FieldsContainer = styled.div`
  margin: 15px 30px;
  width: 360px;
  @media (max-width: 575px) {
    margin: 15px 0px;
    width: 100%;
  }
`;

export const InputStyle = styled(CustomTextField)<any>`
  width: 360px;
  @media (max-width: 768px) {
    width: 290px;
  }
  @media (max-width: 575px) {
    width: 100%;
  }
`;

export const TabContainer = styled(Row)`
  padding: 20px;
  justify-content: center;
  flex: 1;
  background: ${({ selected }: Props) =>
    selected ? "#ffffff" : "transparent"};
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
  position: relative;
  top: 10px;
  cursor: pointer;
`;

export const MobileProfileContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin: 0px 30px;
  @media screen and ${theme.breakpoints.xs_down} {
    margin: 0px;
  }
`;

export const StatusBadge = styled.div`
  padding: 6px 12px;
  border-radius: 5px;
  background-color: rgba(255, 193, 7, 0.19);
  font-family: ${theme.fonts.maxroundedmedium};
  font-size: 16px;
  color: ${COLORS.STATUS_YELLOW};
`;

export const ContentWrapper = styled.div`
  padding: 20px 30px;
  @media screen and ${theme.breakpoints.xs_down} {
    padding: 20px 0px;
  }
`;

export const DialogWrapper = styled.div`
  width: 520px;
  padding: 20px;
  @media (max-width: 640px) {
    width: auto;
  }
`;

export const ContentContainer = styled.div`
  padding: 20px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
