import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { ImageUploadBox } from "./styles";
import { Images } from "../../../assets/images";
import { useMutation } from "react-apollo";
import { GET_S3_URL } from "../../../graphql/mutation";
import Axios from "axios";
import { useSnackbar } from "notistack";
import { ColorCircularProgress } from "../MaterialFields";

interface Props {
  name: string;
  value: string;
}

interface FormikProps {
  setFieldValue: any;
}

const ActivityImageUpload = ({
  name,
  setFieldValue,
  value,
}: Props & FormikProps) => {
  const [fileName, setFileName] = useState<string>("");
  const [callS3UploadUrl] = useMutation(GET_S3_URL);
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState<boolean>(false);
  const onDropAccepted = useCallback((acceptedFiles) => {
    console.log(acceptedFiles, fileName, value);
    handleImageChange(acceptedFiles[0]);
    setFileName(acceptedFiles[0].name);
  }, []);

  const handleImageChange = async (file: any) => {
    setLoading(true);
    callS3UploadUrl({
      variables: {
        input: { fileName: file.name },
      },
    })
      .then((res) => {
        if (res?.data?.generateSignedUrl?.status) {
          let url = res?.data?.generateSignedUrl?.url;
          uploadToAws(url, file);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const uploadToAws = (url: string, file: any) => {
    Axios.put(url, file, {
      headers: {
        "Content-Type": file.type,
      },
    })
      .then((_) => {
        setFieldValue(name, url?.split("?")[0]);
        setLoading(false);
      })
      .catch((err) => {
        setFileName("");
        setFieldValue(name, "");
        enqueueSnackbar("Upload Failed", {
          variant: "error",
          autoHideDuration: 3000,
        });
        setLoading(false);
        console.log("err", err);
      });
  };

  const onDropRejected = useCallback((rejectedFiles) => {
    if (rejectedFiles[0]?.size > 5000000) {
      enqueueSnackbar("File size cannot be greater than 5MB", {
        variant: "error",
        autoHideDuration: 3000,
      });
    } else {
      enqueueSnackbar("Invalid file format", {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDropAccepted,
    onDropRejected,
    accept: "image/*",
    disabled: false,
    maxSize: 5000000,
  });

  return (
    <ImageUploadBox {...getRootProps()}>
      {value && <div className="overlay" />}
      {loading ? (
        <>
          <div className="absolute">
            <ColorCircularProgress size={30} thickness={4} />
          </div>
          <div className={`upload-text ${value ? "white" : ""}`}>
            Uploading...
          </div>
        </>
      ) : (
        <>
          <img
            className={value ? "preview" : "placeholder"}
            src={value ? value : Images.ImageUploadIcon}
          />
          <div className={`upload-text ${value ? "white" : ""}`}>
            {value ? "Change Image" : "Upload Image"}
          </div>
        </>
      )}
      <input {...getInputProps()} name={name} />
    </ImageUploadBox>
  );
};

export default ActivityImageUpload;
