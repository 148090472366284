import COLORS from "../../../assets/colors";
import theme from "../../../assets/theme";
import {
  withStyles,
  Button,
  TextField,
  ButtonProps,
  TextFieldProps,
  RadioGroup,
  RadioGroupProps,
  FormControlLabel,
  Radio,
  InputBaseProps,
  IconButtonProps,
  IconButton,
  FormControlProps,
  FormControl,
  SelectProps,
  Select,
  InputBase,
  MenuItem,
  CircularProgress,
  Dialog,
  Checkbox,
  FormLabel,
} from "@material-ui/core";
import styled from "styled-components";
import { DatePicker, TimePicker } from "@material-ui/pickers";
import React from "react";

export const CustomStyleInput = {
  root: {
    width: "100%",
    "& .MuiFormHelperText-root": {
      fontSize: "12px",
      marginRight: "0px",
      fontFamily: `${theme.fonts.maxrounded}`,
    },
    "& label.Mui-focused": {
      color: COLORS.INPUT_ACTIVE_GREEN,
      fontFamily: "max-rounded-medium",
    },
    "& input": {
      fontSize: "16px",
      fontFamily: "max-rounded-medium",
    },
    "& .MuiSelect-select": {
      fontSize: "16px",
      fontFamily: "max-rounded-medium",
    },
    "& .MuiSelect-outlined": {
      padding: "20px 32px 20px 14px",
    },
    "& label": {
      fontSize: "16px",
      padding: "0px 5px",
      background: "rgba(255,255,255, 1)",
      fontFamily: "max-rounded-medium",
    },
    "& .MuiMenuItem-root": {
      fontSize: "16px",
      fontFamily: "max-rounded-medium",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: COLORS.INPUT_ACTIVE_GREEN,
    },
    "& .MuiOutlinedInput-root": {
      lineHeight: 1,
      "& fieldset": {
        borderRadius: "10px",
      },
      "&.Mui-focused fieldset": {
        borderColor: COLORS.INPUT_ACTIVE_GREEN,
      },
    },
    "& textarea": {
      fontFamily: "max-rounded-medium",
    },
  },
};

const CustomCheckBoxStyle = {
  root: {
    "& .MuiSvgIcon-root": {
      fontSize: `22px !important`,
    },
  },
  checked: {
    color: `${COLORS.INPUT_ACTIVE_GREEN} !important`,
  },
};

const CheckBoxLabelStyles = {
  root: {
    "& .MuiFormControlLabel-label": {
      fontSize: `14px !important`,
      color: "#1e2d4f",
      fontFamily: theme.fonts.maxrounded,
      marginTop: 2,
    },
  },
  disabled: { color: "#a8a8a8 !important" },
};

const FormLabelStyles = {
  root: {
    fontSize: `12px !important`,
    color: "#202f51",
    fontFamily: theme.fonts.montserratsemibold,
  },
};

export const CustomDatePicker = withStyles(CustomStyleInput)(DatePicker);
export const CustomTimePicker = withStyles(CustomStyleInput)(TimePicker);

export const CustomCheckBoxLabel = withStyles(CheckBoxLabelStyles)(
  FormControlLabel
);

export const CustomLabel = withStyles(FormLabelStyles)(FormLabel);

export const CustomTextField = withStyles(CustomStyleInput)(TextField);

export const CustomCheckBox = withStyles(CustomCheckBoxStyle)(Checkbox);

export const MatFileUploadInput = (props: TextFieldProps) => (
  <CustomTextField {...props} fullWidth />
);

// export const LocationSearchInput = (props: TextFieldProps) => (
//   <CustomTextField
//     {...props}
//     fullWidth
//     InputProps={{
//       endAdornment: (
//         <InputAdornment position="end">
//           <img src={Images.SearchIcon} />
//         </InputAdornment>
//       )
//     }}
//   />
// );

export const CCTVRadioGroup = (props: RadioGroupProps) => (
  <StyleRadioGroup aria-label="cctv" {...props}>
    <StyledRadioLabel value="yes" control={<StyledRadio />} label="Yes" />
    <StyledRadioLabel value="no" control={<StyledRadio />} label="No" />
  </StyleRadioGroup>
);

export const StyledRadio = withStyles({
  checked: {
    color: `${COLORS.INPUT_ACTIVE_GREEN} !important`,
  },
})(Radio);

export const StyleRadioGroup = withStyles({
  root: {
    flexDirection: "row",
  },
})(RadioGroup);

export const StyledRadioLabel = withStyles({
  root: {
    "& .MuiSvgIcon-root": {
      fontSize: 20,
    },
  },

  label: {
    fontSize: 16,
    fontWeight: 500,
    fontFamily: theme.fonts.maxroundedmedium,
  },
})(FormControlLabel);

export const PrimaryCTAButton = withStyles({
  root: {
    backgroundColor: COLORS.PRIMARY_PURPLE,
    color: COLORS.PRIMARY_WHITE,
    fontFamily: theme.fonts.maxroundedmedium,
    fontSize: 16,
    textTransform: "none",
    borderRadius: 8,
    padding: "16px 22px",
    border: `1px solid ${COLORS.PRIMARY_PURPLE}`,
    "&:hover": {
      backgroundColor: COLORS.PRIMARY_PURPLE,
      boxShadow: "none",
      borderColor: COLORS.PRIMARY_PURPLE,
      opacity: "0.9",
    },
    "&:active": {
      boxShadow: "none",
      backgroundColor: COLORS.PRIMARY_PURPLE,
      borderColor: COLORS.PRIMARY_PURPLE,
    },
    "&:focus": {
      boxShadow: "none",
      borderColor: COLORS.PRIMARY_PURPLE,
    },
  },
  label: {
    lineHeight: 1,
  },
  disabled: {
    borderColor: "rgba(0, 0, 0, 0.26)",
  },
})((props: ButtonProps) => (
  <Button {...props} variant="contained" color="primary" disableElevation />
));

export const SecondaryCTAButton = withStyles({
  root: {
    backgroundColor: COLORS.PRIMARY_WHITE,
    color: COLORS.PRIMARY_PURPLE,
    fontFamily: theme.fonts.maxroundedmedium,
    fontSize: 16,
    textTransform: "none",
    borderRadius: 8,
    padding: "16px 22px",
    borderColor: COLORS.PRIMARY_PURPLE,
    "&:hover": {
      boxShadow: "none",
      borderColor: COLORS.PRIMARY_PURPLE,
      opacity: "0.9",
    },
    "&:active": {
      boxShadow: "none",
      borderColor: COLORS.PRIMARY_PURPLE,
    },
    "&:focus": {
      boxShadow: "none",
      borderColor: COLORS.PRIMARY_PURPLE,
    },
    "& a": {
      textDecoration: "none",
      color: COLORS.PRIMARY_PURPLE,
    },
  },
  disabled: {
    borderColor: "rgba(0, 0, 0, 0.26)",
  },
  label: {
    lineHeight: 1,
  },
})((props: ButtonProps) => (
  <Button {...props} variant="outlined" disableElevation />
));

export const NegativeCTAButton = withStyles({
  root: {
    backgroundColor: COLORS.PRIMARY_WHITE,
    color: COLORS.NEGATIVE_COLOR,
    fontFamily: theme.fonts.maxroundedmedium,
    fontSize: 16,
    textTransform: "none",
    borderRadius: 8,
    padding: "16px 22px",
    borderColor: COLORS.NEGATIVE_COLOR,
    "&:hover": {
      boxShadow: "none",
      borderColor: COLORS.NEGATIVE_COLOR,
      opacity: "0.9",
    },
    "&:active": {
      boxShadow: "none",
      borderColor: COLORS.NEGATIVE_COLOR,
    },
    "&:focus": {
      boxShadow: "none",
      borderColor: COLORS.NEGATIVE_COLOR,
    },
  },
  disabled: {
    borderColor: "rgba(0, 0, 0, 0.26)",
  },
  label: {
    lineHeight: 1,
  },
})((props: ButtonProps) => (
  <Button {...props} variant="outlined" disableElevation />
));

export const SnackbarButton = withStyles({
  root: {
    color: COLORS.PRIMARY_WHITE,
  },
})((props: ButtonProps) => <Button {...props} />);

export const FilterIconBtn = withStyles({
  root: {
    padding: "14px 32px",
    backgroundColor: COLORS.ICON_BTN_BG,
    borderRadius: 10,
    height: "100%",
    margin: 10,
    "&:hover": {},
    "&:active": {},
    "&:focus": {},
  },
})((props: IconButtonProps) => <IconButton {...props} />);

export const ActivitiesSortFormControl = withStyles({
  root: {
    margin: 10,
  },
})((props: FormControlProps) => <FormControl {...props} />);

export const SortActivitiesSelect = withStyles({
  root: {
    padding: "15px 18px",
    paddingRight: "15px !important",
    paddingLeft: 82,
  },
  icon: {
    display: "none",
  },
})((props: SelectProps) => <Select {...props} input={<SortInputBase />} />);

const SelectInputBaseWrapper = styled.div`
  position: relative;
  display: flex;
  .label {
    position: absolute;
    top: 15px;
    left: 15px;
    z-index: 10;
    pointer-events: none;
    font-family: ${(props) => props.theme.fonts.maxrounded};
    font-size: 16px;
  }
`;

const SortInputBase = withStyles({
  root: {
    fontSize: 16,
    fontFamily: theme.fonts.maxroundedmedium,
    backgroundColor: COLORS.ICON_BTN_BG,
    borderRadius: 10,
  },
  input: {
    "&:focus": {},
  },
})((props: InputBaseProps) => (
  <SelectInputBaseWrapper>
    <div className="label">Sort By:</div>
    <InputBase {...props} />
  </SelectInputBaseWrapper>
));

export const StyledMenuItem = withStyles({
  root: {
    fontFamily: theme.fonts.maxrounded,
  },
  selected: {
    fontFamily: theme.fonts.maxroundedmedium,
  },
})(MenuItem);

export const ColorCircularProgress = withStyles({
  root: {
    color: "#00ada8",
  },
})(CircularProgress);

export const CustomDialog = withStyles({
  paper: {
    maxWidth: 800,
  },
})(Dialog);
