import styled from "styled-components";

export const SelectTutorWrapper = styled.div`
min-height: 80vh;
display: flex;
align-items: center;
justify-content: center;
@media (max-width: 600px) {
  align-items: flex-start;
}
`;