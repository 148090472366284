import styled from "styled-components";
import theme from "../../../assets/theme";
import COLORS from "../../../assets/colors";

interface Props {
  fontSize?: string;
}

export const StyledTutorInfo = styled.div`
  display: flex;
  align-items: center;
`;

export const TutorName = styled.div<Props>`
  font-family: ${theme.fonts.maxroundedmedium};
  font-size: ${({ fontSize }) => (fontSize ? fontSize : "12px")};
  color: ${COLORS.HIGHTLIGHT_DETAIL};
  margin: 0 8px 0 4px;
  @media screen and ${theme.breakpoints.sm_down} {
    font-size: 12px;
  }
`;
