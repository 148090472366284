import React from "react";
import styled from "styled-components";

interface Props {
  src: string;
  width?: string;
  height?: string;
}

const Avatar = styled.img`
  width: ${(props: Props) => props.width};
  height: ${(props: Props) => props.height};
  min-width: ${(props: Props) => props.width};
  max-height: ${(props: Props) => props.height};
  border-radius: 50%;
`;

const index: React.FC<Props> = (props: Props) => {
  const { src } = props;
  return <Avatar src={src} {...props} />;
};

export default index;
