import React from "react";
import Layout from "../../../components/Layout/Layout";
import CreateActivity from "../../../components/CreateActivity";
import { useParams } from "@reach/router";

function EditDraftActivityPage() {
  const params = useParams();

  return (
    <Layout isFooterEnable={false}>
      <CreateActivity
        userType="individual_tutor"
        id={params?.id ?? null}
        mode="draft"
      />
    </Layout>
  );
}

export default EditDraftActivityPage;
