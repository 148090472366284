import { FormControl, withStyles } from "@material-ui/core";
import { CustomStyleInput } from "../../../common/MaterialFields";

export const StyledSelectFormControl = withStyles({
  ...CustomStyleInput,
  root: {
    ...CustomStyleInput.root,
    width: "100%"
  }
})(FormControl);
