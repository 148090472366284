import { useContext } from "react";
import { LanguageContext } from "../Context/LanguageContext";

export default function useLang() {
  const context = useContext(LanguageContext);
  if (context === undefined) {
    throw new Error("useLang must be used within a LangProvider");
  }
  return context;
}
