import React from "react";
import InstituteDetailsForm from "./steps/InstituteDetailsForm";
import { FORM_STATES } from "../../_helper/enums";
import EnterOTP from "../TutorRegistrationForm/steps/EnterOTP";

interface Props {
  userStep: string;
  institute?: boolean;
}

const InstituteRegistrationForm: React.FC<Props> = ({
  userStep,
  institute
}) => {
  return (
    <div>
      {userStep === FORM_STATES.INSTITUTE_DETAILS && <InstituteDetailsForm />}
      {userStep === FORM_STATES.OTP && <EnterOTP institute={institute} />}
    </div>
  );
};

export default InstituteRegistrationForm;
