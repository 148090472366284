import React, { useState, useEffect } from "react";
import OnboardingFormContainer from "../../common/OnboardingFormContainer";
import {
  FieldContainer,
  StyledTextInput,
  SubmitBtnContainer,
  RadioLegend,
} from "../../common/OnboardingFormContainer/styles";
import {
  PrimaryCTAButton,
  CCTVRadioGroup,
  ColorCircularProgress,
} from "../../common/MaterialFields";
import FileUploadInput from "../../common/FileUploadInput";
import { Formik, Form, Field } from "formik";
import { upadateTutorSchema } from "../../../_helper/ValidationSchema";
import { UPDATE_TUTOR } from "../../../graphql/mutation";
import { useMutation, useQuery } from "react-apollo";
import { useSnackbar } from "notistack";
import { GET_TUTOR_INFO } from "../../../graphql/queries";
import { User } from "../../../store";
import Cookie from "js-cookie";
import { navigate } from "@reach/router";

interface Props {}

const CompleteKYC: React.FC<Props> = () => {
  const [panFile, setPanFile] = useState<string>("");
  const [ppFile, setPpfile] = useState<string>("");
  const [callUpdateTutor, { loading }] = useMutation(UPDATE_TUTOR);
  const { enqueueSnackbar } = useSnackbar();
  const ProfileInfoResponse = useQuery(GET_TUTOR_INFO, {
    fetchPolicy: "no-cache",
  });

  const [initialValue, setInitialValue] = useState<any>({
    firstName: "",
    email: "",
    phoneNumber: "",
    address: "",
    location: "",
    pan: "",
    pp_size_photo: "",
    cctv: "no",
  });

  /**
   * @description This effect for set the initial value. it calls when the inital get and update call is triggered
   */
  useEffect(() => {
    if (ProfileInfoResponse?.data) {
      const { me: Tutor } = ProfileInfoResponse?.data;
      setInitialValue(getInitialValues(Tutor));
    }
  }, [ProfileInfoResponse.data]);

  /**
   * @description Set the initial value for form
   * @param data
   */
  const getInitialValues = (data: any) => {
    return {
      firstName: data?.firstName ?? "",
      email: data?.email ?? "",
      phoneNumber: data?.phoneNumber ?? "",
      address: data?.address ?? "",
      location: "",
      pan: "",
      pp_size_photo: "",
      cctv: "no",
    };
  };

  const onFormSubmit = (values: any) => {
    let postObj: any = {};
    postObj.firstName = values.firstName;
    postObj.phoneNumber = values.phoneNumber;
    postObj.email = values.email;
    postObj.address = values.address;
    postObj.locationName = values.location;
    postObj.cctvAvailability = values.cctv === "yes" ? true : false;
    postObj.geoLat = "0.73";
    postObj.geoLng = "0.67";
    postObj.kycDocuments = [];
    postObj.kycDocuments.push(panFile);
    postObj.kycDocuments.push(ppFile);

    callUpdateTutor({
      variables: {
        input: postObj,
      },
    })
      .then((res) => {
        console.log(loading);
        if (res?.data?.updateTutor?.status) {
          enqueueSnackbar(res.data.updateTutor.message, {
            variant: "success",
            autoHideDuration: 3000,
          });
          User.setToken(res?.data?.updateTutor?.token);
          Cookie.set("vendor_token", res?.data?.updateTutor?.token);
          navigate("/tutor/account-pending-review");
        }
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <OnboardingFormContainer title="Complete KYC">
      {ProfileInfoResponse?.loading ? (
        <div className="flex center-center h50p">
          <ColorCircularProgress size={50} thickness={5} />
        </div>
      ) : (
        <Formik
          isInitialValid={false}
          onSubmit={onFormSubmit}
          validationSchema={upadateTutorSchema}
          initialValues={initialValue}
          enableReinitialize={true}
        >
          {({
            handleSubmit,
            handleChange,
            touched,
            errors,
            setFieldValue,
            isValid,
          }) => (
            <Form onSubmit={handleSubmit}>
              <FieldContainer>
                <Field
                  disabled={true}
                  error={touched.firstName && errors.firstName}
                  autoFocus={true}
                  helperText={touched.firstName && errors.firstName}
                  name="firstName"
                  onChange={handleChange}
                  id="outlined-basic"
                  label="Name"
                  variant="outlined"
                  as={StyledTextInput}
                />
              </FieldContainer>
              <FieldContainer>
                <Field
                  disabled={true}
                  error={touched.phoneNumber && errors.phoneNumber}
                  helperText={touched.phoneNumber && errors.phoneNumber}
                  name="phoneNumber"
                  onChange={handleChange}
                  id="outlined-basic"
                  label="Phone Number"
                  variant="outlined"
                  as={StyledTextInput}
                />
              </FieldContainer>
              <FieldContainer>
                <Field
                  disabled={true}
                  error={touched.email && errors.email}
                  helperText={touched.email && errors.email}
                  name="email"
                  onChange={handleChange}
                  id="outlined-basic"
                  label="Email"
                  variant="outlined"
                  as={StyledTextInput}
                />
              </FieldContainer>
              <FieldContainer>
                <Field
                  disabled={true}
                  error={touched.address && errors.address}
                  helperText={touched.address && errors.address}
                  name="address"
                  onChange={handleChange}
                  id="outlined-basic"
                  label="Proof of Address"
                  variant="outlined"
                  as={StyledTextInput}
                />
              </FieldContainer>
              <FieldContainer>
                <Field
                  error={touched.location && errors.location}
                  helperText={touched.location && errors.location}
                  name="location"
                  onChange={handleChange}
                  id="outlined-basic"
                  label="Location"
                  variant="outlined"
                  as={StyledTextInput}
                />
              </FieldContainer>
              <FieldContainer>
                <Field
                  error={errors.pan && touched.pan ? true : false}
                  helperText={touched.pan && errors.pan ? errors.pan : ""}
                  name="pan"
                  onChange={(url: string) => setPanFile(url)}
                  label="PAN Card Picture"
                  setFieldValue={setFieldValue}
                  as={FileUploadInput}
                />
              </FieldContainer>
              <FieldContainer>
                <Field
                  error={
                    touched.pp_size_photo && errors.pp_size_photo ? true : false
                  }
                  helperText={
                    touched.pp_size_photo && errors.pp_size_photo
                      ? errors.pp_size_photo
                      : ""
                  }
                  name="pp_size_photo"
                  onChange={(url: string) => setPpfile(url)}
                  label="Clear Passport Size Photo"
                  setFieldValue={setFieldValue}
                  as={FileUploadInput}
                />
              </FieldContainer>
              <FieldContainer>
                <RadioLegend>
                  Do you have CCTV camera inside the premises?
                </RadioLegend>
                <Field
                  error={touched.cctv && errors.cctv}
                  name="cctv"
                  onChange={handleChange}
                  as={CCTVRadioGroup}
                />
              </FieldContainer>
              <SubmitBtnContainer>
                <PrimaryCTAButton
                  disabled={!isValid || !panFile || !ppFile || loading}
                  type="submit"
                  style={{ maxWidth: 360, width: "100%" }}
                >
                  Send for Approval
                </PrimaryCTAButton>
              </SubmitBtnContainer>
            </Form>
          )}
        </Formik>
      )}
    </OnboardingFormContainer>
  );
};

export default CompleteKYC;
