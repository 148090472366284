import React, { useEffect, useState } from "react";
import OnboardingFormContainer from "../common/OnboardingFormContainer";
import { useMutation } from "react-apollo";
import { useSnackbar } from "notistack";
import jwt_decode from "jwt-decode";
import { User } from "../../store";
import Cookie from "js-cookie";
import { Images } from "../../assets/images";
import { navigate, useLocation, useParams } from "@reach/router";
import { KYC_VERIFY } from "../../graphql/mutation";
import useAuth from "../../hooks/useAuth";
interface Props {
  institute?: boolean;
  kycNotVerified?: boolean;
  id?: string;
}

const AccountVerifying: React.FC<Props> = () => {
  const [fetchKyc] = useMutation(KYC_VERIFY);
  const { enqueueSnackbar } = useSnackbar();
  const [data, setData] = useState<any>();
  const token = User.getToken();
  const location = useLocation();
  const { setToken } = useAuth();
  const searchParams = new URLSearchParams(location.search);
  const param = useParams();

  useEffect(() => {
    setData(searchParams.get("status"));
    if (searchParams.get("status") === "SUCCESS") {
      fetchKyc({
        variables: {
          input: {
            uuid: searchParams.get("uuid"),
            hash: searchParams.get("hash"),
            tutorId: param?.id ? Number(param?.id) : undefined,
          },
        },
      })
        .then((res) => {
          if (res.data.fetchKyc.status) {
            enqueueSnackbar(res.data.fetchKyc.message, {
              variant: "success",
              autoHideDuration: 3000,
              onExiting: () => {
                redirectToPage();
              },
            });
          }
        })
        .catch((err) => {
          enqueueSnackbar(err?.graphQLErrors[0]?.message, {
            variant: "error",
            autoHideDuration: 3000,
            onExiting: () => {
              redirectToPage();
            },
          });
        });
    } else {
      enqueueSnackbar("KYC verification Failed", {
        variant: "error",
        autoHideDuration: 3000,
        onExiting: () => {
          redirectToPage();
        },
      });
    }
  }, []);

  const redirectToPage = () => {
    if (token) {
      let tokenObj: any = jwt_decode(token ? token : "");
      let mode = tokenObj?.claims?.entity;
      if (mode === "Tutor") {
        User.clearToken().then(() => setToken(""));
        Cookie.remove("vendor_token");
        navigate("/tutor/signin");
      } else {
        navigate(`/institute/dashboard/tutor`);
      }
    }
  };

  return (
    <div style={{ height: "50vh" }} className="flex center-center">
      {data && (
        <OnboardingFormContainer
          title={`${
            data === "SUCCESS"
              ? "KYC Verification Completed"
              : "KYC Verification Failed"
          }`}
        >
          {data === "SUCCESS" ? (
            <img src={Images.verified} />
          ) : (
            <img src={Images.failed} />
          )}
          <div className="mt20">Redirecting...</div>
        </OnboardingFormContainer>
      )}
    </div>
  );
};

export default AccountVerifying;
