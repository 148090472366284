import { Images } from "../assets/images";

export const navLinksTutor = [
  {
    name: "My Account",
    imgSrc: `${Images.person}`,
    imgBlack: `${Images.personBlack}`,
    key: "MY_ACC",
    href: "/tutor/dashboard/my-account",
  },
  {
    name: "My Classes",
    imgSrc: `${Images.list}`,
    imgBlack: `${Images.listBlack}`,
    key: "MY_CLASSES",
    href: "/tutor/dashboard/my-classes",
  },
  {
    name: "Reviews and Ratings",
    imgSrc: `${Images.star}`,
    imgBlack: `${Images.starBlack}`,
    key: "REVIEW_RATINGS",
    href: "/tutor/dashboard/review-rating",
  },
  {
    name: "Support",
    imgSrc: `${Images.chat}`,
    imgBlack: `${Images.chatBlack}`,
    key: "SUPPORT",
    href: "mailto:connect@activkido.com",
  },
  {
    name: "Logout",
    imgSrc: `${Images.switch}`,
    key: "LOGOUT",
    href: "/tutor/dashboard/review-rating",
  },
];

export const navLinksInstitute = [
  {
    name: "My Account",
    imgSrc: `${Images.person}`,
    imgBlack: `${Images.personBlack}`,
    key: "MY_ACC",
    href: "/institute/dashboard/my-account",
  },
  {
    name: "My Classes",
    imgSrc: `${Images.list}`,
    imgBlack: `${Images.listBlack}`,
    key: "MY_CLASSES",
    href: "/institute/dashboard/my-classes",
  },
  {
    name: "Tutors",
    imgSrc: `${Images.group}`,
    imgBlack: `${Images.groupBlack}`,
    key: "TUTOR",
    href: "/institute/dashboard/tutor",
  },
  {
    name: "Reviews and Ratings",
    imgSrc: `${Images.star}`,
    imgBlack: `${Images.starBlack}`,
    key: "REVIEW_RATINGS",
    href: "/institute/dashboard/review-rating",
  },
  {
    name: "Logout",
    imgSrc: `${Images.switch}`,
    key: "LOGOUT",
    href: "/institute/dashboard/review-rating",
  },
];
