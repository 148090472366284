import React from "react";
import Layout from "../../components/Layout/Layout";
import { FORM_STATES } from "../../_helper/enums";
import TutorRegistrationForm from "../../components/TutorRegistrationForm";

function TutorRegistration() {
  const userStep = FORM_STATES.BASIC;

  return (
    <Layout isFooterEnable={false}>
      <TutorRegistrationForm userStep={userStep} />
    </Layout>
  );
}

export default TutorRegistration;
