import React from "react";
import { Formik, Form, Field } from "formik";
import { useMutation } from "react-apollo";
import { useSnackbar } from "notistack";
import { Hidden, Dialog } from "@material-ui/core";
import { REPORT_PARENT } from "../../../graphql/mutation";
import { PrimaryCTAButton } from "../../common/MaterialFields";
import { DialogWrapper, ContentContainer, FieldsContainer } from "../style";
import DialogHeader from "../../DialogHeader";
import { StyledTextInput } from "../../common/OnboardingFormContainer/styles";
import { reportIssueSchema } from "../../../_helper/ValidationSchema";

interface Props {
  setShowReportDialog: any;
  showReportDialog: any;
}

const ReportParent: React.FC<Props> = ({
  setShowReportDialog,
  showReportDialog,
}) => {
  const [createReport, { loading }] = useMutation(REPORT_PARENT);
  const { enqueueSnackbar } = useSnackbar();

  const onFormSubmit = (values: any) => {
    createReport({
      variables: {
        input: {
          reason: values.issue,
          reportedEntityType: "Parent",
          id: showReportDialog?.id,
        },
      },
    })
      .then((res) => {
        if (res.data.createReport.status) {
          enqueueSnackbar(res.data.createReport.message, {
            variant: "success",
            autoHideDuration: 3000,
          });
        }
        setShowReportDialog({});
      })
      .catch((err) => {
        enqueueSnackbar(err.graphQLErrors[0].message, {
          variant: "error",
          autoHideDuration: 3000,
        });
      });
  };

  return (
    <Formik
      initialValues={{ issue: "" }}
      onSubmit={onFormSubmit}
      validationSchema={reportIssueSchema}
      isInitialValid={false}
      render={({ handleSubmit, errors, touched, isValid }) => (
        <Dialog
          open={showReportDialog?.show}
          onClose={() => setShowReportDialog({})}
        >
          <Form onSubmit={handleSubmit}>
            <DialogWrapper>
              <Hidden implementation="css" xsDown>
                <DialogHeader
                  triggerClose={() => setShowReportDialog({})}
                  showHeader={true}
                  heading="Report Parent"
                />
              </Hidden>

              <Hidden implementation="css" smUp>
                <DialogHeader
                  triggerClose={() => setShowReportDialog({})}
                  showLogo={false}
                  showHeader={true}
                />
              </Hidden>

              <p style={{ textAlign: "center", marginTop: 20 }}>
                Please brief us about what went wrong.
              </p>

              <ContentContainer>
                <FieldsContainer>
                  <Field
                    style={{ fontSize: 20 }}
                    error={errors.issue && touched.issue}
                    helperText={touched.issue && errors.issue}
                    autoFocus={true}
                    name="issue"
                    id="outlined-basic"
                    placeholder="Type your issue here"
                    label="Issue"
                    variant="outlined"
                    multiline
                    rows={6}
                    as={StyledTextInput}
                  />
                </FieldsContainer>
              </ContentContainer>
              <div className="flex center-center">
                <FieldsContainer>
                  <PrimaryCTAButton
                    type="submit"
                    disabled={!isValid || loading}
                    style={{ width: "100%" }}
                  >
                    Submit
                  </PrimaryCTAButton>
                </FieldsContainer>
              </div>
            </DialogWrapper>
          </Form>
        </Dialog>
      )}
    />
  );
};

export default ReportParent;
