import React, { useEffect } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SelectTutor from "../../components/SelectTutor";
import { SelectTutorWrapper } from "./styles";
import Layout from "../Layout/Layout";
import jwt_decode from "jwt-decode";
import { navigate } from "@reach/router";

interface Props {
  token: any;
}

const Home: React.FC<Props> = ({ token }) => {
  useEffect(() => {
    if (token) {
      const userData: any = jwt_decode(token);
      if (userData.claims.entity === "Tutor") {
        navigate("/tutor/dashboard/my-classes");
      } else {
        navigate("/institute/dashboard/my-classes");
      }
    }
  }, [token]);
  return (
    <Layout>
      <div className="flex column">
        <SelectTutorWrapper>
          <SelectTutor />
        </SelectTutorWrapper>
      </div>
    </Layout>
  );
};

export default Home;
