import React, { useCallback, useState, useEffect } from "react";
import PhotoCameraIcon from "@material-ui/icons/PhotoCamera";
import { Fab } from "@material-ui/core";
import { useMutation, useQuery } from "react-apollo";
import { useDropzone } from "react-dropzone";
import axios from "axios";
import { useSnackbar } from "notistack";
import Avatar from "../../common/Avatar";
import { Images } from "../../../assets/images";
import { LoaderPos } from "../style";
import {
  GET_S3_URL,
  UPDATE_INSTITUTE,
  UPDATE_TUTOR,
} from "../../../graphql/mutation";
import { ColorCircularProgress } from "../../common/MaterialFields";
import {
  GET_INSTITUEINFO_INFO,
  GET_TUTOR_INFO,
} from "../../../graphql/queries";

interface Props {
  institute: boolean;
}

const MyprofileName: React.FC<Props> = ({ institute }) => {
  const [callS3UploadUrl] = useMutation(GET_S3_URL);
  const [getMe, setMe] = useState<any>();
  const [updateProfile] = useMutation(
    institute ? UPDATE_INSTITUTE : UPDATE_TUTOR
  );
  const getMeRes = useQuery(
    institute ? GET_INSTITUEINFO_INFO : GET_TUTOR_INFO,
    {
      fetchPolicy: "no-cache",
    }
  );
  const [loading, setLoading] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();
  const onDropAccepted = useCallback((acceptedFiles) => {
    // Do something with the fileso
    console.log(isDragActive);
    handleImageChange(acceptedFiles[0]);
  }, []);

  useEffect(() => {
    if (getMeRes?.data?.me) {
      setMe(getMeRes?.data?.me);
    }
  }, [getMeRes.data]);

  const onDropRejected = useCallback((rejectedFiles) => {
    if (rejectedFiles[0]?.size > 5000000) {
      enqueueSnackbar("File size cannot be greater than 5MB", {
        variant: "error",
        autoHideDuration: 3000,
      });
    } else {
      enqueueSnackbar("Invalid file format", {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDropAccepted,
    onDropRejected,
    accept: "image/*",
    disabled: loading,
    maxSize: 5000000,
  });

  const handleImageChange = async (file: any) => {
    setLoading(true);
    callS3UploadUrl({
      variables: {
        input: { fileName: file.name },
      },
    })
      .then((res) => {
        if (res?.data?.generateSignedUrl?.status) {
          let url = res?.data?.generateSignedUrl?.url;
          uploadToAws(url, file);
        }
      })
      .catch((err) => {
        enqueueSnackbar("Upload Failed", {
          variant: "error",
          autoHideDuration: 3000,
        });
        setLoading(false);
        console.log(err);
      });
  };

  const uploadToAws = (url: string, file: any) => {
    axios
      .put(url, file, {
        headers: {
          "Content-Type": file.type,
        },
      })
      .then((_) => {
        uploadUrlToQraphql(url);
      })
      .catch((err) => {
        setLoading(false);
        console.log("err", err);
      });
  };

  const uploadUrlToQraphql = (url: string) => {
    updateProfile({
      variables: {
        input: {
          avatarUrl: url?.split("?")[0],
        },
      },
    })
      .then((res) => {
        if (
          res?.data[institute ? "updateInstitution" : "updateTutor"]?.status
        ) {
          enqueueSnackbar(
            res?.data[institute ? "updateInstitution" : "updateTutor"]?.message,
            {
              variant: "success",
              autoHideDuration: 3000,
            }
          );
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        enqueueSnackbar(err.graphQLErrors[0].message, {
          variant: "error",
          autoHideDuration: 3000,
        });
      });
  };
  return (
    <>
      <div className="UploadButton-wrapper outlineNone" {...getRootProps()}>
        <Avatar
          src={
            getMe?.avatarUrl ? getMe?.avatarUrl : Images.userUploadPlaceHolder
          }
          width="127px"
          height="127px"
        />
        {loading && (
          <LoaderPos>
            <ColorCircularProgress size={127} thickness={3} />
          </LoaderPos>
        )}
        <input accept="image/*" className="outlineNone" {...getInputProps()} />
        {!loading && (
          <Fab
            type="button"
            className="upload-fabButton fabButton"
            color="primary"
            aria-label="edit"
          >
            <PhotoCameraIcon />
          </Fab>
        )}
      </div>
      {/* <div className="pl15">
        <Font fontSize="12px" colorValue="#8f96a7">
          Hey
        </Font>
        <ProfileName>{getMe?.firstName}</ProfileName>
      </div> */}
    </>
  );
};

export default MyprofileName;
