import React, { useState, useEffect } from "react";
import {
  FieldsContainer,
  Heading,
  InputStyle,
  ButtonContainer,
  ButtonStyle,
} from "../style";
import { Formik, Form, Field } from "formik";
import { InputAdornment, Fab } from "@material-ui/core";
import { useLazyQuery, useMutation, useQuery } from "react-apollo";
import {
  GET_TUTOR_INFO,
  GET_INSTITUEINFO_INFO,
  GET_CATEGORIES,
} from "../../../graphql/queries";
import { UPDATE_TUTOR, UPDATE_INSTITUTE } from "../../../graphql/mutation";
import { useSnackbar } from "notistack";
import _ from "lodash";
import CustomSelectField from "../../CreateActivity/Fields/CustomSelectField";
import EditIcon from "@material-ui/icons/Edit";
import { Images } from "../../../assets/images";
import { updateProfileSchemaInMyaccount } from "../../../_helper/ValidationSchema";
import ProfileContainer from "../Layout/ProfileContainer";

interface Props {
  setIsEdit: React.Dispatch<React.SetStateAction<boolean>>;
  institute?: boolean;
  isEdit?: boolean;
}

const Index: React.FC<Props> = ({ setIsEdit, institute, isEdit }) => {
  // const { use } = useAuth();
  const [getTutorInformation, tutorRes] = useLazyQuery(GET_TUTOR_INFO, {
    fetchPolicy: "no-cache",
  });
  const [getInsituteInformation, insRes] = useLazyQuery(GET_INSTITUEINFO_INFO, {
    fetchPolicy: "no-cache",
  });
  const [callUpadateTutor, updateTutorRes] = useMutation(UPDATE_TUTOR);
  const { enqueueSnackbar } = useSnackbar();
  const CategoriesResponse = useQuery(GET_CATEGORIES);
  const [callUpadateInstitute, updateIns] = useMutation(UPDATE_INSTITUTE);
  const [categories, setCategories] = useState([]);
  const [instituteInitialValue, setInstituteInitialValue] = useState<any>({
    firstName: "",
    category: "",
    phoneNumber: "",
    email: "",
    alternatePhoneNumber: "",
    address: "",
    panNumber: "",
    overview: "",
    certification: "",
    awards: "",
  });
  const [tutorInitialValue, setTutorInitialValue] = useState<any>({
    firstName: "",
    category: "",
    email: "",
    phoneNumber: "",
    alternatePhoneNumber: "",
    address: "",
    panNumber: "",
    overview: "",
    education: "",
    certification: "",
    totalExperience: "",
    awards: "",
  });

  useEffect(() => {
    if (institute) {
      getInsituteInformation();
    } else {
      getTutorInformation();
    }
  }, []);

  useEffect(() => {
    if (institute && insRes?.data) {
      const { me: Institution } = insRes?.data;
      setInstituteInitialValue(getInstituteInitialValues(Institution));
    } else if (tutorRes?.data) {
      const { me: Tutor } = tutorRes?.data;
      setTutorInitialValue(getTutorInitialValues(Tutor));
    }
  }, [tutorRes.data, insRes.data]);

  /**
   * @description Set the initial value for form
   * @param data
   */
  const getInstituteInitialValues = (data: any) => {
    return {
      firstName: data?.firstName ?? "",
      lastName: data?.lastName ?? "",
      phoneNumber: data?.phoneNumber ?? "",
      alternatePhoneNumber: data?.alternatePhoneNumber ?? "",
      address: data?.address ?? "",
      email: data?.email ?? "",
      panNumber: data?.panNumber ?? "",
      overview: data?.overview ?? "",
      certification: data?.certification ?? "",
      awards: data?.awards ?? "",
      allowedCategories: data?.allowedCategories
        ? setAllowedCategories(data?.allowedCategories)
        : "",
    };
  };

  const getTutorInitialValues = (data: any) => {
    return {
      firstName: data?.firstName ?? "",
      lastName: data?.lastName ?? "",
      email: data?.email ?? "",
      phoneNumber: data?.phoneNumber ?? "",
      alternatePhoneNumber: data?.alternatePhoneNumber ?? "",
      address: data?.address ?? "",
      panNumber: data?.panNumber ?? "",
      overview: data?.overview ?? "",
      certification: data?.certification ?? "",
      awards: data?.awards ?? "",
      education: data.education ?? "",
      totalExperience: data?.totalExperience ?? "",
      allowedCategories: data?.allowedCategories
        ? setAllowedCategories(data?.allowedCategories)
        : "",
    };
  };

  const setAllowedCategories = (categories: any) => {
    return categories?.nodes.map((list: any) => list.category.id);
  };

  useEffect(() => {
    if (!_.isEmpty(CategoriesResponse.data)) {
      if (CategoriesResponse.data?.categories?.nodes) {
        setCategories(CategoriesResponse.data.categories.nodes);
      }
    }
  }, [CategoriesResponse.data]);

  const updateTutor = (postObj: any, resetForm: any) => {
    callUpadateTutor({
      variables: {
        input: postObj,
      },
    })
      .then((res) => {
        if (res?.data?.updateTutor?.status) {
          enqueueSnackbar(res.data.updateTutor.message, {
            variant: "success",
            autoHideDuration: 3000,
          });
          resetForm();
          setIsEdit(false);
        }
      })
      .catch((err) => {
        setIsEdit(false);
        enqueueSnackbar(err.graphQLErrors[0].message, {
          variant: "error",
          autoHideDuration: 3000,
        });
      });
  };

  const updateInstitue = (postObj: any, resetForm: any) => {
    callUpadateInstitute({
      variables: {
        input: postObj,
      },
    })
      .then((res) => {
        if (res?.data?.updateInstitution?.status) {
          enqueueSnackbar(res.data.updateInstitution.message, {
            variant: "success",
            autoHideDuration: 3000,
          });
          setIsEdit(false);
          resetForm();
        }
      })
      .catch((err) => {
        enqueueSnackbar(err.graphQLErrors[0].message, {
          variant: "error",
          autoHideDuration: 3000,
        });
      });
  };

  const onFormSubmit = (values: any, { resetForm }: any) => {
    let postObj: any = {};
    postObj.alternatePhoneNumber = values.alternatePhoneNumber;
    postObj.allowedCategories = values.allowedCategories;
    postObj.overview = values.overview;
    postObj.certification = values.certification;
    postObj.awards = values.awards;
    if (institute) {
      updateInstitue(postObj, resetForm);
    } else {
      updateTutor(postObj, resetForm);
      postObj.education = values.education;
      postObj.totalExperience = values.totalExperience;
    }
  };

  return (
    <div>
      {/* Personal Infomation */}
      <Formik
        initialValues={institute ? instituteInitialValue : tutorInitialValue}
        isInitialValid={false}
        enableReinitialize={true}
        onSubmit={onFormSubmit}
        validationSchema={updateProfileSchemaInMyaccount}
        render={({
          touched,
          errors,
          handleChange,
          handleSubmit,
          resetForm,
          values,
        }) => (
          <Form onSubmit={handleSubmit}>
            {/* Your Information Section */}
            <div>
              <Heading
                className="flex between-center"
                fontSize="20px"
                padding="30px"
              >
                <div>Your Information</div>
                {isEdit ? (
                  <ButtonContainer>
                    <ButtonStyle
                      disabled={updateTutorRes.loading || updateIns.loading}
                      disableRipple={true}
                      type="submit"
                      className="mt5 ml15"
                    >
                      Save
                    </ButtonStyle>
                    <ButtonStyle
                      disabled={updateTutorRes.loading || updateIns.loading}
                      type="button"
                      className="mt5"
                      disableRipple={true}
                      onClick={(e) => {
                        e.preventDefault();
                        setIsEdit(false);
                        resetForm();
                      }}
                    >
                      Cancel
                    </ButtonStyle>
                  </ButtonContainer>
                ) : (
                  <ButtonContainer>
                    <Fab
                      type="button"
                      className="mt5 ml15 h30 w35 fabButton"
                      color="primary"
                      aria-label="edit"
                      onClick={(e) => {
                        e.preventDefault();
                        setIsEdit(true);
                      }}
                    >
                      <EditIcon />
                    </Fab>
                  </ButtonContainer>
                )}
              </Heading>
              <ProfileContainer institute={institute ? true : false} />
              <div className="flex row wrap pt10">
                <FieldsContainer>
                  <Field
                    disabled={true}
                    error={touched.firstName && errors.firstName ? true : false}
                    helperText={touched.firstName && errors.firstName}
                    name="firstName"
                    onChange={handleChange}
                    id="outlined-basic"
                    label="First Name"
                    variant="outlined"
                    as={InputStyle}
                  />
                </FieldsContainer>
                <FieldsContainer>
                  <Field
                    disabled={true}
                    error={touched.email && errors.email ? true : false}
                    helperText={touched.email && errors.email}
                    name="email"
                    onChange={handleChange}
                    id="outlined-basic"
                    label="Email"
                    variant="outlined"
                    as={InputStyle}
                  />
                </FieldsContainer>

                {institute && (
                  <FieldsContainer>
                    <Field
                      disabled={true}
                      error={
                        touched.panNumber && errors.panNumber ? true : false
                      }
                      helperText={touched.panNumber && errors.panNumber}
                      name="panNumber"
                      onChange={handleChange}
                      id="outlined-basic"
                      label="PAN"
                      variant="outlined"
                      as={InputStyle}
                    />
                  </FieldsContainer>
                )}

                <FieldsContainer>
                  <Field
                    disabled={true}
                    error={touched.address && errors.address ? true : false}
                    helperText={touched.address && errors.address}
                    name="address"
                    onChange={handleChange}
                    id="outlined-basic"
                    label="Address"
                    variant="outlined"
                    as={InputStyle}
                  />
                </FieldsContainer>

                <FieldsContainer>
                  <Field
                    disabled={true}
                    inputProps={{ maxLength: 10 }}
                    error={
                      touched.phoneNumber && errors.phoneNumber ? true : false
                    }
                    helperText={touched.phoneNumber && errors.phoneNumber}
                    name="phoneNumber"
                    onChange={handleChange}
                    id="outlined-basic"
                    label="Phone Number"
                    variant="outlined"
                    as={InputStyle}
                  />
                </FieldsContainer>

                <FieldsContainer>
                  <Field
                    disabled={!isEdit}
                    inputProps={{ maxLength: 10 }}
                    autoFocus={true}
                    error={
                      touched.alternatePhoneNumber &&
                      errors.alternatePhoneNumber
                        ? true
                        : false
                    }
                    helperText={
                      touched.alternatePhoneNumber &&
                      errors.alternatePhoneNumber
                    }
                    name="alternatePhoneNumber"
                    onChange={handleChange}
                    id="outlined-basic"
                    label="Alternate Number"
                    variant="outlined"
                    as={InputStyle}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <img
                            src={Images.flagImage}
                            className="h25 w35"
                            alt="flagImage"
                          />
                        </InputAdornment>
                      ),
                    }}
                  />
                </FieldsContainer>

                <FieldsContainer>
                  <Field
                    disabled={!isEdit}
                    error={
                      touched.allowedCategories && errors.allowedCategories
                        ? true
                        : false
                    }
                    helperText={
                      touched.allowedCategories && errors.allowedCategories
                    }
                    name="allowedCategories"
                    id="outlined-basic"
                    label="Categories"
                    variant="outlined"
                    as={CustomSelectField}
                    value={values.allowedCategories}
                    multiple
                    menuItemValues={categories.map((category: any) => ({
                      label: category.name,
                      value: category.id,
                    }))}
                    selectedItems={values.allowedCategories}
                  />
                </FieldsContainer>
                <FieldsContainer>
                  <Field
                    disabled={!isEdit}
                    error={touched.overview && errors.overview ? true : false}
                    helperText={touched.overview && errors.overview}
                    name="overview"
                    onChange={handleChange}
                    id="outlined-basic"
                    label="Overview"
                    variant="outlined"
                    as={InputStyle}
                    multiline
                    rows="6"
                  />
                </FieldsContainer>
                <FieldsContainer>
                  <Field
                    disabled={!isEdit}
                    error={
                      touched.certification && errors.certification
                        ? true
                        : false
                    }
                    helperText={touched.certification && errors.certification}
                    name="certification"
                    onChange={handleChange}
                    id="outlined-basic"
                    label="Certification"
                    variant="outlined"
                    as={InputStyle}
                    multiline
                    rows="6"
                  />
                </FieldsContainer>

                <FieldsContainer>
                  <Field
                    disabled={!isEdit}
                    error={touched.awards && errors.awards ? true : false}
                    helperText={touched.awards && errors.awards}
                    name="awards"
                    onChange={handleChange}
                    id="outlined-basic"
                    label="Awards"
                    variant="outlined"
                    as={InputStyle}
                    multiline
                    rows="6"
                  />
                </FieldsContainer>

                {!institute ? (
                  <>
                    <FieldsContainer>
                      <Field
                        disabled={!isEdit}
                        error={
                          touched.education && errors.education ? true : false
                        }
                        helperText={touched.education && errors.education}
                        name="education"
                        onChange={handleChange}
                        id="outlined-basic"
                        label="Education"
                        variant="outlined"
                        as={InputStyle}
                        multiline
                        rows="6"
                      />
                    </FieldsContainer>
                    <FieldsContainer>
                      <Field
                        disabled={!isEdit}
                        error={
                          touched.totalExperience && errors.totalExperience
                            ? true
                            : false
                        }
                        helperText={
                          touched.totalExperience && errors.totalExperience
                        }
                        name="totalExperience"
                        onChange={handleChange}
                        id="outlined-basic"
                        label="Total Experience"
                        variant="outlined"
                        as={InputStyle}
                      />
                    </FieldsContainer>
                  </>
                ) : null}
              </div>
            </div>
          </Form>
        )}
      />
    </div>
  );
};

export default Index;
