import styled from "styled-components";
import COLORS from "../../assets/colors";

export const SelectTutorCard = styled.div`
  min-height: 348px;
  width: 500px;
  border-radius: 10px;
  box-shadow: 0 2px 27px 0 rgba(0, 0, 0, 0.06);
  background-color: ${COLORS.PRIMARY_WHITE};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  padding: 30px;
  @media (max-width: 600px) {
    box-shadow: none;
  }
  button {
    margin-bottom: 20px;
  }
`;
export const TextContainer = styled.div``;
export const Heading = styled.h1`
  font-family: ${(props) => props.theme.fonts.maxroundedmedium};
  font-size: 30px;
  text-align: center;
`;
export const Paragraph = styled.p`
  font-family: ${(props) => props.theme.fonts.maxrounded};
  font-size: 18px;
  text-align: center;
  color: ${COLORS.TUTOR_OPTION_PARAGRAPH};
  max-width: 455px;
  margin: 20px 0;
  line-height: 1.5;
  @media (max-width: 600px) {
    padding-top: 35px;
    line-height: 1.69;
    font-size: 16px;
    max-width: 281px;
  }
`;
