import React from 'react';
import { ActivityStatsContainer, StatContainer } from './styles';
import { Images } from '../../../assets/images';

interface Props {
  startDate: string;
  duration: string;
  batchSize: string;
  ageRange: any;
}

const ActivityStats: React.FC<Props> = (props: Props) => {
  return (
    <ActivityStatsContainer>
      <StatContainer>
        <img src={Images.calendar} />
        <div className="details">
          <div className="label">Start Date</div>
          <div className="value">{props.startDate}</div>
        </div>
      </StatContainer>
      <StatContainer>
        <img src={Images.duration} />
        <div className="details">
          <div className="label">Duration</div>
          <div className="value">{props.duration}</div>
        </div>
      </StatContainer>
      <StatContainer>
        <img src={Images.batch} />
        <div className="details">
          <div className="label">Batch Size</div>
          <div className="value">{props.batchSize}</div>
        </div>
      </StatContainer>
      <StatContainer>
        <img src={Images.ageRange} />
        <div className="details">
          <div className="label">Age Range</div>
          <div className="value">{props.ageRange}</div>
        </div>
      </StatContainer>
    </ActivityStatsContainer>
  );
};

export default ActivityStats;
