import React, { useEffect, useState } from "react";
import ReviewSingle from "../../ReviewSingle";
import { Images } from "../../../assets/images";
import { useLazyQuery } from "react-apollo";
import {
  GET_TUTOR_RATING,
  GET_INSTITUTE_RATING,
} from "../../../graphql/queries";

interface Props {
  id: number;
  institute: boolean;
}

const RatingList: React.FC<Props> = ({ id, institute }) => {
  const [reviews, setReviews] = useState<any>([]);
  const [callTutorReviews, tutorReviewRes] = useLazyQuery(GET_TUTOR_RATING, {
    fetchPolicy: "no-cache",
  });

  const [callInstituteReviews, instituteReviewRes] = useLazyQuery(
    GET_INSTITUTE_RATING,
    {
      fetchPolicy: "no-cache",
    }
  );

  useEffect(() => {
    if (tutorReviewRes?.data) {
      setReviews(tutorReviewRes?.data?.getTutorRatings?.nodes);
    } else if (instituteReviewRes?.data) {
      setReviews(instituteReviewRes?.data?.getInstituteRatings?.nodes);
    }
  }, [tutorReviewRes.data, instituteReviewRes.data]);

  useEffect(() => {
    if (institute) {
      callInstituteReviews({ variables: { id: Number(id) } });
    } else {
      callTutorReviews({ variables: { id: Number(id) } });
    }
  }, []);

  return (
    <>
      {reviews?.map((data: any, idx: any) => (
        <ReviewSingle
          key={idx}
          avatar={
            data?.authoredBy?.avatarUrl
              ? data?.authoredBy?.avatarUrl
              : Images.userPlaceHolder
          }
          name={`${data?.authoredBy?.firstName} ${
            data?.authoredBy?.lastName && data?.authoredBy?.lastName
          }`}
          rating={data?.number}
          review={data?.reviewComment}
        />
      ))}
    </>
  );
};

export default RatingList;
