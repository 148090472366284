import React from "react";
import Signin from "../../components/Signin";
import Layout from "../../components/Layout/Layout";
import { useLocation } from "@reach/router";
import TutorForgotPassword from "./TutorForgotPassword";
import TutorResetPassword from "./TutorResetPassword";

function TutorSignin() {
  const { pathname } = useLocation();
  const isForgotPassword = pathname.includes("forgot-password");
  const isResetPassword = pathname.includes("reset-password");

  function renderComponent() {
    if (isForgotPassword) {
      return <TutorForgotPassword />;
    } else if (isResetPassword) {
      return <TutorResetPassword />;
    } else return <Signin mode="tutor" />;
  }

  return <Layout isFooterEnable={false}>{renderComponent()}</Layout>;
}

export default TutorSignin;
