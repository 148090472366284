import React, { useEffect, useState } from "react";
import { CardWrapper, Heading, Container } from "../style";
import ReviewsHeadSection from "../../ReviewsHeadSection";
import { NegativeCTAButton } from "../../common/MaterialFields";
import { Link, navigate } from "@reach/router";
import {
  GET_TUTOR_INFO,
  GET_INSTITUEINFO_INFO,
  GET_TUTOR,
} from "../../../graphql/queries";
import { useLazyQuery, useMutation } from "react-apollo";
import RatingList from "./RatingList";
import { Dialog } from "@material-ui/core";
import DeleteConfirmation from "../../common/DeleteConfirmation";
import { DELETE_TUTOR } from "../../../graphql/mutation";
import { useSnackbar } from "notistack";
import { Images } from "../../../assets/images";

interface Props {
  institute?: boolean;
  id?: string;
}

const ReviewRatings: React.FC<Props> = ({ institute, id }) => {
  const [getMe, setMe] = useState<any>();
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [deleteTutor, { loading }] = useMutation(DELETE_TUTOR);
  const { enqueueSnackbar } = useSnackbar();
  const [callGetTutorReview, tutorRatingRes] = useLazyQuery(GET_TUTOR_INFO, {
    fetchPolicy: "no-cache",
  });

  const [callGetInstituteReview, instituteRes] = useLazyQuery(
    GET_INSTITUEINFO_INFO,
    {
      fetchPolicy: "no-cache",
    }
  );

  const [callGetTutorInfoById, tutorRes] = useLazyQuery(GET_TUTOR, {
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    if (institute) {
      if (id) {
        callGetTutorInfoById({ variables: { id: Number(id) } });
      } else {
        callGetInstituteReview();
      }
    } else {
      callGetTutorReview();
    }
  }, []);

  useEffect(() => {
    if (institute) {
      if (id && tutorRes?.data) {
        setMe(tutorRes?.data?.getTutor);
      } else if (instituteRes?.data) {
        setMe(instituteRes?.data?.me);
      }
    } else {
      setMe(tutorRatingRes?.data?.me);
    }
  }, [tutorRatingRes.data, instituteRes.data, tutorRes]);

  const callDeleteTutor = () => {
    deleteTutor({
      variables: {
        input: {
          id: Number(id),
        },
      },
    })
      .then((res) => {
        if (res?.data?.deleteTutor?.status) {
          enqueueSnackbar(res.data.deleteTutor.message, {
            variant: "success",
            autoHideDuration: 3000,
          });
          setTimeout(() => {
            setOpenDialog(false);
            navigate("/institute/dashboard/tutor");
          }, 500);
        }
      })
      .catch((err) => {
        console.log(err);
        enqueueSnackbar(err.graphQLErrors[0].message, {
          variant: "error",
          autoHideDuration: 3000,
        });
      });
  };

  return (
    <div>
      <div className="flex row pt30 pb30">
        <Heading
          className="flex start-center flex-1"
          padding="0px"
          fontSize="20px"
        >
          {institute && id ? (
            <>
              <Link
                to="/institute/dashboard/tutor"
                // as="/institute/dashboard/tutor"
              >
                <div className="pointer">Tutors</div>
              </Link>{" "}
              <div className="pl15 pr15"> > </div>
              <div> {getMe?.firstName}</div>
            </>
          ) : (
            <div>Reviews and Ratings</div>
          )}
        </Heading>
        {institute && id && (
          <NegativeCTAButton onClick={() => setOpenDialog(true)}>
            Remove Tutor
          </NegativeCTAButton>
        )}
      </div>
      <div>
        <CardWrapper className="mt20 maxwidth370">
          <Container padding="30px" alignItems="center" layout="row">
            <ReviewsHeadSection
              name={getMe?.firstName}
              numOfReviews={getMe?.ratingCount}
              rating={getMe?.ratingAverage}
              specialization={getMe?.allowedCategories?.nodes}
              avatar={
                getMe?.avatarUrl
                  ? getMe?.avatarUrl
                  : Images.userUploadPlaceHolder
              }
              isPending={getMe?.status === "pending" ? true : false}
            />
          </Container>
        </CardWrapper>
        <div className="mt40">
          {getMe?.id && (
            <RatingList
              id={id ? id : getMe?.id}
              institute={institute ? (id ? false : true) : false}
            />
          )}
        </div>
      </div>
      {/* Delete confirmation popup */}
      <Dialog open={openDialog} aria-labelledby="Dialog" maxWidth="lg">
        <DeleteConfirmation
          heading={"Delete Confirmation"}
          content={"Are you sure, you want to Remove this Tutor?"}
          deleteLoading={loading}
          handleCancel={() => setOpenDialog(false)}
          handleOk={() => callDeleteTutor()}
        />
      </Dialog>
    </div>
  );
};

export default ReviewRatings;
