const theme = {
  fonts: {
    //Font - MaxRounded
    maxrounded: "max-rounded, sans-serif",
    maxroundedbold: "max-rounded-bold, sans-serif",
    maxroundedbolditalic: "max-rounded-bold-italic, sans-serif",
    maxroundeditalic: "max-rounded-italic, sans-serif",
    maxroundedmedium: "max-rounded-medium,san-serif",
    maxroundedmediumitalic: "max-rounded-medium-italic, sans-serif",

    //Font - Montserrat
    montserrat: "montserrat, sans-serif",
    montserratmedium: "montserrat-medium, sans-serif",
    montserratsemibold: "montserrat-semi-bold, sans-serif",
    montserratbold: "montserrat-bold, sans-serif",
    montserratlight: "montserrat-light, sans-serif",
  },
  breakpoints: {
    xs: "(max-width: 575px)",
    sm_only: "(min-width: 576px) and (max-width: 768px)",
    md_only: "(min-width: 769px) and (max-width: 1024px)",
    lg_only: "(min-width: 1025px) and (max-width: 1199px)",
    xl: "(min-width: 1200px)",

    sm_up: "(min-width: 576px)",
    md_up: "(min-width: 769px)",
    lg_up: "(min-width: 1025px)",
    xl_up: "(min-width: 1200px)",
    xxl_up: "(min-width: 1440px)",

    xs_down: "(max-width: 575px)",
    sm_down: "(max-width: 768px)",
    md_down: "(max-width: 1024px)",
    lg_down: "(max-width: 1199px)",
    xl_down: "(max-width: 1200px)",
    xxl_down: "(max-width: 1439px)",

    //material breakpoints
    mat_md_down: "(max-width: 960px)",
    mat_sm_only: "(min-width: 575px) and (max-width: 960px)",
    mat_md_only: "(min-width: 960px) and (max-width: 1024px)",
    mat_xs_down: "(max-width: 600px)",
  },
};

export default theme;
